import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { Box } from "@chakra-ui/react";
import { AppAsyncSelect, AppSelectOption } from "./AppAsyncSelect";
import { ProjectListAtom, SelectedProjectAtom } from "../atoms";
import { LSC } from "../config";

export const AppProjectSelector = () => {
    const navigate = useNavigate();

    const projectData = useRecoilValue(ProjectListAtom)?.map((e) => {
        return {
            value: e.id,
            label: e.name,
        };
    });
    const [selectedProject, setSelectedProject] =
        useRecoilState(SelectedProjectAtom);

    const onChange = (value: number) => {
        if (value > 0) {
            setSelectedProject(value);
        }
        navigate(0);
    };

    useEffect(() => {
        localStorage.setItem(LSC.APP_SEL_P, `${selectedProject}`);
    }, [selectedProject]);

    return (
        <Box>
            <AppAsyncSelect<AppSelectOption>
                defaultOptions={projectData}
                onChange={(e) => onChange(Number(e?.value))}
                value={projectData?.filter((d) => d.value === selectedProject)}
                placeholder="Select Project"
                // isLoading={loadingProject}
                isClearable={false}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused
                            ? "var(--chakra-colors-primary)"
                            : "var(--chakra-colors-greyT65)",
                        height: "50px",
                        minWidth: "150px",
                        ":hover": {
                            borderColor: state.isFocused
                                ? "var(--chakra-colors-primary)"
                                : "var(--chakra-colors-greyT40)",
                        },
                    }),
                }}
            />
            <span
                style={{
                    fontSize: "8px",
                    position: "absolute",
                    top: "5px",
                    background: "var(--chakra-colors-white)",
                    padding: "0 8px",
                    marginLeft: "8px",
                }}
            >
                PROJECTS
            </span>
        </Box>
    );
};
