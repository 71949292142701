import {
    Box,
    Button,
    Center,
    Checkbox,
    Divider,
    Flex,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Text,
    Link,
} from "@chakra-ui/react";
import { AppIcon } from "./AppIcon";
import { useEffect, useState } from "react";
import { AppLoader } from "./AppLoader";

export interface Option {
    value: string | number;
    label: string;
}

export type AppMultiSelectorReturnType = {
    data: Option[];
    totalItems: number;
};

interface AppMultiSelectorProps {
    loadOptions: (
        page: number,
        search: string,
        pageSize: number
    ) => Promise<AppMultiSelectorReturnType>;
    onSelect: (data: (string | number)[]) => void;
    selectedOpt: (string | number)[];
}

export const AppMultiSelector = ({
    loadOptions,
    onSelect,
    selectedOpt,
}: AppMultiSelectorProps) => {
    const [value, setValue] = useState("");
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [data, setData] = useState<Option[]>([]);
    const [selectedOptions, setSelectedOptions] =
        useState<(string | number)[]>(selectedOpt);

    const pageSize = 10;

    const handleSearch = (v: string) => {
        if (v !== value) {
            if (page !== 1) {
                setPage(1);
            }
            setData([]);
            setValue(v);
        }
    };

    const handleCheckboxChange = (value: string | number) => {
        setSelectedOptions((prev) => {
            if (prev.includes(value)) {
                return prev.filter((val) => val !== value);
            } else {
                return [...prev, value];
            }
        });
    };

    const handleSelectAll = () => {
        if (selectedOptions.length === data.length) {
            setSelectedOptions([]);
        } else {
            setSelectedOptions(data.map((option) => option.value));
        }
    };

    const isIndeterminate = () => {
        return (
            selectedOptions.length > 0 && selectedOptions.length < data.length
        );
    };

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

        console.log(scrollTop, clientHeight, scrollHeight);
        console.log(scrollTop + clientHeight, scrollHeight - 1);
        if (scrollTop + clientHeight > scrollHeight - 1) {
            if (data.length >= totalItems) {
                console.log("return");
                return;
            } else {
                console.log("page change");
                console.log(data.length, "xxx", totalItems);
                setPage((prevPage) => prevPage + 1);
            }
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const result = await loadOptions(page, value, pageSize);
            page === 1
                ? setData(result.data)
                : setData([...data, ...result.data]);
            setTotalItems(result.totalItems);
            setLoading(false);
        };

        fetchData();
    }, [page, value]);

    if (loading) {
        <AppLoader />;
    }

    return (
        <>
            <Flex
                direction="column"
                width={"full"}
                justifyContent={"space-between"}
                gap={6}
            >
                <InputGroup minW={"11.25rem"}>
                    <InputLeftElement pointerEvents="none">
                        <AppIcon name="ics-search" />
                    </InputLeftElement>
                    <Input
                        type="text"
                        placeholder={"Search"}
                        value={value}
                        onChange={(e) => handleSearch(e.currentTarget.value)}
                        maxLength={50}
                    />
                    <InputRightElement>
                        <Button
                            display={value ? "block" : "none"}
                            variant={"transparent"}
                            size={"sm"}
                            className="btn-icon-sm"
                            onClick={() => handleSearch("")}
                        >
                            <AppIcon name="icl-x" w="1rem" />
                        </Button>
                    </InputRightElement>
                </InputGroup>
                <Flex
                    direction="column"
                    width={"full"}
                    justifyContent={"space-between"}
                    border={"1px solid var(--chakra-colors-primaryT80)"}
                    borderRadius={"0.25rem"}
                >
                    <HStack
                        h={"50px"}
                        px={3}
                        gap={3}
                        bg={"var(--chakra-colors-primaryT95)"}
                        borderTopLeftRadius={"0.25rem"}
                        borderTopRightRadius={"0.25rem"}
                        borderBottom={
                            "1px solid var(--chakra-colors-primaryT80)"
                        }
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Checkbox
                            isChecked={selectedOptions.length === data.length}
                            isIndeterminate={isIndeterminate()}
                            onChange={handleSelectAll}
                        />
                        <Box flexGrow={1}>
                            <Text
                                textStyle={"regularMd"}
                            >{`Projects (${selectedOptions.length}/${totalItems})`}</Text>
                        </Box>
                    </HStack>
                    <Flex
                        direction="column"
                        width={"full"}
                        maxH={"320px"}
                        my={5}
                        gap={3}
                        overflowY="auto"
                        sx={{ scrollbarWidth: "thin" }}
                        onScroll={!loading ? handleScroll : () => {}}
                    >
                        {data.map((opt) => (
                            <HStack
                                key={opt.value}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                px={3}
                                gap={3}
                            >
                                <Checkbox
                                    isChecked={selectedOptions.includes(
                                        opt.value
                                    )}
                                    onChange={() =>
                                        handleCheckboxChange(opt.value)
                                    }
                                />
                                <Box flexGrow={1}>
                                    <Text textStyle={"regularMd"}>
                                        {opt.label}
                                    </Text>
                                </Box>
                            </HStack>
                        ))}
                    </Flex>
                    <HStack
                        h={"50px"}
                        px={3}
                        borderTop={"1px solid var(--chakra-colors-primaryT80)"}
                        justifyContent={"space-between"}
                    >
                        <Text>{`Showing ${data.length} of ${totalItems}`}</Text>
                        <HStack gap={3}>
                            <Link href="">Select All</Link>
                            <Center height="1.3rem">
                                <Divider
                                    orientation="vertical"
                                    borderColor={"var(--chakra-colors-greyT80)"}
                                />
                            </Center>
                            <Link href="">Clear All</Link>
                        </HStack>
                    </HStack>
                </Flex>
                <HStack justifyContent={"flex-end"}>
                    <Button onClick={() => onSelect(selectedOptions)}>
                        Select
                    </Button>
                </HStack>
            </Flex>
        </>
    );
};
