const componentToHex = (c) => {
    const hex = `${c.toString(16)}`;
    return hex.length === 1 ? "0" + hex : hex;
};

export const calculateTintShade = (
    hexColor: string,
    tintPercent: number,
    shadePercent: number
) => {
    // Convert hex color to RGB components
    const r = parseInt(hexColor.substring(1, 3), 16);
    const g = parseInt(hexColor.substring(3, 5), 16);
    const b = parseInt(hexColor.substring(5, 7), 16);
    // Calculate tint and shade values
    const tintR = r + ((255 - r) * tintPercent) / 100;
    const tintG = g + ((255 - g) * tintPercent) / 100;
    const tintB = b + ((255 - b) * tintPercent) / 100;

    const shadeR = r - (r * shadePercent) / 100;
    const shadeG = g - (g * shadePercent) / 100;
    const shadeB = b - (b * shadePercent) / 100;

    // Clamp values to ensure they stay within the 0-255 range and round to integers
    const clampedTintR = Math.round(Math.max(0, Math.min(255, tintR)));
    const clampedTintG = Math.round(Math.max(0, Math.min(255, tintG)));
    const clampedTintB = Math.round(Math.max(0, Math.min(255, tintB)));

    const clampedShadeR = Math.round(Math.max(0, Math.min(255, shadeR)));
    const clampedShadeG = Math.round(Math.max(0, Math.min(255, shadeG)));
    const clampedShadeB = Math.round(Math.max(0, Math.min(255, shadeB)));

    // Convert RGB components back to hex color
    const tintHex =
        "#" +
        componentToHex(clampedTintR) +
        componentToHex(clampedTintG) +
        componentToHex(clampedTintB);
    const shadeHex =
        "#" +
        componentToHex(clampedShadeR) +
        componentToHex(clampedShadeG) +
        componentToHex(clampedShadeB);

    return { tint: tintHex, shade: shadeHex };
};
