import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTrans } from "../../../hooks";
import { VStack, HStack } from "@chakra-ui/react";
import { AppPageHeader } from "../../../components";
import { TimesheetList as ListComponent } from "./TimesheetList";
import { AppSearch } from "../../../containers";
import { useTimesheetCrud } from "./useTimesheetCrud";

export const TimesheetPage = () => {
    // hook
    const { t } = useTrans();
    const { id } = useParams();
    const {
        formContainerState,
        kaTableProps,
        actionOptions,
        actionClickHandler,
        deleteHandler,
        deleteAlertState,
        setSearch,
    } = useTimesheetCrud();

    useEffect(() => {
        if (id) {
            formContainerState.open(id);
        }
    }, [id]);

    return (
        <>
            <AppPageHeader title={t("padm.TimesheetPage:text.pageTitle")}>
                <HStack gap={4} py={{ base: 3.5, sm: 4, md: "1.125rem" }}>
                    <AppSearch onSearch={(value) => setSearch(value)} />
                </HStack>
            </AppPageHeader>
            <VStack p={6}>
                <ListComponent
                    kaTableProps={kaTableProps}
                    actionOptions={actionOptions}
                    actionClickHandler={actionClickHandler}
                    deleteHandler={deleteHandler}
                    deleteAlertState={deleteAlertState}
                />
            </VStack>
        </>
    );
};
