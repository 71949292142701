import * as yup from "yup";
import { UserApi } from "../../../apis";

export const schemaChangeEmail = (t: (val: string) => string) => {
    const validation = {
        newEmail: yup
            .string()
            .required(t("cmn:val.required"))
            .email(t("ent.User:email.val.email"))
            .test("isExist", t("ent.User:email.val.uniq"), async (value) => {
                return (
                    (
                        await UserApi.postUserExist<
                            { isExist: boolean },
                            { email: string }
                        >({
                            email: value,
                        })
                    ).response?.isExist !== true
                );
            }),
    };
    return yup.object().shape({ ...validation });
};
