import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    Skeleton,
    VStack,
} from "@chakra-ui/react";

type AppFormControlProps = {
    children: JSX.Element;
    label?: string;
    isInvalid?: boolean;
    isDisabled?: boolean;
    message?: string;
    isLoading?: boolean;
};

export const AppFormControl = ({
    children,
    label = "",
    isInvalid = false,
    isDisabled = false,
    message = "",
    isLoading = false,
}: AppFormControlProps) =>
    isLoading ? (
        <VStack w={"full"} alignItems={"flex-start"}>
            <Skeleton
                isLoaded={!isLoading}
                w="25%"
                h="21px"
                startColor={"var(--chakra-colors-primaryT95)"}
                endColor={"var(--chakra-colors-primaryT80)"}
            >
                Test
            </Skeleton>
            <Skeleton
                isLoaded={!isLoading}
                w="full"
                h="41px"
                startColor={"var(--chakra-colors-primaryT95)"}
                endColor={"var(--chakra-colors-primaryT80)"}
            >
                Test
            </Skeleton>
        </VStack>
    ) : (
        <FormControl isInvalid={isInvalid} isDisabled={isDisabled}>
            {label && <FormLabel>{label}</FormLabel>}
            {children}
            {message && <FormErrorMessage>{message}</FormErrorMessage>}
        </FormControl>
    );
