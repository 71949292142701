import { radioAnatomy } from "@chakra-ui/anatomy";
import {
    createMultiStyleConfigHelpers,
    defineStyle,
} from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyleContainer = defineStyle({
    display: "flex",
    alignItems: "center",
    _disabled: { cursor: "not-allowed" },
});

const baseStyleControl = defineStyle({
    border: "1px solid",
});

const baseStyleLabel = defineStyle({
    alignSelf: "center",
    userSelect: "none",
    _disabled: { opacity: 1 },
});

const baseStyle = definePartsStyle({
    container: baseStyleContainer,
    control: baseStyleControl,
    label: baseStyleLabel,
});

const sizes = {
    sm: definePartsStyle({
        container: {
            // padding: "0.25rem",
        },
        control: {
            boxSize: 4,
        },
        label: { fontSize: "0.7262rem", ms: 1.5 },
    }),
    md: definePartsStyle({
        container: {
            // padding: "0.375rem",
        },
        control: {
            boxSize: 5,
        },
        label: { fontSize: "0.875rem", ms: 2 },
    }),
    lg: definePartsStyle({
        container: {
            // padding: "0.5rem",
        },
        control: {
            boxSize: 6,
        },
        label: { fontSize: "1.02rem", ms: 2.5 },
    }),
};

const primary = definePartsStyle({
    control: {
        borderColor: "primaryRadioControlBorder",
        bg: "primaryRadioControlBg",

        _checked: {
            bg: "primaryRadioControlCheckedBg",
            borderColor: "primaryRadioControlCheckedBorder",
            color: "primaryRadioControlCheckedText",
            _hover: {
                bg: "primaryRadioControlCheckedHoverBg",
                borderColor: "primaryRadioControlCheckedHoverBorder",
                color: "primaryRadioControlCheckedHoverText",
            },
            _focusVisible: {
                borderColor: "primaryRadioControlFocusBorder",
                boxShadow: "var(--chakra-colors-boxShadowFormEle)",
            },
            _disabled: {
                opacity: 1,
                bg: "primaryRadioControlCheckedDisabledBg",
                borderColor: "primaryRadioControlCheckedDisabledBorder",
                color: "primaryRadioControlCheckedDisabledText",
                _hover: {
                    opacity: 1,
                    bg: "primaryRadioControlCheckedDisabledHoverBg",
                    borderColor:
                        "primaryRadioControlCheckedDisabledHoverBorder",
                    color: "primaryRadioControlCheckedDisabledHoverText",
                },
            },
        },

        _hover: {
            borderColor: "primaryRadioControlHoverBorder",
        },
        _focusVisible: {
            borderColor: "primaryRadioControlFocusBorder",
            boxShadow: "var(--chakra-colors-boxShadowFormEle)",
        },

        _disabled: {
            opacity: 1,
            bg: "primaryRadioControlDisabledBg",
            borderColor: "primaryRadioControlDisabledBorder",
            _hover: {
                opacity: 1,
                bg: "primaryRadioControlDisabledHoverBg",
                borderColor: "primaryRadioControlDisabledHoverBorder",
            },
        },
    },
    label: {
        color: "primaryRadioLabelText",
        _disabled: {
            opacity: "1",
            cursor: "not-allowed",
            color: "primaryRadioLabelDisabledText",
            _hover: {
                color: "primaryRadioLabelDisabledHoverText",
            },
        },
    },
});

const variants = {
    primary,
};

export const Radio = defineMultiStyleConfig({
    baseStyle,
    sizes,
    variants,
    defaultProps: {
        size: "md",
        variant: "primary",
    },
});
