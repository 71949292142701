import React, { useRef } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { atom, atomFamily, useRecoilState, useRecoilValue } from "recoil";

// interface Status {
//     key: string;
//     name: string;
// }

// interface Task {
//     id: number;
//     name: string;
//     status: string;
// }

interface Column {
    id: string;
    name: string;
    listIds: number[];
}

interface AppDroppableColumnTestProps {
    column: Column;
}

interface AppDragItemTestProps {
    itemId: number;
    index: number;
}

interface ItemState {
    id: number;
    content: string;
}

// const statuses: Status[] = [
//     {
//         key: "TODO",
//         name: "To Do",
//     },
//     {
//         key: "PROGRESS",
//         name: "Progress",
//     },
//     {
//         key: "COMPLETED",
//         name: "Completed",
//     },
//     {
//         key: "TESTING",
//         name: "Testing",
//     },
// ];

// const tasks: Task[] = [
//     {
//         id: 1,
//         name: "Design Landing Page UI",
//         status: "TODO",
//     },
//     {
//         id: 2,
//         name: "Implement User Authentication",
//         status: "TODO",
//     },
//     {
//         id: 3,
//         name: "Create Product Listing Component",
//         status: "TODO",
//     },
//     {
//         id: 4,
//         name: "Set Up Database Schema",
//         status: "PROGRESS",
//     },
//     {
//         id: 5,
//         name: "Develop Notification System",
//         status: "PROGRESS",
//     },
//     {
//         id: 6,
//         name: "Optimize Website Performance",
//         status: "COMPLETED",
//     },
//     {
//         id: 7,
//         name: "Write Unit Tests for API",
//         status: "COMPLETED",
//     },
//     {
//         id: 8,
//         name: "Integrate Payment Gateway",
//         status: "TESTING",
//     },
//     {
//         id: 9,
//         name: "Fix Bugs in Login Module",
//         status: "TESTING",
//     },
//     {
//         id: 10,
//         name: "Develop Admin Dashboard",
//         status: "TESTING",
//     },
// ];

// console.log(statuses, tasks);

const todoColumnState = atom<Column>({
    key: "todoColumnState",
    default: {
        id: "todo",
        name: "TO DO",
        listIds: [],
    },
});

const doneColumnState = atom<Column>({
    key: "doneColumnState",
    default: {
        id: "done",
        name: "DONE",
        listIds: [],
    },
});

const itemState = atomFamily<ItemState, number>({
    key: "itemState",
    default: (id: number) => ({
        id: id,
        content: `Task ${id} content`,
    }),
});

export const AppDragAndDropTest = () => {
    const itemRef = useRef<number>(1);
    const [todoColumn, setTodoColumn] = useRecoilState(todoColumnState);
    const [doneColumn, setDoneColumn] = useRecoilState(doneColumnState);

    const addItem = () => {
        setTodoColumn((prev) => ({
            ...prev,
            listIds: [...prev.listIds, itemRef.current],
        }));
        itemRef.current++;
    };

    const getColumn = (dropId: string) => {
        switch (dropId) {
            case "todo":
                return todoColumn;
            case "done":
                return doneColumn;
        }
    };

    const getItems = (columnId: string): number[] => {
        switch (columnId) {
            case "todo":
                return Array.from(todoColumn?.listIds);
            case "done":
                return Array.from(doneColumn?.listIds);
            default:
                return [] as number[];
        }
    };

    const handleOnDragEnd = (result) => {
        // console.log(result);
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        ) {
            return;
        }
        const sourceColumn = getColumn(source.droppableId);
        const sourceItems = getItems(sourceColumn?.id || "");
        // console.log(sourceColumn, sourceItems);

        if (source.droppableId === destination.droppableId) {
            const [movedItem] = sourceItems.splice(source.index, 1);
            sourceItems.splice(destination.index, 0, movedItem);
            if (!sourceColumn) {
                return;
            }
            const updatedColumn = {
                ...sourceColumn,
                listIds: sourceItems,
            };
            switch (source.droppableId) {
                case "todo":
                    setTodoColumn(updatedColumn);
                    break;
                case "done":
                    setDoneColumn(updatedColumn);
                    break;
                default:
                    break;
            }
            return;
        }

        const destColumn = getColumn(destination.droppableId);
        const destItems = getItems(destColumn?.id || "");

        if (!destColumn || !sourceColumn) {
            return;
        }

        const [movedItem] = sourceItems.splice(source.index, 1);
        destItems.splice(destination.index, 0, movedItem);

        const updatedSourceColumn = {
            ...sourceColumn,
            listIds: sourceItems,
        };
        const updatedDestColumn = {
            ...destColumn,
            listIds: destItems,
        };

        switch (source.droppableId) {
            case "todo":
                setTodoColumn(updatedSourceColumn);
                break;
            case "done":
                setDoneColumn(updatedSourceColumn);
                break;
            default:
                break;
        }

        switch (destination.droppableId) {
            case "todo":
                setTodoColumn(updatedDestColumn);
                break;
            case "done":
                setDoneColumn(updatedDestColumn);
                break;
            default:
                break;
        }
    };

    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Flex direction={"column"} gap={8}>
                <Box>Todo array: {todoColumn?.listIds?.join(",")}</Box>
                <Box>
                    <Button onClick={addItem}>Add Task</Button>
                </Box>
                <Flex gap={6}>
                    <AppDroppableColumnTest column={todoColumn} />
                    <AppDroppableColumnTest column={doneColumn} />
                </Flex>
            </Flex>
        </DragDropContext>
    );
};

const AppDroppableColumnTest = React.memo(
    ({ column }: AppDroppableColumnTestProps) => {
        console.log(`${column.id} column render`);
        return (
            <Droppable droppableId={column.id}>
                {(provided) => (
                    <Flex
                        w={"300px"}
                        direction={"column"}
                        p={"1.25rem"}
                        gap={"1.25rem"}
                        border={"1px solid #E4E4E4"}
                        borderRadius={"8px"}
                        bg={"white"}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        <Box>{column.name}</Box>
                        {column?.listIds.map((id, index) => (
                            <AppDragItemTest
                                key={id}
                                itemId={id}
                                index={index}
                            />
                        ))}
                        {provided.placeholder}
                    </Flex>
                )}
            </Droppable>
        );
    }
);
AppDroppableColumnTest.displayName = "AppDroppableColumnTest";

const AppDragItemTest = React.memo(
    ({ itemId, index }: AppDragItemTestProps) => {
        const item = useRecoilValue(itemState(itemId));
        console.log(`${item.id} card render`);
        return (
            <Draggable draggableId={`${item.id}`} index={index}>
                {(provided) => (
                    <Flex
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        direction={"column"}
                        p={"1.125rem"}
                        gap={"1rem"}
                        border={"1px solid #E4E4E4"}
                        borderRadius={"4px"}
                        bg={"white"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        shadow={"md"}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            width="30px"
                            height="30px"
                            borderRadius="30px"
                            bg="#727272"
                            color="white"
                            textAlign="center"
                            padding={"20px"}
                        >
                            <Text textStyle="mediumMd">{item.id}</Text>
                        </Box>
                        <Text>{item.content}</Text>
                    </Flex>
                )}
            </Draggable>
        );
    },
    (prevProps, nextProps) => {
        return (
            prevProps.itemId === nextProps.itemId &&
            prevProps.index === nextProps.index
        );
    }
);
AppDragItemTest.displayName = "AppDragItemTest";
