import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTrans } from "../../../hooks";
import {
    Button,
    VStack,
    HStack,
    Divider,
    Center,
    Text,
    Hide,
} from "@chakra-ui/react";
import { AppIcon, AppPageHeader } from "../../../components";
import { CompanyList as ListComponent } from "./CompanyList";
import { CompanyForm as FormComponent } from "./CompanyForm";
import { AppSearch } from "../../../containers";
import { useCompanyCrud } from "./useCompanyCrud";

export const CompanyPage = () => {
    // hook
    const { t } = useTrans();
    const { id } = useParams();
    const {
        formContainerState,
        kaTableProps,
        actionOptions,
        actionClickHandler,
        deleteHandler,
        deleteAlertState,
        setSearch,
        onFormSubmit,
        register,
        formState,
        data,
        loading,
    } = useCompanyCrud();

    useEffect(() => {
        if (id) {
            formContainerState.open(id);
        }
    }, [id]);

    return (
        <>
            <AppPageHeader title={t("padm.CompanyPage:text.pageTitle")}>
                <HStack gap={4} py={{ base: 3.5, sm: 4, md: "1.125rem" }}>
                    <AppSearch onSearch={(value) => setSearch(value)} />
                    <Center height="2.1875rem">
                        <Divider
                            orientation="vertical"
                            borderColor={"var(--chakra-colors-greyT80)"}
                        />
                    </Center>
                    <Button
                        variant={"primary"}
                        onClick={() => formContainerState.open(0)}
                    >
                        <AppIcon name="ics-plus-circle" w="1rem" />
                        <Hide below="md">
                            <Text ml={2}>
                                {t("padm.CompanyPage:button.create")}
                            </Text>
                        </Hide>
                    </Button>
                </HStack>
            </AppPageHeader>
            <VStack p={6}>
                <ListComponent
                    kaTableProps={kaTableProps}
                    actionOptions={actionOptions}
                    actionClickHandler={actionClickHandler}
                    deleteHandler={deleteHandler}
                    deleteAlertState={deleteAlertState}
                />
            </VStack>
            {formContainerState.isOpen && (
                <FormComponent
                    formContainerState={formContainerState}
                    onFormSubmit={onFormSubmit}
                    register={register}
                    formState={formState}
                    data={data}
                    loading={loading}
                />
            )}
        </>
    );
};
