import { FC } from "react";
import { Badge, HStack, UseDisclosureReturn, Text } from "@chakra-ui/react";
import { IKaTableProps, Table as KaTable } from "ka-table";
import { ICellTextProps } from "ka-table/props";
import {
    AppAlertDelete,
    AppOptionBoxItem,
    AppOptionsBox,
} from "../../../components";
import { Company } from "../../../apis";
import { APPC } from "../../../config";
import { useTrans } from "../../../hooks";

export const CompanyList: FC<{
    kaTableProps: IKaTableProps;
    actionOptions: AppOptionBoxItem[];
    actionClickHandler: (key: string, data?: Company) => void;
    deleteHandler: () => Promise<void>;
    deleteAlertState: UseDisclosureReturn;
}> = ({
    kaTableProps,
    actionOptions,
    actionClickHandler,
    deleteHandler,
    deleteAlertState,
}) => {
    // hook
    const { t } = useTrans();
    return (
        <>
            <KaTable
                {...kaTableProps}
                childComponents={{
                    ...kaTableProps.childComponents,
                    cellText: {
                        content: (props: ICellTextProps) => {
                            switch (props?.column?.key) {
                                case "name":
                                    return (
                                        <Text
                                            textStyle={"mediumSm"}
                                            color={"black"}
                                        >
                                            {props?.rowData?.name}
                                        </Text>
                                    );
                                case "domain":
                                    return (
                                        <a
                                            href={`${APPC.APP_PROTOCOL}://${props?.rowData?.domain}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {props?.rowData?.domain}
                                        </a>
                                    );
                                case "isVerified":
                                    return (
                                        <Badge
                                            variant={
                                                props?.rowData?.isVerified
                                                    ? "green"
                                                    : "red"
                                            }
                                        >
                                            {t(
                                                `cmn:label.verified.${props?.rowData?.isVerified}`
                                            )}
                                        </Badge>
                                    );
                                case "isActive":
                                    return (
                                        <Badge
                                            variant={
                                                props?.rowData?.isActive
                                                    ? "green"
                                                    : "red"
                                            }
                                        >
                                            {t(
                                                `cmn:label.active.${props?.rowData?.isActive}`
                                            )}
                                        </Badge>
                                    );
                                case "action":
                                    return (
                                        <HStack justify={"flex-end"} gap={3}>
                                            <AppOptionsBox<Company>
                                                options={actionOptions}
                                                data={props?.rowData}
                                                onClick={actionClickHandler}
                                            />
                                        </HStack>
                                    );
                            }
                        },
                    },
                }}
            />
            {deleteAlertState.isOpen && (
                <AppAlertDelete
                    isOpenDelete={deleteAlertState.isOpen}
                    deleteHandler={() => deleteHandler()}
                    onCloseDelete={deleteAlertState.onClose}
                />
            )}
        </>
    );
};
