import { BaseEntity } from "./BaseEntity";
import { Company } from "./Company";
import { KanbanStatus } from "./KanbanStatus";
import { Task } from "./Task";
import { Workspace } from "./Workspace";

export class Project extends BaseEntity {
    public name: string = "";
    public description?: string = "";
    public tasks?: string[] | Task[];
    public workspace?: string | Workspace = "";
    public company?: string | Company = "";
    public kanbanStatuses?: string[] | KanbanStatus[];

    constructor(model: Partial<Project> = {}) {
        super(model?.id);
        Object.assign(this, model);
    }
}

export type PProject = Partial<Project>;
