import { VStack, Text } from "@chakra-ui/react";
import { useTrans } from "../hooks";
import { AppAlert } from "./AppAlert";
import { AppIcon } from "./AppIcon";

type AppAlertDeleteProps = {
    isOpenDelete: boolean;
    deleteHandler: () => Promise<void>;
    onCloseDelete: () => void;
};

export const AppAlertDelete = ({
    isOpenDelete,
    deleteHandler,
    onCloseDelete,
}: AppAlertDeleteProps) => {
    const { t } = useTrans();
    return (
        <AppAlert
            title={t("cmn:alert.delete.title")}
            isOpen={isOpenDelete}
            onSuccess={deleteHandler}
            onCancel={onCloseDelete}
            successLable={t("cmn:alert.delete.button.success")}
            cancelLable={t("cmn:alert.delete.button.cancel")}
        >
            <VStack py={6}>
                <AppIcon
                    name="icl-trash"
                    w="2.5rem"
                    color={"var(--chakra-colors-destructive)"}
                />
                <Text textStyle={"boldMd"}>
                    {t("cmn:alert.delete.message")}
                </Text>
            </VStack>
        </AppAlert>
    );
};
