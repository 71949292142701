import { useDateTime } from "../hooks";
import { Progress } from "@chakra-ui/react";
import { AppTooltip } from "./AppTooltip";

interface AppTaskProgressProps {
    estimatedTime: number;
    trackTime: number;
}

export const AppTaskProgress = ({
    estimatedTime,
    trackTime,
}: AppTaskProgressProps) => {
    const { getFormattedTimeFromSeconds } = useDateTime();
    const isExtend = trackTime > estimatedTime;
    const progressValue = isExtend
        ? (estimatedTime * 100) / trackTime
        : (trackTime * 100) / estimatedTime;
    const info = `Estimated time: ${getFormattedTimeFromSeconds(
        estimatedTime
    )}, Track time: ${getFormattedTimeFromSeconds(trackTime)}`;
    return (
        <>
            <AppTooltip label={info}>
                <Progress
                    colorScheme="green"
                    value={progressValue}
                    height={"0.35rem"}
                    borderRadius={"0.25rem"}
                    backgroundColor={
                        trackTime > estimatedTime
                            ? "var(--chakra-colors-destructiveT10)"
                            : ""
                    }
                />
            </AppTooltip>
        </>
    );
};
