import { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
    CompanyPage,
    DashboardPage,
    ForgotPasswordPage,
    KanbanStatusPage,
    LoginPage,
    ProfilePage,
    ProjectPage,
    RegisterPage,
    SetPasswordPage,
    TaskKanbanPage,
    TaskPage,
    TimesheetPage,
    UserPage,
    WorkspacePage,
} from "../pages";
import { useAuthAction } from "../hooks";
import { cPath } from "../config";
import { AppLoader } from "../components";

const AdminLayout = lazy(() => import("../layout/AdminLayout"));
// const HomeLayout = lazy(() => import("../layout/HomeLayout"));
const LoginLayout = lazy(() => import("../layout/LoginLayout"));

const Logout = () => {
    const { logoutAction } = useAuthAction();
    logoutAction();
    return <></>;
};

const RootRoutes = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
    return (
        <Routes>
            {isAuthenticated ? (
                <>
                    <Route
                        path="*"
                        element={
                            <Navigate
                                to={cPath("ADMIN.DASHBOARD_PAGE")}
                                replace
                            />
                        }
                    />
                    <Route
                        path={cPath("ADMIN")}
                        element={
                            <Suspense fallback={<AppLoader />}>
                                <AdminLayout />
                            </Suspense>
                        }
                    >
                        <Route
                            path={cPath("ADMIN.DASHBOARD_PAGE", {}, true)}
                            element={<DashboardPage />}
                        />
                        <Route
                            path={cPath("ADMIN.PROFILE_PAGE", {}, true)}
                            element={<ProfilePage />}
                        />
                        <Route
                            path={cPath("ADMIN.COMPANY_PAGE", {}, true)}
                            element={<CompanyPage />}
                        />
                        <Route
                            path={cPath(
                                "ADMIN.COMPANY_PAGE_EDIT",
                                { id: ":id" },
                                true
                            )}
                            element={<CompanyPage />}
                        />
                        <Route
                            path={cPath("ADMIN.USER_PAGE", {}, true)}
                            element={<UserPage />}
                        />
                        <Route
                            path={cPath(
                                "ADMIN.USER_PAGE_EDIT",
                                { id: ":id" },
                                true
                            )}
                            element={<UserPage />}
                        />
                        <Route
                            path={cPath("ADMIN.WORKSPACE_PAGE", {}, true)}
                            element={<WorkspacePage />}
                        />
                        <Route
                            path={cPath(
                                "ADMIN.WORKSPACE_PAGE_EDIT",
                                { id: ":id" },
                                true
                            )}
                            element={<WorkspacePage />}
                        />
                        <Route
                            path={cPath("ADMIN.PROJECT_PAGE", {}, true)}
                            element={<ProjectPage />}
                        />
                        <Route
                            path={cPath(
                                "ADMIN.PROJECT_PAGE_EDIT",
                                { id: ":id" },
                                true
                            )}
                            element={<ProjectPage />}
                        />
                        <Route
                            path={cPath("ADMIN.TASK_PAGE", {}, true)}
                            element={<TaskPage />}
                        />
                        <Route
                            path={cPath(
                                "ADMIN.TASK_PAGE_EDIT",
                                { id: ":id" },
                                true
                            )}
                            element={<TaskPage />}
                        />
                        <Route
                            path={cPath("ADMIN.TASK_BOARD_PAGE", {}, true)}
                            element={<TaskKanbanPage />}
                        />
                        <Route
                            path={cPath(
                                "ADMIN.KANBANSTATUS_PAGE_EDIT",
                                { id: ":id" },
                                true
                            )}
                            element={<KanbanStatusPage />}
                        />
                        <Route
                            path={cPath("ADMIN.TIMESHEET_PAGE", {}, true)}
                            element={<TimesheetPage />}
                        />
                        <Route path="analysis" element={<>analysis</>} />
                        <Route path="resumes" element={<>resumes</>} />
                        <Route path="coverletter" element={<>coverletter</>} />
                        <Route path="personal" element={<>personal</>} />
                        <Route path="education" element={<>education</>} />
                        <Route path="dangal" element={<>dangal</>} />
                        <Route path="swades" element={<>swades</>} />
                        <Route path="twelethfail" element={<>twelethfail</>} />
                        <Route path="war" element={<>war</>} />
                        <Route path="history" element={<>history</>} />
                        <Route path="favourites" element={<>favourites</>} />
                        <Route
                            path={cPath("ADMIN.LOGOUT", {}, true)}
                            element={<Logout />}
                        />
                    </Route>
                </>
            ) : (
                <>
                    <Route
                        path="*"
                        element={<Navigate to={cPath("PUBLIC")} replace />}
                    />
                    <Route
                        path={cPath("PUBLIC")}
                        element={
                            <Suspense fallback={<AppLoader />}>
                                <LoginLayout />
                            </Suspense>
                        }
                    >
                        <Route
                            path={cPath("PUBLIC.SECURITY.LOGIN_PAGE", {}, true)}
                            element={<LoginPage />}
                        />
                        <Route
                            path={cPath(
                                "PUBLIC.SECURITY.FORGOT_PASSWORD_PAGE",
                                {},
                                true
                            )}
                            element={<ForgotPasswordPage />}
                        />
                        <Route
                            path={cPath(
                                "PUBLIC.SECURITY.SET_PASSWORD_PAGE",
                                {},
                                true
                            )}
                            element={<SetPasswordPage />}
                        />
                        <Route
                            path={cPath(
                                "PUBLIC.SECURITY.REGISTER_PAGE",
                                {},
                                true
                            )}
                            element={<RegisterPage />}
                        />
                    </Route>
                </>
            )}
        </Routes>
    );
};

export default RootRoutes;
