import { FC } from "react";
import { FormContainerStateReturnType, useTrans } from "../../../hooks";
import { VStack, Input } from "@chakra-ui/react";
import { AppFormControl } from "../../../components";
import { AppFormContainer } from "../../../containers";
import { APPC } from "../../../config";

export const WorkspaceForm: FC<{
    formContainerState: FormContainerStateReturnType;
    onFormSubmit;
    register;
    formState;
    data;
    loading;
}> = ({
    formContainerState,
    onFormSubmit,
    register,
    formState,
    data,
    loading,
}) => {
    // hook
    const { t } = useTrans();

    // state & const
    const { errors } = formState;

    return (
        <AppFormContainer
            title={t("padm.WorkspaceForm:text.pageTitle")}
            state={formContainerState}
            type={APPC.FORM_CONTAINER}
        >
            <form id="app-form" onSubmit={onFormSubmit}>
                <VStack gap={5} alignItems={"stretch"}>
                    <AppFormControl
                        label={t("ent.Workspace:name.label")}
                        isInvalid={!!errors?.name}
                        message={errors?.name?.message}
                        isLoading={loading}
                    >
                        <Input
                            type="text"
                            placeholder={t("ent.Workspace:name.placeholder")}
                            {...register("name")}
                            defaultValue={data.name}
                        />
                    </AppFormControl>
                </VStack>
            </form>
        </AppFormContainer>
    );
};
