import { Canceler } from "axios";
import { EntityApi } from "../../services/ApiPlatform";
import { PrimitiveObject } from "../../services/ApiPlatform/types";
import { FinalResponse, ListResponse } from "../../services/ApiPlatform/models";

export abstract class UserWorkspaceApi extends EntityApi {
    protected static GET_COLLECTION_MY_WORKSPACES = "/api/user-workspaces/me";

    protected static POST_COLLECTION_ACTIVE = "/api/user-workspaces/active";

    public static async getCollectionMyWorkspaces<R>(
        page = 1,
        extraParams: PrimitiveObject = {},
        cancelToken?: (c: Canceler) => void
    ): Promise<FinalResponse<ListResponse<R> | null>> {
        extraParams = {
            ...extraParams,
            PATH: this.GET_COLLECTION_MY_WORKSPACES,
        };
        return this.getCollection(page, extraParams, cancelToken);
    }

    public static async postWorkspaceActive<R, P>(
        entity: P,
        extraParams = {}
    ): Promise<FinalResponse<R | null>> {
        extraParams = { ...extraParams, PATH: this.POST_COLLECTION_ACTIVE };
        return this.postCollection(entity, extraParams);
    }
}
