import { BaseEntity } from "./BaseEntity";
import { Company } from "./Company";
import { Project } from "./Project";
import { Task } from "./Task";

export class KanbanStatus extends BaseEntity {
    public name: string = "";
    public type: string = "";
    public project: string | Project = "";
    public ord?: number = 0;
    public tasks?: string[] | Task[] = [];
    public company?: string | Company = "";

    constructor(model: Partial<KanbanStatus> = {}) {
        super(model?.id);
        Object.assign(this, model);
    }
}

export type PKanbanStatus = Partial<KanbanStatus>;
