import { ChangeEvent, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import {
    Box,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    Text,
    useDisclosure,
    Button,
    PopoverCloseButton,
    FormLabel,
    Flex,
    HStack,
    useToast,
    Input,
    Switch,
    FormControl,
    VStack,
} from "@chakra-ui/react";
import {
    AppIcon,
    AppLoader,
    AppAsyncSelect,
    AppSelectOption,
} from "../../components";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Timesheet, TimesheetApi, Task, TaskApi, ProjectApi } from "../../apis";
import {
    TimesheetManualPayload,
    TimesheetStartPayload,
} from "../../apis/entities/dtos";

import { ProjectListAtom } from "../../atoms";

dayjs.extend(utc);

export const AppStopWatch = () => {
    // hooks
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    // state & const
    const [isLoading, setIsLoading] = useState(true);
    const [start, setStart] = useState<number>(0);
    const [displyTime, setDisplyTime] = useState("00:00:00");
    const [isRunning, setIsRunning] = useState(false);
    const [isActionDisable, setIsActionDisable] = useState(false);
    const [isOpenManual, setIsOpenManual] = useState(false);
    const [isAllowManual] = useState(true);

    // const [projectData, setProjectData] = useState<AppSelectOption[]>([]);
    const [selectedProject, setSelectedProject] = useState<
        string | undefined
    >();
    const [taskData, setTaskData] = useState<AppSelectOption[]>([]);
    const [selectedTask, setSelectedTask] = useState<string | undefined>();
    const [selectedTimesheet, setSelectedTimesheet] =
        useState<Timesheet | null>(null);

    const [selectedDate, setSelectedDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [isBillable, setIsBillable] = useState(true);
    const [manualTimesheet, setManualTimesheet] =
        useState<TimesheetManualPayload>({} as TimesheetManualPayload);

    const projectData = useRecoilValue(ProjectListAtom)?.map((e) => {
        return {
            value: ProjectApi.toResourceIRI(e.id),
            label: e.name,
        };
    });

    const fetchTaskData = async (projectId: string | number | undefined) => {
        if (!projectId) {
            setTaskData([]);
            setSelectedTask(undefined);
            return;
        }
        const params = {
            "project.id": projectId,
            "order[name]": "asc",
            pagination: false,
        };

        await TaskApi.getCollection<Task>(1, params).then(
            ({ errorMessage, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    const data = response?.items?.map((e) => {
                        setSelectedTask(selectedTimesheet?.task as string);
                        return {
                            value: TaskApi.toResourceIRI(e.id),
                            label: e.name,
                        };
                    });
                    setTaskData(data);
                }
            }
        );
    };

    const fetchCurrentTimesheet = async () => {
        await TimesheetApi.getCollectionRunning<Timesheet>().then(
            ({ errorMessage, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    if (response.totalItems > 0) {
                        const ts = response.items[0];
                        setSelectedTimesheet(ts);
                        setSelectedProject(ts?.project as string);
                        setStart(dayjs(ts.startAt).valueOf());
                        setIsRunning(true);
                    }
                }
            }
        );
    };

    const startStopHandler = () => {
        if (!selectedTask) {
            return;
        }
        setIsActionDisable(true);
        if (isOpenManual) {
            // ToDo: Add manual time logic here...
            TimesheetApi.postStartManual<Timesheet, TimesheetManualPayload>(
                manualTimesheet
            )
                .then(({ response, errorMessage }) => {
                    if (errorMessage) {
                        toast({
                            title: errorMessage,
                            status: "error",
                        });
                    } else if (response !== null) {
                        onClose();
                    }
                })
                .finally(() => setIsActionDisable(false));
            return;
        }
        if (isRunning) {
            TimesheetApi.postEnd<Timesheet, object>({})
                .then(({ response, errorMessage }) => {
                    if (errorMessage) {
                        toast({
                            title: errorMessage,
                            status: "error",
                        });
                    } else if (response !== null) {
                        setStart(0);
                        setDisplyTime("00:00:00");
                        setIsRunning(!isRunning);
                        setSelectedTimesheet(null);
                    }
                })
                .finally(() => setIsActionDisable(false));
        } else {
            TimesheetApi.postStart<Timesheet, TimesheetStartPayload>({
                task: selectedTask,
                isBillable: isBillable,
            })
                .then(({ response, errorMessage }) => {
                    if (errorMessage) {
                        toast({
                            title: errorMessage,
                            status: "error",
                        });
                    } else if (response !== null) {
                        setSelectedTimesheet(response);
                        setStart(dayjs().valueOf());
                        setIsRunning(!isRunning);
                    }
                })
                .finally(() => setIsActionDisable(false));
        }
    };

    useEffect(() => {
        const fetch = async () => {
            await fetchCurrentTimesheet();
            setIsLoading(false);
        };
        fetch();
    }, []);

    useEffect(() => {
        fetchTaskData(selectedProject);
    }, [selectedProject]);

    const updateTimer = () => {
        const secondsFromStart = dayjs().valueOf() - start;
        setDisplyTime(dayjs(secondsFromStart).utc().format("HH:mm:ss"));
    };

    useEffect(() => {
        if (isRunning) {
            const timer = setTimeout(updateTimer, 1000);
            return () => clearTimeout(timer);
        }
    }, [displyTime, isRunning]);

    const handleManualTimesheet = (e: ChangeEvent<HTMLInputElement>) => {
        const mts: TimesheetManualPayload = {
            startAt: "",
            endAt: "",
            task: selectedTask as string,
            isBillable: isBillable,
            note: "Time added manually",
        };

        const startDateTime = dayjs(
            `${selectedDate} ${startTime}`,
            "YYYY-MM-DD HH:mm:ss"
        );
        let endDateTime = dayjs(
            `${selectedDate} ${e.target.value}`,
            "YYYY-MM-DD HH:mm:ss"
        );

        if (endDateTime.isBefore(startDateTime)) {
            endDateTime = endDateTime.add(1, "day");
        }

        if (
            startDateTime.isAfter(dayjs()) ||
            endDateTime.isAfter(dayjs()) ||
            endDateTime.isSame(startDateTime)
        ) {
            setSelectedDate("");
            setStartTime("");
            setEndTime("");
            return;
        }

        mts.startAt = dayjs(startDateTime).format("YYYY-MM-DD HH:mm:ss");
        mts.endAt = dayjs(endDateTime).format("YYYY-MM-DD HH:mm:ss");
        setManualTimesheet(mts);
    };

    if (isLoading) {
        return <AppLoader />;
    }

    return (
        <>
            <Popover
                placement="bottom-start"
                offset={[0, -1]}
                isOpen={isOpen}
                onClose={onClose}
            >
                <PopoverTrigger>
                    <Box zIndex={100} display={"flex"} flexWrap={"nowrap"}>
                        <Box
                            width="150px"
                            height="50px"
                            padding={4}
                            bg={
                                isOpen && !isOpenManual
                                    ? "var(--chakra-colors-primaryT95)"
                                    : "var(--chakra-colors-white)"
                            }
                            border={"1px solid var(--chakra-colors-primaryT80)"}
                            borderTopLeftRadius={"0.25rem"}
                            borderTopRightRadius={"0.25rem"}
                            borderBottomLeftRadius={
                                isOpen && !isOpenManual ? 0 : "0.25rem"
                            }
                            borderBottomRightRadius={
                                isOpen && !isOpenManual ? 0 : "0.25rem"
                            }
                            display="inline-flex"
                            position={"relative"}
                            justifyContent="space-between"
                            alignItems="center"
                            fontWeight={500}
                            cursor="pointer"
                            as="button"
                            borderBottom={
                                isOpen && !isOpenManual
                                    ? "1px solid var(--chakra-colors-primaryT95)"
                                    : ""
                            }
                            onClick={() => {
                                setIsOpenManual(false);
                                isOpen ? onClose() : onOpen();
                            }}
                        >
                            <span
                                style={{
                                    fontSize: "8px",
                                    position: "absolute",
                                    top: "-5px",
                                    background: "var(--chakra-colors-white)",
                                    padding: "0 8px",
                                }}
                            >
                                TIME TRACKER
                            </span>
                            <AppIcon name={"icl-clock"} w="1.25rem" />
                            <Text
                                fontSize={18}
                                mt={1}
                                fontFamily="Courier"
                                textAlign="center"
                            >
                                {displyTime}
                            </Text>
                        </Box>
                        {isAllowManual && (
                            <Box
                                width="54px"
                                height="50px"
                                padding={4}
                                ml={2}
                                bg={
                                    isOpen && isOpenManual
                                        ? "var(--chakra-colors-primaryT95)"
                                        : "var(--chakra-colors-white)"
                                }
                                border={
                                    "1px solid var(--chakra-colors-primaryT80)"
                                }
                                borderTopLeftRadius={"0.25rem"}
                                borderTopRightRadius={"0.25rem"}
                                borderBottomLeftRadius={
                                    isOpen && isOpenManual ? 0 : "0.25rem"
                                }
                                borderBottomRightRadius={
                                    isOpen && isOpenManual ? 0 : "0.25rem"
                                }
                                display="inline-flex"
                                justifyContent="space-between"
                                alignItems="center"
                                fontWeight={500}
                                cursor="pointer"
                                as="button"
                                borderBottom={
                                    isOpen && isOpenManual
                                        ? "1px solid var(--chakra-colors-primaryT95)"
                                        : ""
                                }
                                onClick={() => {
                                    setIsOpenManual(true);
                                    isOpen ? onClose() : onOpen();
                                }}
                                disabled={isRunning}
                            >
                                <AppIcon name={"icl-plus"} w="1.25rem" />
                            </Box>
                        )}
                    </Box>
                </PopoverTrigger>
                <PopoverContent
                    width="500px"
                    padding={6}
                    bg={"var(--chakra-colors-primaryT95)"}
                    boxShadow="lg"
                    border={"1px solid var(--chakra-colors-primaryT80)"}
                    borderTopLeftRadius={0}
                    borderTopRightRadius={"0.25rem"}
                    borderBottomLeftRadius={"0.25rem"}
                    borderBottomRightRadius={"0.25rem"}
                    zIndex={100}
                >
                    <PopoverCloseButton />
                    <PopoverBody padding={0}>
                        {isOpen && (
                            <Flex gap={4} direction={"column"}>
                                <Box>
                                    <FormLabel>Project</FormLabel>
                                    <AppAsyncSelect<AppSelectOption>
                                        defaultOptions={projectData}
                                        onChange={(e) =>
                                            setSelectedProject(`${e?.value}`)
                                        }
                                        value={projectData?.filter(
                                            (d) => d.value === selectedProject
                                        )}
                                        placeholder="Select Project"
                                        isLoading={isLoading}
                                        isDisabled={isRunning}
                                        isClearable={true}
                                    />
                                </Box>
                                <Box>
                                    <FormLabel>Task</FormLabel>
                                    <AppAsyncSelect<AppSelectOption>
                                        defaultOptions={taskData}
                                        onChange={(e) =>
                                            setSelectedTask(`${e?.value}`)
                                        }
                                        value={taskData?.filter(
                                            (d) => d.value === selectedTask
                                        )}
                                        placeholder="Select Task"
                                        isLoading={isLoading}
                                        isDisabled={isRunning}
                                        isClearable={true}
                                    />
                                </Box>
                                {isOpenManual && (
                                    <VStack alignItems={"flex-start"} gap={4}>
                                        <HStack>
                                            <Box>
                                                <FormLabel>Date</FormLabel>
                                                <Input
                                                    placeholder={dayjs().format(
                                                        "DD/MM/YYYY"
                                                    )}
                                                    type="date"
                                                    value={selectedDate}
                                                    isDisabled={
                                                        selectedTask
                                                            ? false
                                                            : true
                                                    }
                                                    min={dayjs()
                                                        .subtract(30, "day")
                                                        .format("YYYY-MM-DD")}
                                                    max={dayjs().format(
                                                        "YYYY-MM-DD"
                                                    )}
                                                    onChange={(e) =>
                                                        setSelectedDate(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </Box>
                                            <Box>
                                                <FormLabel>
                                                    Start Time
                                                </FormLabel>
                                                <Input
                                                    placeholder="Start Time"
                                                    type="time"
                                                    isDisabled={
                                                        selectedTask &&
                                                        selectedDate
                                                            ? false
                                                            : true
                                                    }
                                                    value={startTime}
                                                    onChange={(e) =>
                                                        setStartTime(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </Box>
                                            <Box>
                                                <FormLabel>End Time</FormLabel>
                                                <Input
                                                    placeholder="End Time"
                                                    type="time"
                                                    isDisabled={
                                                        selectedDate &&
                                                        startTime
                                                            ? false
                                                            : true
                                                    }
                                                    value={endTime}
                                                    onChange={(e) => {
                                                        setEndTime(
                                                            e.target.value
                                                        );
                                                        handleManualTimesheet(
                                                            e
                                                        );
                                                    }}
                                                />
                                            </Box>
                                        </HStack>
                                        <Box>
                                            <FormControl
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <FormLabel mb="0">
                                                    Billable?
                                                </FormLabel>
                                                <Switch
                                                    size={"sm"}
                                                    isChecked={isBillable}
                                                    onChange={() =>
                                                        setIsBillable(
                                                            (prev) => !prev
                                                        )
                                                    }
                                                />
                                            </FormControl>
                                        </Box>
                                    </VStack>
                                )}
                                <HStack
                                    justifyContent={"flex-end"}
                                    marginTop={4}
                                >
                                    <Button
                                        onClick={startStopHandler}
                                        size="sm"
                                        isDisabled={
                                            isActionDisable ||
                                            isLoading ||
                                            !selectedTask
                                        }
                                    >
                                        {isOpenManual
                                            ? "ADD TIME"
                                            : isRunning
                                              ? "STOP"
                                              : "START"}
                                    </Button>
                                </HStack>
                            </Flex>
                        )}
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </>
    );
};
