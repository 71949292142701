import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";

const baseStyle = defineStyle({
    fontFamily: "Inter Regular, sans-serif",
    fontWeight: 400,
    color: "formLabelText",
    _disabled: {
        color: "var(--chakra-colors-greyT60)",
        opacity: 1,
    },
});

const sizes = {
    xs: defineStyle({
        mb: "0.318rem",
        fontSize: "0.5775rem",
        lineHeight: "0.8665rem",
    }),
    sm: defineStyle({
        mb: "0.414rem",
        fontSize: "0.7262rem",
        lineHeight: "1.0895rem",
    }),
    md: defineStyle({
        mb: 2,
        fontSize: "0.875rem",
        lineHeight: "1.3125rem",
    }),
    lg: defineStyle({
        mb: "0.582rem",
        fontSize: "1.02rem",
        lineHeight: "1.5356rem",
    }),
};

export const FormLabel = defineStyleConfig({
    baseStyle,
    sizes,
    defaultProps: {
        size: "md",
    },
});
