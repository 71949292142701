import { Tooltip, TooltipProps } from "@chakra-ui/react";

export const AppTooltip = (props: TooltipProps) => {
    return (
        <Tooltip
            hasArrow
            bg={"var(--chakra-colors-greyT25)"}
            color={"var(--chakra-colors-white)"}
            placement="auto"
            openDelay={1000}
            {...props}
        >
            {props.children}
        </Tooltip>
    );
};
