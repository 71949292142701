import { Box, useToast } from "@chakra-ui/react";
import { Project, ProjectApi } from "../apis";
import { useEffect, useRef, useState } from "react";
import { MultiValue, SingleValue } from "react-select";
import CreatableSelect from "react-select/creatable";

interface Option {
    value: string;
    label: string;
}

const mutlioptions: Option[] = [
    {
        value: ProjectApi.toResourceIRI(3),
        label: "zomato",
    },
    {
        value: ProjectApi.toResourceIRI(9),
        label: "birla",
    },
];

const singleOption: Option = {
    value: ProjectApi.toResourceIRI(9),
    label: "birla",
};

console.log(mutlioptions, singleOption);

export const AppSelectLazy = () => {
    const optionRef = useRef(100);
    const toast = useToast();
    const [loading, setLoading] = useState(false);
    const [isMultiSelect] = useState(true);
    const [data, setData] = useState<Option[]>([]);
    const [totalItems, setTotalItems] = useState(0);
    const [page, setPage] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [selectedOption, setSelectedOption] = useState<Option[] | Option>(
        isMultiSelect ? mutlioptions : singleOption
    );

    const pageSize = 10;

    const loadOptions = (inputValue: string) => {
        setLoading(true);
        const params = {
            itemsPerPage: pageSize,
            [`order[name]`]: "asc",
            pagination: true,
        };

        if (inputValue) {
            params["name"] = inputValue;
        }

        ProjectApi.getCollection<Project>(page, params)
            .then(({ errorMessage, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    setTotalItems(response.totalItems);
                    const newOptions =
                        response.items?.map((e) => ({
                            value: ProjectApi.toResourceIRI(e.id),
                            label: e.name,
                        })) || [];
                    page === 1
                        ? setData(newOptions)
                        : setData((prevOptions) => [
                              ...prevOptions,
                              ...newOptions,
                          ]);
                }
            })
            .finally(() => setLoading(false));
    };

    const handleMenuScrollToBottom = () => {
        if (data.length >= totalItems) {
            return;
        }
        setPage((prevPage) => prevPage + 1);
    };

    const handleInputChange = (inputValue: string) => {
        if (inputValue !== searchValue) {
            setSearchValue(inputValue);
            if (page !== 1) {
                setPage(1);
            }
            setData([]);
        }
    };

    const handleChange = (value: MultiValue<Option> | SingleValue<Option>) => {
        // console.log(value);
        if (Array.isArray(value)) {
            setSelectedOption(value);
        } else {
            setSelectedOption(value as Option);
        }
    };

    const handleBlur = () => {
        if (page !== 1) {
            setData([]);
            setPage(1);
            setSearchValue("");
        }
    };

    const createOption = (label: string): Option => {
        const newOption = {
            value: ProjectApi.toResourceIRI(optionRef.current),
            label: label,
        };
        optionRef.current++;
        return newOption;
    };

    const handleCreate = (inputValue: string) => {
        setLoading(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setLoading(false);
            setData((prevOptions) => [...prevOptions, newOption]);
            setSelectedOption((prev) =>
                isMultiSelect ? [...(prev as Option[]), newOption] : newOption
            );
        }, 1000);
    };

    useEffect(() => {
        loadOptions(searchValue);
    }, [page, searchValue]);

    return (
        <>
            <Box>Total Items: {totalItems}</Box>
            <Box>DropDown Items: {data?.length}</Box>
            <Box>Search Value: {searchValue}</Box>
            <Box>Page: {page}</Box>
            <CreatableSelect
                isClearable
                isMulti={isMultiSelect}
                isLoading={loading}
                options={data}
                value={selectedOption}
                placeholder="Select Project"
                onCreateOption={handleCreate}
                onChange={handleChange}
                onMenuScrollToBottom={handleMenuScrollToBottom}
                onInputChange={handleInputChange}
                onBlur={handleBlur}
            />
        </>
    );
};
