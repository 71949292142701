import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { Box } from "@chakra-ui/react";
import { AppAsyncSelect, AppSelectOption } from "./AppAsyncSelect";
import { UserWorkspaceListAtom, ActiveUserWorkspaceAtom } from "../atoms";
import {
    UserWorkspace,
    UserWorkspaceApi,
    Workspace,
    WorkspaceApi,
} from "../apis";

export const AppWorkspaceSelector = () => {
    const navigate = useNavigate();

    const workspaceData = useRecoilValue(UserWorkspaceListAtom)?.map((e) => {
        return {
            value: (e.workspace as Workspace).id,
            label: (e.workspace as Workspace).name,
        };
    });
    const [activeUserWorkspace, setActiveUserWorkspace] = useRecoilState(
        ActiveUserWorkspaceAtom
    );

    const onChange = (value: number) => {
        if (value > 0) {
            UserWorkspaceApi.postWorkspaceActive<
                UserWorkspace,
                { workspace: string }
            >({
                workspace: WorkspaceApi.toResourceIRI(value),
            }).then(({ response }) => {
                if (response !== null) {
                    setActiveUserWorkspace(value);
                    navigate(0);
                }
            });
        }
    };

    return (
        <Box>
            <AppAsyncSelect<AppSelectOption>
                defaultOptions={workspaceData}
                onChange={(e) => onChange(Number(e?.value))}
                value={workspaceData?.filter(
                    (d) => d.value === activeUserWorkspace
                )}
                placeholder="Select Workspace"
                isClearable={false}
            />
        </Box>
    );
};
