import { Skeleton, VStack } from "@chakra-ui/react";

export const AppTableSkeleton = () => {
    return (
        <VStack w={"full"} alignItems={"flex-start"}>
            <Skeleton
                h="48px"
                w="full"
                startColor={"var(--chakra-colors-primaryT95)"}
                endColor={"var(--chakra-colors-primaryT80)"}
            />
            <Skeleton
                height="48px"
                w="full"
                startColor={"var(--chakra-colors-primaryT95)"}
                endColor={"var(--chakra-colors-primaryT80)"}
            />
            <Skeleton
                height="60px"
                w="full"
                startColor={"var(--chakra-colors-primaryT95)"}
                endColor={"var(--chakra-colors-primaryT80)"}
            />
        </VStack>
    );
};
