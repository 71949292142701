import { FC } from "react";
import { useDateTime, useTrans } from "../../../hooks";
import {
    Text,
    HStack,
    UseDisclosureReturn,
    VStack,
    Badge,
} from "@chakra-ui/react";
import { IKaTableProps, Table as KaTable } from "ka-table";
import { ICellTextProps } from "ka-table/props";
import {
    AppAlertDelete,
    AppOptionBoxItem,
    AppOptionsBox,
} from "../../../components";
import { Timesheet } from "../../../apis";
import dayjs from "dayjs";

export const TimesheetList: FC<{
    kaTableProps: IKaTableProps;
    actionOptions: AppOptionBoxItem[];
    actionClickHandler: (key: string, data?: Timesheet) => void;
    deleteHandler: () => Promise<void>;
    deleteAlertState: UseDisclosureReturn;
}> = ({
    kaTableProps,
    actionOptions,
    actionClickHandler,
    deleteHandler,
    deleteAlertState,
}) => {
    const { t } = useTrans();
    const { getFormattedTimeFromSeconds } = useDateTime();
    return (
        <>
            <KaTable
                {...kaTableProps}
                childComponents={{
                    ...kaTableProps.childComponents,
                    cellText: {
                        content: (props: ICellTextProps) => {
                            switch (props?.column?.key) {
                                case "task.name":
                                    return (
                                        <VStack
                                            gap={1}
                                            alignItems={"flex-start"}
                                        >
                                            <Text textStyle={"mediumSm"}>
                                                {props?.rowData?.task?.name}
                                            </Text>
                                            <Text textStyle={"regularXs"}>
                                                {props?.rowData?.project?.name}
                                            </Text>
                                        </VStack>
                                    );
                                case "startAt":
                                    return (
                                        <VStack
                                            gap={1}
                                            alignItems={"flex-start"}
                                        >
                                            <Text textStyle={"mediumSm"}>
                                                {dayjs(
                                                    props?.rowData.startAt
                                                ).format("DD/MM/YYYY")}
                                            </Text>
                                            <Text textStyle={"regularXs"}>
                                                {`${dayjs(
                                                    props?.rowData.startAt
                                                ).format("HH:mm:ss")} - ${dayjs(
                                                    props?.rowData.endAt
                                                ).format("HH:mm:ss")}`}
                                            </Text>
                                        </VStack>
                                    );
                                case "duration":
                                    return (
                                        <Text textStyle={"regularXs"}>
                                            {getFormattedTimeFromSeconds(
                                                props?.rowData.duration
                                            )}
                                        </Text>
                                    );
                                case "isManual":
                                    return (
                                        <Badge
                                            variant={
                                                props?.rowData?.isManual
                                                    ? "red"
                                                    : "green"
                                            }
                                        >
                                            {t(
                                                `ent.Timesheet:isManual.${props?.rowData?.isManual}`
                                            )}
                                        </Badge>
                                    );
                                case "isBillable":
                                    return (
                                        <Badge
                                            variant={
                                                props?.rowData?.isBillable
                                                    ? "green"
                                                    : "red"
                                            }
                                        >
                                            {t(
                                                `ent.Timesheet:isBillable.${props?.rowData?.isBillable}`
                                            )}
                                        </Badge>
                                    );
                                case "action":
                                    return (
                                        <HStack justify={"flex-end"} gap={3}>
                                            <AppOptionsBox<Timesheet>
                                                options={actionOptions}
                                                data={props?.rowData}
                                                onClick={actionClickHandler}
                                            />
                                        </HStack>
                                    );
                            }
                        },
                    },
                }}
            />
            {deleteAlertState.isOpen && (
                <AppAlertDelete
                    isOpenDelete={deleteAlertState.isOpen}
                    deleteHandler={() => deleteHandler()}
                    onCloseDelete={deleteAlertState.onClose}
                />
            )}
        </>
    );
};
