type UseDateTimeReturnType = {
    getFormattedTimeFromSeconds: (seconds: number) => string;
};

export const useDateTime = (): UseDateTimeReturnType => {
    const getFormattedTimeFromSeconds = (seconds: number): string => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const sec = Math.floor((seconds % 3600) % 60);
        let formattedTime = "";
        if (hours > 0) {
            formattedTime += `${hours}h `;
        }
        if (minutes > 0) {
            formattedTime += `${minutes}m `;
        }
        if (sec > 0) {
            formattedTime += `${sec}s`;
        }
        return formattedTime;
    };
    return {
        getFormattedTimeFromSeconds,
    };
};
