import { checkboxAnatomy } from "@chakra-ui/anatomy";
import {
    createMultiStyleConfigHelpers,
    defineStyle,
} from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyleContainer = defineStyle({
    display: "flex",
    alignItems: "center",
    _disabled: { cursor: "not-allowed" },
});

const baseStyleControl = defineStyle({
    border: "1px solid",
});

const baseStyleLabel = defineStyle({
    alignSelf: "center",
    userSelect: "none",
    _disabled: { opacity: 1 },
});

const baseStyle = definePartsStyle({
    container: baseStyleContainer,
    control: baseStyleControl,
    label: baseStyleLabel,
});

const sizes = {
    sm: definePartsStyle({
        container: {
            // padding: "0.25rem",
        },
        control: {
            boxSize: 4,
        },
        label: { fontSize: "0.7262rem", ms: 1.5 },
        icon: { boxSize: "0.60rem" },
    }),
    md: definePartsStyle({
        container: {
            // padding: "0.375rem",
        },
        control: {
            boxSize: 5,
        },
        label: { fontSize: "0.875rem", ms: 2 },
        icon: { boxSize: "0.70rem" },
    }),
    lg: definePartsStyle({
        container: {
            // padding: "0.5rem",
        },
        control: {
            boxSize: 6,
        },
        label: { fontSize: "1.02rem", ms: 2.5 },
        icon: { fontSize: "0.80rem" },
    }),
};

const primary = definePartsStyle({
    control: {
        borderRadius: "0.25rem",
        borderColor: "primaryCheckboxControlBorder",
        bg: "primaryCheckboxControlBg",

        _hover: {
            border: "1.2px solid var(--chakra-colors-primaryCheckboxControlHoverBorder)",
        },
        _focusVisible: {
            borderColor: "primaryCheckboxControlFocusBorder",
            boxShadow: "var(--chakra-colors-boxShadowFormEle)",
        },
        _disabled: {
            opacity: 1,
            cursor: "not-allowed",
            border: "1.5px solid var(--chakra-colors-primaryCheckboxControlDisabledBorder)",
            bg: "primaryCheckboxControlDisabledBg",
            _hover: {
                opacity: 1,
                cursor: "not-allowed",
                border: "1.5px solid var(--chakra-colors-primaryCheckboxControlDisabledHoverBorder)",
                bg: "primaryCheckboxControlDisabledHoverBg",
            },
        },

        _checked: {
            border: "none",
            bg: "primaryCheckboxControlCheckedBg",
            _hover: {
                border: "none",
                bg: "primaryCheckboxControlCheckedHoverBg",
            },
            _focusVisible: {
                borderColor: "primaryCheckboxControlFocusBorder",
                boxShadow: "var(--chakra-colors-boxShadowFormEle)",
            },
            _disabled: {
                opacity: 1,
                cursor: "not-allowed",
                border: "none",
                bg: "primaryCheckboxControlCheckedDisabledBg",
                stroke: "primaryCheckboxControlCheckedDisabledStroke !important",
                _hover: {
                    opacity: 1,
                    cursor: "not-allowed",
                    border: "none",
                    bg: "primaryCheckboxControlCheckedDisabledHoverBg",
                    stroke: "primaryCheckboxControlCheckedDisabledHoverStroke",
                },
            },
        },

        _indeterminate: {
            border: "none",
            bg: "primaryCheckboxControlIndeterminateBg",
            _hover: {
                border: "none",
                bg: "primaryCheckboxControlIndeterminateHoverBg",
            },
            _focusVisible: {
                borderColor: "primaryCheckboxControlFocusBorder",
                boxShadow: "var(--chakra-colors-boxShadowFormEle)",
            },
            _disabled: {
                opacity: 1,
                cursor: "not-allowed",
                border: "none",
                bg: "primaryCheckboxControlIndeterminateDisabledBg",
                _hover: {
                    opacity: 1,
                    cursor: "not-allowed",
                    border: "none",
                    bg: "primaryCheckboxControlIndeterminateDisabledHoverBg",
                },
            },
        },
    },
    label: {
        color: "primaryCheckboxLabelText",
        _disabled: {
            opacity: "1",
            cursor: "not-allowed",
            color: "primaryCheckboxLabelDisabledText",
            _hover: {
                color: "primaryCheckboxLabelDisabledHoverText",
            },
        },
    },
    icon: {
        color: "primaryCheckboxIcon",
    },
});

const variants = {
    primary,
};

export const Checkbox = defineMultiStyleConfig({
    baseStyle,
    sizes,
    variants,
    defaultProps: {
        size: "md",
        variant: "primary",
    },
});
