import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useFormContainerState, useTrans } from "../../../hooks";
import {
    Button,
    HStack,
    Divider,
    Center,
    Text,
    Hide,
    Flex,
    Box,
} from "@chakra-ui/react";
import {
    AppIcon,
    AppPageHeader,
    AppTaskCard,
    AppTaskColumn,
} from "../../../components";
import { TaskForm as FormComponent } from "./TaskForm";
import { AppSearch } from "../../../containers";
import { KanbanStatus, KanbanStatusApi, Task, TaskApi } from "../../../apis";
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult,
} from "react-beautiful-dnd";
import { SelectedProjectAtom } from "../../../atoms";

export const TaskKanbanPage = () => {
    // hook
    const { t } = useTrans();
    const formContainerState = useFormContainerState();

    // state & const
    const [, setSearch] = useState("");
    const [kanbanStatusData, setKanbanStatusData] = useState<KanbanStatus[]>(
        []
    );

    const selectedProject = useRecoilValue(SelectedProjectAtom);

    const fetchKanbanStatusData = () => {
        KanbanStatusApi.getCollectioForBoard<KanbanStatus>(1, {
            "project.id": selectedProject,
            ["order[ord]"]: "asc",
        }).then(({ response }) => {
            if (response !== null) {
                setKanbanStatusData(response?.items);
            }
        });
    };
    useEffect(() => {
        fetchKanbanStatusData();
    }, [selectedProject]);

    const onDragEnd = (result: DropResult) => {
        console.log(result);
        const { source, destination } = result;
        if (
            !destination ||
            (source.droppableId === destination.droppableId &&
                source.index === destination.index)
        ) {
            return;
        }

        const [, sourceKanbanStatusId] = source.droppableId.split("-");
        const sourceTasks = kanbanStatusData.find(
            (x) => x.id === Number(sourceKanbanStatusId)
        )?.tasks as Task[];
        const [changeElement] = sourceTasks?.splice(source.index, 1) as Task[];

        if (source.droppableId === destination.droppableId) {
            sourceTasks?.splice(destination.index, 0, changeElement);
            const data = [
                ...kanbanStatusData.map((e) =>
                    e.id === Number(sourceKanbanStatusId)
                        ? ({ ...e, tasks: sourceTasks } as KanbanStatus)
                        : e
                ),
            ];
            setKanbanStatusData(data);
            TaskApi.patchItemChangeOrder<Task, { newOrd: number }>(
                changeElement.id,
                {
                    newOrd: destination.index + 1,
                }
            ).then();
        } else {
            const [, destinationKanbanStatusId] =
                destination.droppableId.split("-");
            const destinationTasks = kanbanStatusData.find(
                (x) => x.id === Number(destinationKanbanStatusId)
            )?.tasks as Task[];
            destinationTasks?.splice(destination.index, 0, changeElement);

            TaskApi.patchItemChangeOrder<
                Task,
                { newOrd: number; kanbanStatus: string }
            >(changeElement.id, {
                newOrd: destination.index + 1,
                kanbanStatus: KanbanStatusApi.toResourceIRI(
                    destinationKanbanStatusId
                ),
            }).then();

            setKanbanStatusData(
                kanbanStatusData.map((e) => {
                    if (e.id === Number(sourceKanbanStatusId)) {
                        return { ...e, tasks: sourceTasks } as KanbanStatus;
                    } else if (e.id === Number(destinationKanbanStatusId)) {
                        return {
                            ...e,
                            tasks: destinationTasks,
                        } as KanbanStatus;
                    }
                    return e;
                })
            );
        }
    };
    console.log("Render:TaskKanbanPage");
    return (
        <>
            <AppPageHeader title={t("padm.TaskKanbanPage:text.pageTitle")}>
                <HStack gap={4} py={{ base: 3.5, sm: 4, md: "1.125rem" }}>
                    <AppSearch onSearch={(value) => setSearch(value)} />
                    <Center height="2.1875rem">
                        <Divider orientation="vertical" />
                    </Center>
                    <Button
                        variant={"primary"}
                        onClick={() => formContainerState.open(0)}
                    >
                        <AppIcon name="ics-plus-circle" w="1rem" />
                        <Hide below="md">
                            <Text ml={2}>
                                {t("padm.TaskKanbanPage:button.create")}
                            </Text>
                        </Hide>
                    </Button>
                </HStack>
            </AppPageHeader>
            <DragDropContext onDragEnd={onDragEnd}>
                <Flex gap={2} padding={6} flexWrap={"nowrap"} flexGrow={1}>
                    {kanbanStatusData.map((kanbanStatus) => {
                        return (
                            <Droppable
                                droppableId={`KanbanStatus-${kanbanStatus.id}`}
                                direction={"vertical"}
                                key={kanbanStatus.id}
                            >
                                {(provided, snapshot) => (
                                    <Box
                                        minW={"309px"}
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        <AppTaskColumn
                                            kanbanStatus={kanbanStatus}
                                            snapshot={snapshot}
                                        >
                                            <>
                                                {kanbanStatus?.tasks?.map(
                                                    (task, index) => {
                                                        return (
                                                            <Draggable
                                                                draggableId={`task-${task.id}`}
                                                                key={task.id}
                                                                index={index}
                                                            >
                                                                {(provided) => (
                                                                    <div
                                                                        className="task-draggable"
                                                                        ref={
                                                                            provided.innerRef
                                                                        }
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <AppTaskCard
                                                                            task={
                                                                                task
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    }
                                                )}
                                            </>
                                        </AppTaskColumn>
                                        {provided.placeholder}
                                    </Box>
                                )}
                            </Droppable>
                        );
                    })}
                </Flex>
            </DragDropContext>
            {formContainerState.isOpen && (
                <FormComponent formContainerState={formContainerState} />
            )}
        </>
    );
};
