import { switchAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(switchAnatomy.keys);

const sizes = {
    sm: definePartsStyle({
        track: {
            width: "1.4rem",
            height: 3,
            p: 1,
        },
        thumb: { width: 3, height: 3 },
    }),
    md: definePartsStyle({
        track: {
            width: "1.875rem",
            height: 4,
            p: 1,
        },
        thumb: { width: 4, height: 4 },
    }),
    lg: definePartsStyle({
        track: {
            width: "2.875rem",
            height: 6,
            p: 1,
        },
        thumb: { width: 6, height: 6 },
    }),
};

const primary = definePartsStyle({
    track: {
        border: "1px solid var(--chakra-colors-primarySwitchTrackBorder)",
        bg: "primarySwitchTrackBg",
        _checked: {
            bg: "primarySwitchTrackCheckedBg",
        },
        _focusVisible: {
            borderColor: "primarySwitchTrackFocusBorder",
            boxShadow: "var(--chakra-colors-boxShadowFormEle)",
        },
        _disabled: {
            opacity: 1,
            bg: "primarySwitchTrackDisabledBg",
            borderColor: "primarySwitchTrackDisabledBorder",
        },
    },
    thumb: {
        bg: "primarySwitchThumbBg",
        _checked: {
            bg: "primarySwitchThumbCheckedBg",
        },
        _disabled: {
            opacity: 1,
            bg: "primarySwitchThumbDisabledBg",
        },
    },
});

const variants = {
    primary,
};

export const Switch = defineMultiStyleConfig({
    variants,
    sizes,
    defaultProps: {
        size: "md",
        variant: "primary",
    },
});
