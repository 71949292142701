import { useTrans } from "../../../hooks";
// import { CheckIcon } from "@chakra-ui/icons";
import {
    AppFormControl,
    AppIcon,
    // AppIcon,
    // AppTaskColumn,
    AppPageHeader,
    AppTab,
    AppTaskModal,
    // AppTab,
    // AppReactSelectTest,
    // AppDragAndDropTest,
    // AppKanban,
    // AppDragAndDropTest,
} from "../../../components";
import {
    Badge,
    Box,
    Button,
    Checkbox,
    Text,
    // Tabs,
    // TabList,
    // TabPanels,
    // Tab,
    // TabPanel,
    // Text,
    // NumberInput,
    // NumberInputField,
    // NumberInputStepper,
    // NumberIncrementStepper,
    // NumberDecrementStepper,
    // Box,
    // HStack,
    // Badge,
    // Button,
    // Checkbox,
    // FormControl,
    // FormLabel,
    // FormHelperText,
    // InputGroup,
    // Input,
    // InputLeftElement,
    // InputRightElement,
    // Hide,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Radio,
    Switch,
    Tab,
    TabList,
    Tabs,
    TabPanels,
    TabPanel,
    Select,
    Textarea,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
    InputLeftElement,
    InputRightElement,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    useToast,
} from "@chakra-ui/react";
import { CheckIcon, PhoneIcon } from "@chakra-ui/icons";
import { AppSelectLazy } from "../../../components/AppSelectLazy";
import { AppMultiSelector } from "../../../components/AppMultiSelector";
import { useState } from "react";
import { Project, ProjectApi } from "../../../apis";
import { AppMultiSelectorReturnType } from "../../../components/AppMultiSelector";

export const DashboardPage = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const { t } = useTrans();
    const [selectedProjects, setSelectedProjects] = useState<
        (string | number)[]
    >(["/api/projects/38", "/api/projects/33"]);

    console.log(selectedProjects);

    const loadOptions = async (
        page: number,
        search: string,
        pageSize: number
    ): Promise<AppMultiSelectorReturnType> => {
        const params = {
            itemsPerPage: pageSize,
            [`order[name]`]: "asc",
            pagination: true,
        };

        if (search) {
            params["name"] = search;
        }

        try {
            const { errorMessage, response } =
                await ProjectApi.getCollection<Project>(page, params);

            if (errorMessage) {
                toast({
                    title: errorMessage,
                    status: "error",
                });
                return { data: [], totalItems: 0 };
            } else if (response !== null) {
                const newOptions =
                    response.items?.map((e) => ({
                        value: ProjectApi.toResourceIRI(e.id),
                        label: e.name,
                    })) || [];
                return {
                    data: newOptions,
                    totalItems: response.totalItems,
                };
            }
        } catch (error) {
            toast({
                title: "An error occurred while loading options",
                status: "error",
            });
            return { data: [], totalItems: 0 };
        }
        return { data: [], totalItems: 0 };
    };

    // const handleMenuScrollToBottom = () => {
    //     if (data.length >= totalItems) {
    //         return;
    //     }
    //     setPage((prevPage) => prevPage + 1);
    // };

    const onSelect = (data: (string | number)[]) => {
        setSelectedProjects(data);
        onClose();
    };

    return (
        <>
            <AppPageHeader
                title={t("padm.DashboardPage:text.dashboard")}
            ></AppPageHeader>
            <AppSelectLazy />
            <Button onClick={onOpen}>Select Projects</Button>
            <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Select Projects</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody p={6}>
                        <AppMultiSelector
                            onSelect={onSelect}
                            loadOptions={loadOptions}
                            selectedOpt={selectedProjects}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* <Hide>
                <AppPageHeader title="Dashboard" />
                <AppTab />

                <Tabs
                    ml={"100px"}
                    mt={"50px"}
                    position="relative"
                    variant="app"
                >
                    <TabList gap={"8px"}>
                        <Tab>
                            <AppIcon name={"icl-list"} w="1rem" />
                            <Text ml={"8px"} as={"span"}>
                                One
                            </Text>
                        </Tab>
                        <Tab>
                            <AppIcon name={"icl-clipboard"} w="1rem" />

                            <Text ml={"8px"} as={"span"}>
                                Two
                            </Text>
                        </Tab>
                        <Tab>
                            <AppIcon name={"icl-chart-table"} w="1rem" />
                            <Text ml={"8px"} as={"span"}>
                                Three
                            </Text>
                        </Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <p>one!</p>
                        </TabPanel>
                        <TabPanel>
                            <p>two!</p>
                        </TabPanel>
                        <TabPanel>
                            <p>three!</p>
                        </TabPanel>
                    </TabPanels>
                </Tabs>

                <Box ml={"100px"} mt={"50px"} w={"400px"}>
                    <FormControl>
                        <FormLabel>Number</FormLabel>
                        <NumberInput>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </FormControl>
                </Box>

                <HStack ml={"100px"} mt={"50px"}>
                    <Badge variant={"green"}>Badge</Badge>
                    <Badge variant={"blue"}>Badge</Badge>
                    <Badge variant={"red"}>Badge</Badge>
                    <Badge variant={"primary"}>Badge</Badge>
                    <Badge variant={"secondary"}>Badge</Badge>
                    <Badge variant={"green"} size={"lg"}>
                        Badge
                    </Badge>
                    <Button variant={"primary"} size={"xs"}>
                        Button
                    </Button>
                    <Button variant={"secondary"} size={"sm"}>
                        Button
                    </Button>
                    <Button variant={"outline"} size={"md"}>
                        Button
                    </Button>
                    <Button variant={"destructive"} size={"lg"}>
                        Button
                    </Button>
                </HStack>

                <HStack ml={"100px"} mt={"50px"}>
                    <Badge colorScheme="green">Badge</Badge>
                    <Badge colorScheme="red">Badge</Badge>
                </HStack>

                <Checkbox size={"sm"}>123</Checkbox>
                <FormControl>
                    <FormLabel>Email address</FormLabel>
                    <Input type="email" />
                    <FormHelperText>
                        Well never share your email.
                    </FormHelperText>
                </FormControl>

                <InputGroup>
                    <InputLeftElement
                        pointerEvents="none"
                        color="gray.300"
                        fontSize="1.2em"
                    >
                        $
                    </InputLeftElement>
                    <Input placeholder="Enter amount" />
                    <InputRightElement>
                        <CheckIcon color="green.500" />
                    </InputRightElement>
                </InputGroup>

                <Box marginTop={8} gap={2} padding={6}>
                    <AppReactSelectTest />
                </Box>

                <Flex
                    marginTop={8}
                    gap={2}
                    padding={6}
                    flexWrap={"wrap"}
                    flexGrow={1}
                >
                    <Box>
                        <AppTaskColumn />
                    </Box>
                    <Box>
                        <AppTaskColumn />
                    </Box>
                    <Box>
                        <AppTaskColumn />
                    </Box>
                    <Box>
                        <AppTaskColumn />
                    </Box>
                    <Box>
                        <AppTaskColumn />
                    </Box>
                </Flex>
            </Hide> */}
            <Flex direction={"column"}>
                <Flex
                    marginTop={8}
                    gap={2}
                    padding={6}
                    flexWrap={"wrap"}
                    flexGrow={1}
                >
                    {/* <AppDragAndDropTest /> */}
                    {/* <AppKanban /> */}
                    <AppTaskModal />
                </Flex>

                <Flex
                    direction={"column"}
                    marginTop={8}
                    gap={2}
                    padding={6}
                    flexWrap={"wrap"}
                    flexGrow={1}
                >
                    <Box>Hello world</Box>
                    <Box color={"#727272"}>Hello world</Box>
                </Flex>
                <Flex
                    marginTop={8}
                    gap={2}
                    padding={6}
                    flexWrap={"wrap"}
                    flexGrow={1}
                >
                    <Button variant={"primary"}>Button</Button>
                    <Button variant={"secondary"}>Button</Button>
                    <Button variant={"outline"}>Button</Button>
                    <Button variant={"destructive"}>Button</Button>
                    <Box>
                        <Button variant={"transparent"}>Button</Button>
                    </Box>
                    <Button variant={"tab"}>Button</Button>
                </Flex>

                <Flex
                    marginTop={8}
                    gap={2}
                    padding={6}
                    flexWrap={"wrap"}
                    flexGrow={1}
                >
                    <Button variant={"primary"} isDisabled>
                        Button
                    </Button>
                    <Button variant={"secondary"} isDisabled>
                        Button
                    </Button>
                    <Button variant={"outline"} isDisabled>
                        Button
                    </Button>
                    <Button variant={"destructive"} isDisabled>
                        Button
                    </Button>
                    <Box>
                        <Button variant={"transparent"} isDisabled>
                            Button
                        </Button>
                    </Box>
                    <Button variant={"tab"} isDisabled>
                        Button
                    </Button>
                </Flex>
                <Flex
                    marginTop={8}
                    gap={2}
                    padding={6}
                    flexWrap={"wrap"}
                    flexGrow={1}
                >
                    <Badge size={"lg"} variant={"green"}>
                        Badge
                    </Badge>
                    <Badge size={"lg"} variant={"red"}>
                        Badge
                    </Badge>
                    <Badge size={"lg"} variant={"primary"}>
                        Badge
                    </Badge>
                    <Badge size={"lg"} variant={"secondary"}>
                        Badge
                    </Badge>
                </Flex>
                <Flex
                    marginTop={8}
                    gap={2}
                    padding={6}
                    flexWrap={"wrap"}
                    flexGrow={1}
                >
                    <Checkbox>Checkbox</Checkbox>
                    <Checkbox defaultChecked>Checkbox</Checkbox>
                </Flex>
                <Flex
                    marginTop={8}
                    gap={2}
                    padding={6}
                    flexWrap={"wrap"}
                    flexGrow={1}
                >
                    <Checkbox isDisabled>Checkbox</Checkbox>
                    <Checkbox isDisabled defaultChecked>
                        Checkbox
                    </Checkbox>
                </Flex>
                <Flex
                    marginTop={8}
                    gap={2}
                    padding={6}
                    flexWrap={"wrap"}
                    flexGrow={1}
                >
                    <Radio value="1">First</Radio>
                    <Radio value="2">Second</Radio>
                    <Radio value="3">Third</Radio>
                </Flex>
                <Flex
                    marginTop={8}
                    gap={2}
                    padding={6}
                    flexWrap={"wrap"}
                    flexGrow={1}
                >
                    <Radio value="1" isDisabled>
                        First
                    </Radio>
                    <Radio value="2" defaultChecked isDisabled>
                        Second
                    </Radio>
                    <Radio value="3" isDisabled>
                        Third
                    </Radio>
                </Flex>
                <Flex
                    marginTop={8}
                    gap={2}
                    padding={6}
                    flexWrap={"wrap"}
                    flexGrow={1}
                >
                    <FormControl display="flex" alignItems="center">
                        <FormLabel htmlFor="email-alerts" mb="0">
                            Enable email alerts?
                        </FormLabel>
                        <Switch id="email-alerts" />
                    </FormControl>
                    <FormControl display="flex" alignItems="center">
                        <FormLabel htmlFor="email-alerts" mb="0">
                            Enable email alerts?
                        </FormLabel>
                        <Switch id="email-alerts" isDisabled />
                    </FormControl>
                </Flex>
                <Flex
                    marginTop={8}
                    gap={2}
                    padding={6}
                    flexWrap={"wrap"}
                    flexGrow={1}
                >
                    <Select placeholder="Select option">
                        <option value="option1">Option 1</option>
                        <option value="option2">Option 2</option>
                        <option value="option3">Option 3</option>
                    </Select>
                    <Select placeholder="Select option" isDisabled>
                        <option value="option1">Option 1</option>
                        <option value="option2">Option 2</option>
                        <option value="option3">Option 3</option>
                    </Select>
                </Flex>
                <Flex
                    marginTop={8}
                    gap={2}
                    padding={6}
                    flexWrap={"wrap"}
                    flexGrow={1}
                >
                    <AppFormControl label="About">
                        <Textarea placeholder="Here is a sample placeholder" />
                    </AppFormControl>
                    <AppFormControl label="About" isDisabled>
                        <Textarea placeholder="Here is a sample placeholder" />
                    </AppFormControl>
                </Flex>

                <Flex
                    marginTop={8}
                    gap={8}
                    padding={6}
                    flexWrap={"wrap"}
                    flexGrow={1}
                >
                    <AppFormControl label="Firstname">
                        <Input placeholder="Enter firstname" />
                    </AppFormControl>
                    <AppFormControl label="Firstname" isDisabled>
                        <Input placeholder="Enter firstname" />
                    </AppFormControl>
                    <InputGroup size="md">
                        <InputLeftAddon>https://</InputLeftAddon>
                        <Input placeholder="mysite" />
                        <InputRightAddon>.com</InputRightAddon>
                    </InputGroup>
                    <InputGroup size="md">
                        <InputLeftAddon>https://</InputLeftAddon>
                        <Input placeholder="mysite" isDisabled />
                        <InputRightAddon>.com</InputRightAddon>
                    </InputGroup>
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <PhoneIcon />
                        </InputLeftElement>
                        <Input placeholder="Enter phone" />
                        <InputRightElement>
                            <CheckIcon />
                        </InputRightElement>
                    </InputGroup>
                    <NumberInput>
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                    <NumberInput isDisabled>
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Flex>

                <AppTab />

                <Tabs
                    ml={"100px"}
                    mt={"50px"}
                    position="relative"
                    variant="app"
                >
                    <TabList gap={"8px"}>
                        <Tab>
                            <AppIcon name={"icl-list"} w="1rem" />
                            <Text ml={"8px"} as={"span"}>
                                One
                            </Text>
                        </Tab>
                        <Tab>
                            <AppIcon name={"icl-clipboard"} w="1rem" />

                            <Text ml={"8px"} as={"span"}>
                                Two
                            </Text>
                        </Tab>
                        <Tab>
                            <AppIcon name={"icl-chart-table"} w="1rem" />
                            <Text ml={"8px"} as={"span"}>
                                Three
                            </Text>
                        </Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <p>one!</p>
                        </TabPanel>
                        <TabPanel>
                            <p>two!</p>
                        </TabPanel>
                        <TabPanel>
                            <p>three!</p>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
                <Flex
                    marginTop={8}
                    gap={2}
                    padding={6}
                    flexWrap={"wrap"}
                    flexGrow={1}
                ></Flex>
            </Flex>
        </>
    );
};

// active

// border-bottom : 2px solid #007ec3
// color: #007ec3
// font-weight: 800

//hover

// background-color : #FEF7EC
// font-weight: 800
// border-radius: 10px
