import { Canceler } from "axios";
import { EntityApi } from "../../services/ApiPlatform";
import { PrimitiveObject } from "../../services/ApiPlatform/types";
import { FinalResponse, ListResponse } from "../../services/ApiPlatform/models";

export abstract class ProjectApi extends EntityApi {
    protected static GET_COLLECTION = "/api/projects";

    protected static POST_COLLECTION = "/api/projects";

    protected static GET_ITEM = "/api/projects/{id}";

    protected static PATCH_ITEM = "/api/projects/{id}";

    protected static DELETE_ITEM = "/api/projects/{id}";

    protected static GET_COLLECTION_MY_PROJECTS = "/api/projects/me";

    public static async getCollectionMyProjects<R>(
        page = 1,
        extraParams: PrimitiveObject = {},
        cancelToken?: (c: Canceler) => void
    ): Promise<FinalResponse<ListResponse<R> | null>> {
        extraParams = {
            ...extraParams,
            PATH: this.GET_COLLECTION_MY_PROJECTS,
        };
        return this.getCollection(page, extraParams, cancelToken);
    }
}
