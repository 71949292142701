import { BaseEntity } from "./BaseEntity";
import { Company } from "./Company";
import { KanbanStatus } from "./KanbanStatus";
import { Project } from "./Project";
import { User } from "./User";

export class Task extends BaseEntity {
    public name: string = "";
    public description?: string = "";
    public priority?: number = 3;
    public startDate?: string = "";
    public dueDate?: string = "";
    public estimatedTime?: number = 0;
    public trackTime?: number = 0;
    public assignee?: string | User = "";
    public user?: string | User = "";
    public kanbanStatus?: string | KanbanStatus = "";
    public project: string | Project = "";
    public company?: string | Company = "";
    public ord?: number = 0;

    constructor(model: Partial<Task> = {}) {
        super(model?.id);
        Object.assign(this, model);
    }
}

export type PTask = Partial<Task>;
