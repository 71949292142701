import { FC } from "react";
import {
    Avatar,
    Badge,
    HStack,
    Text,
    UseDisclosureReturn,
} from "@chakra-ui/react";
import { IKaTableProps, Table as KaTable } from "ka-table";
import { ICellTextProps } from "ka-table/props";
import { AppAlertDelete } from "../../../components";
import { APPC } from "../../../config";
import { useTrans } from "../../../hooks";

export const UserList: FC<{
    kaTableProps: IKaTableProps;
    deleteHandler: () => Promise<void>;
    deleteAlertState: UseDisclosureReturn;
}> = ({ kaTableProps, deleteHandler, deleteAlertState }) => {
    const { t } = useTrans();
    return (
        <>
            <KaTable
                {...kaTableProps}
                childComponents={{
                    ...kaTableProps.childComponents,
                    cellText: {
                        content: (props: ICellTextProps) => {
                            switch (props?.column?.key) {
                                case "firstName":
                                    return (
                                        <HStack>
                                            <Avatar
                                                size={"sm"}
                                                name={props?.rowData?.fullName}
                                                // src={"https://bit.ly/dan-abramov"}
                                                color={
                                                    "var(--chakra-colors-primaryS10)"
                                                }
                                                bgColor={
                                                    "var(--chakra-colors-primaryT80)"
                                                }
                                            />
                                            <Text
                                                textStyle={"mediumSm"}
                                                color={"black"}
                                            >
                                                {props?.rowData?.fullName}
                                            </Text>
                                        </HStack>
                                    );
                                case "company.name":
                                    return (
                                        <a
                                            href={`${APPC.APP_PROTOCOL}://${props?.rowData?.company?.domain}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {props?.rowData?.company?.name}
                                        </a>
                                    );
                                case "userType":
                                    return (
                                        <Badge variant={"secondary"}>
                                            {t(
                                                `con.User:${props?.rowData?.userType}`
                                            )}
                                        </Badge>
                                    );
                            }
                            if (
                                kaTableProps?.childComponents?.cellText?.content
                            ) {
                                return kaTableProps?.childComponents?.cellText?.content(
                                    props
                                );
                            }
                        },
                    },
                }}
            />
            {deleteAlertState.isOpen && (
                <AppAlertDelete
                    isOpenDelete={deleteAlertState.isOpen}
                    deleteHandler={() => deleteHandler()}
                    onCloseDelete={deleteAlertState.onClose}
                />
            )}
        </>
    );
};
