import { Canceler } from "axios";
import { EntityApi } from "../../services/ApiPlatform";
import { FinalResponse, ListResponse } from "../../services/ApiPlatform/models";
import { PrimitiveObject } from "../../services/ApiPlatform/types";

export abstract class KanbanStatusApi extends EntityApi {
    protected static GET_COLLECTION = "/api/kanban-statuses";

    protected static POST_COLLECTION = "/api/kanban-statuses";

    protected static GET_ITEM = "/api/kanban-statuses/{id}";

    protected static PATCH_ITEM = "/api/kanban-statuses/{id}";

    protected static DELETE_ITEM = "/api/kanban-statuses/{id}";

    protected static GET_COLLECTION_FOR_BOARD = "/api/kanban-statuses/board";

    protected static PATCH_ITEM_CHANGE_ORDER =
        "/api/kanban-statuses/{id}/change-order";

    public static async getCollectioForBoard<R>(
        page = 1,
        extraParams: PrimitiveObject = {},
        cancelToken?: (c: Canceler) => void
    ): Promise<FinalResponse<ListResponse<R> | null>> {
        extraParams = {
            ...extraParams,
            PATH: this.GET_COLLECTION_FOR_BOARD,
        };
        return this.getCollection(page, extraParams, cancelToken);
    }

    public static async patchItemChangeOrder<R, P>(
        id: number | string,
        entity: P,
        extraParams: PrimitiveObject = {}
    ): Promise<FinalResponse<R | null>> {
        extraParams = { ...extraParams, PATH: this.PATCH_ITEM_CHANGE_ORDER };
        return this.patchItem(id, entity, extraParams);
    }
}
