import { FC } from "react";
import { useRecoilValue } from "recoil";
import { Badge, HStack, UseDisclosureReturn } from "@chakra-ui/react";
import { IKaTableProps, Table as KaTable } from "ka-table";
import { ICellTextProps } from "ka-table/props";
import {
    AppAlertDelete,
    AppOptionBoxItem,
    AppOptionsBox,
} from "../../../components";
import { Workspace } from "../../../apis";
import { useTrans } from "../../../hooks";
import { ActiveUserWorkspaceAtom } from "../../../atoms";

export const WorkspaceList: FC<{
    kaTableProps: IKaTableProps;
    actionOptions: AppOptionBoxItem[];
    actionClickHandler: (key: string, data?: Workspace) => void;
    deleteHandler: () => Promise<void>;
    deleteAlertState: UseDisclosureReturn;
}> = ({
    kaTableProps,
    actionOptions,
    actionClickHandler,
    deleteHandler,
    deleteAlertState,
}) => {
    const { t } = useTrans();
    const activeUserWorkspace = useRecoilValue(ActiveUserWorkspaceAtom);
    return (
        <>
            <KaTable
                {...kaTableProps}
                childComponents={{
                    ...kaTableProps.childComponents,
                    cellText: {
                        content: (props: ICellTextProps) => {
                            switch (props?.column?.key) {
                                case "isActive":
                                    return props?.rowData?.id ===
                                        activeUserWorkspace ? (
                                        <Badge variant={"green"}>
                                            {t(`cmn:label.active.true`)}
                                        </Badge>
                                    ) : (
                                        <></>
                                    );
                                case "action":
                                    return (
                                        <HStack justify={"flex-end"} gap={3}>
                                            <AppOptionsBox<Workspace>
                                                options={actionOptions}
                                                data={props?.rowData}
                                                onClick={actionClickHandler}
                                            />
                                        </HStack>
                                    );
                            }
                        },
                    },
                }}
            />
            {deleteAlertState.isOpen && (
                <AppAlertDelete
                    isOpenDelete={deleteAlertState.isOpen}
                    deleteHandler={() => deleteHandler()}
                    onCloseDelete={deleteAlertState.onClose}
                />
            )}
        </>
    );
};
