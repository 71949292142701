import { useNavItems } from "../hooks";
import { useAuthData } from "../contexts";
import { HStack } from "@chakra-ui/react";
import { AppUserNav } from "./AppUserNav";
import { AppStopWatch } from "../containers";
import { AppProjectSelector } from "./AppProjectSelector";

export const AppHeader = () => {
    const { user } = useAuthData();
    const userNavItems = useNavItems("user");

    return (
        <>
            <HStack
                minH={"4.5rem"}
                px={6}
                justifyContent={"space-between"}
                gap={8}
            >
                <AppProjectSelector />
                <HStack gap={8}>
                    <AppStopWatch />
                    {user && <AppUserNav user={user} navItems={userNavItems} />}
                </HStack>
            </HStack>
        </>
    );
};
