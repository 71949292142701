import { selectAnatomy } from "@chakra-ui/anatomy";
import {
    createMultiStyleConfigHelpers,
    defineStyle,
} from "@chakra-ui/styled-system";

const { defineMultiStyleConfig, definePartsStyle } =
    createMultiStyleConfigHelpers(selectAnatomy.keys);

const baseStyleField = defineStyle({
    fontFamily: "Inter Regular, sans-serif",
    border: "1px solid",
    fontWeight: 400,
});

const baseStyleIcon = defineStyle({});

const baseStyle = definePartsStyle({
    field: baseStyleField,
    icon: baseStyleIcon,
});

const sizes = {
    xs: definePartsStyle({
        field: {
            h: "1.7025rem",
            px: "0.495rem",
            py: "0.4125rem",
            borderRadius: "0.15rem",
            fontSize: "0.5775rem",
            lineHeight: "0.8665rem",
        },
        icon: {
            insetEnd: 0,
            fontSize: "0.80rem",
        },
    }),
    sm: definePartsStyle({
        field: {
            h: "2.1325rem",
            px: "0.622rem",
            py: "0.518rem",
            borderRadius: "0.20rem",
            fontSize: "0.7262rem",
            lineHeight: "1.0895rem",
        },
        icon: {
            insetEnd: "0.125rem",
            fontSize: "1rem",
        },
    }),
    md: definePartsStyle({
        field: {
            h: "2.5625rem",
            px: 3,
            py: 2.5,
            borderRadius: "0.25rem",
            fontSize: "0.875rem",
            lineHeight: "1.3125rem",
        },
        icon: {
            insetEnd: 1,
            fontSize: "1.25rem",
        },
    }),
    lg: definePartsStyle({
        field: {
            h: 12,
            px: 3.5,
            py: "0.73rem",
            borderRadius: "0.30rem",
            fontSize: "1.02rem",
            lineHeight: "1.5356rem",
        },
        icon: {
            insetEnd: 1.5,
            fontSize: "1.5rem",
        },
    }),
};

const primary = definePartsStyle({
    field: {
        bg: "primarySelectFieldBg",
        borderColor: "primarySelectFieldBorder",
        color: "primarySelectFieldText",

        _disabled: {
            opacity: 1,
            bg: "primarySelectFieldDisabledBg",
            borderColor: "primarySelectFieldDisabledBorder",
            color: "primarySelectFieldDisabledText",
            _hover: {
                opacity: 1,
                bg: "primarySelectFieldDisabledHoverBg",
                borderColor: "primarySelectFieldDisabledHoverBorder",
                color: "primarySelectFieldDisabledHoverText",
            },
        },
        _placeholder: {
            color: "primarySelectFieldPlaceholder",
        },
        _hover: {
            borderColor: "primarySelectFieldHoverBorder",
        },
        _focusVisible: {
            borderColor: "primarySelectFieldFocusBorder",
            boxShadow: "var(--chakra-colors-boxShadowFormEle)",
        },
    },
    icon: {
        color: "primarySelectIcon",
    },
});

const variants = {
    primary,
};

export const Select = defineMultiStyleConfig({
    baseStyle,
    variants,
    sizes,
    defaultProps: {
        size: "md",
        variant: "primary",
    },
});
