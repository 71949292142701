import {
    Box,
    Button,
    Flex,
    Hide,
    HStack,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    VStack,
} from "@chakra-ui/react";
import {
    AppIcon,
    AppKanbanStatusCard,
    AppPageHeader,
} from "../../../components";
import { useTrans } from "../../../hooks";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
    PKanbanStatus,
    KanbanStatusApi,
    ProjectApi,
    KanbanStatus,
} from "../../../apis";
import { APPC } from "../../../config";
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult,
} from "react-beautiful-dnd";

export const KanbanStatusPage = () => {
    // hooks
    const { t } = useTrans();
    const { id } = useParams();

    // state & const
    const [kanbanStatuses, setKanbanStatuses] = useState<KanbanStatus[]>([]);

    const fetchKanbanStatus = () => {
        KanbanStatusApi.getCollection<KanbanStatus>(1, {
            "project.id": id || 0,
            ["order[ord]"]: "asc",
        }).then(({ response }) => {
            if (response !== null) {
                setKanbanStatuses(response?.items);
            }
        });
    };

    const addKanbanStatus = (type: string) => {
        KanbanStatusApi.postCollection<KanbanStatus, KanbanStatus>(
            new KanbanStatus({
                name: type,
                type,
                project: ProjectApi.toResourceIRI(id),
            })
        ).then(({ response }) => {
            if (response !== null) {
                setKanbanStatuses([...kanbanStatuses, response]);
            }
        });
    };

    const removeKanbanStatus = (id: number) => {
        if (id > 0) {
            KanbanStatusApi.deleteItem(id).then(() => {
                fetchKanbanStatus();
            });
        }
    };

    const updateKanbanStatus = (id: number, name: string) => {
        let selIndex = -1;
        kanbanStatuses.forEach((e, index) => {
            if (e.id === id) {
                selIndex = index;
            }
        });
        if (selIndex > -1) {
            KanbanStatusApi.patchItem<KanbanStatus, PKanbanStatus>(id, {
                name,
            }).then(({ response }) => {
                if (response !== null) {
                    const data = [...kanbanStatuses];
                    data.splice(selIndex, 1);
                    data.splice(selIndex, 0, response);
                    setKanbanStatuses(data);
                }
            });
        }
    };

    const onDragEnd = (result: DropResult) => {
        const { source, destination } = result;
        if (!destination || source.index === destination.index) {
            return;
        }

        const data = [...kanbanStatuses];
        const [el] = data.splice(source.index, 1);
        data.splice(destination.index, 0, el);
        if (el?.id) {
            setKanbanStatuses(data);
            KanbanStatusApi.patchItemChangeOrder<
                KanbanStatus,
                { newOrd: number }
            >(el.id, { newOrd: destination.index + 1 }).then();
        }
    };

    useEffect(() => {
        fetchKanbanStatus();
    }, []);

    return (
        <>
            <AppPageHeader title={t("padm.KanbanStatusPage:text.pageTitle")}>
                <HStack gap={4} py={{ base: 3.5, sm: 4, md: "1.125rem" }}>
                    <Menu placement="bottom-end">
                        <MenuButton
                            as={Button}
                            variant={"primary"}
                            rightIcon={
                                <AppIcon
                                    name="ics-chevron-bottom-alt"
                                    w="1rem"
                                />
                            }
                        >
                            <Box display={"flex"} alignItems={"center"}>
                                <AppIcon name="ics-plus-circle" w="1rem" />
                                <Hide below="md">
                                    <Text ml={2}>
                                        {t(
                                            "padm.KanbanStatusPage:button.create"
                                        )}
                                    </Text>
                                </Hide>
                            </Box>
                        </MenuButton>
                        <MenuList>
                            {Object.values(APPC.BE.KanbanStatus).map((s) => (
                                <MenuItem
                                    key={s}
                                    onClick={() => addKanbanStatus(s)}
                                >
                                    {t(`con.KanbanStatus:TYPE_${s}`)}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                </HStack>
            </AppPageHeader>
            <VStack p={6} alignItems={"flex-start"}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable
                        droppableId={"KanbanStatus"}
                        direction={"horizontal"}
                    >
                        {(provided) => (
                            <Flex
                                gap={2}
                                py={4}
                                minW={"100%"}
                                // direction={"column"}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {kanbanStatuses?.map((status, index) => {
                                    return (
                                        <Draggable
                                            draggableId={`status-${status.id}`}
                                            key={status.id}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <AppKanbanStatusCard
                                                        kanbanStatus={status}
                                                        onRemove={
                                                            removeKanbanStatus
                                                        }
                                                        onUpdate={
                                                            updateKanbanStatus
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </Flex>
                        )}
                    </Droppable>
                </DragDropContext>
            </VStack>
        </>
    );
};
