import { FC } from "react";
import { HStack, Text, Flex, Box } from "@chakra-ui/react";
import { KanbanStatus } from "../apis";
import { DroppableStateSnapshot } from "react-beautiful-dnd";
import { AppTooltip } from "./AppTooltip";

type AppTaskColumnProps = {
    kanbanStatus: KanbanStatus;
    snapshot?: DroppableStateSnapshot;
    children?: JSX.Element;
};

export const AppTaskColumn: FC<AppTaskColumnProps> = ({
    kanbanStatus,
    // snapshot,
    children,
}) => {
    console.log("Render:AppTaskColumn" + kanbanStatus.id);
    return (
        <>
            <Flex
                w={"full"}
                minH={"75vh"}
                direction={"column"}
                p={"1.25rem"}
                gap={"1.25rem"}
                border={"1px solid var(--chakra-colors-primaryT80)"}
                borderTopWidth={"5px"}
                borderRadius={"8px"}
                bg={"var(--chakra-colors-white)"}
                justifyContent={"flex-start"}
            >
                <HStack
                    gap={"1rem"}
                    flexWrap={"nowrap"}
                    justifyContent={"space-between"}
                >
                    <AppTooltip label={kanbanStatus.name}>
                        <Text
                            noOfLines={1}
                            textStyle="semiBoldMd"
                            verticalAlign={"bottom"}
                            textOverflow={"ellipsis"}
                        >
                            {kanbanStatus.name}
                        </Text>
                    </AppTooltip>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="4"
                        bg={"var(--chakra-colors-primaryT50)"}
                        color={"var(--chakra-colors-white)"}
                        textAlign="center"
                        p={"5px 10px"}
                    >
                        <Text textStyle="regularXs">
                            {kanbanStatus?.tasks?.length}
                        </Text>
                    </Box>
                </HStack>
                {children}
                {/*
                <Button variant={"secondary"} size={"lg"}>
                    <AppIcon name="icl-plus" w="1.25rem" />
                    <Text marginLeft={2}>
                        {t("com.AppTaskColumn:button.create")}
                    </Text>
                </Button>
                */}
            </Flex>
        </>
    );
};
