import { Center, Spinner } from "@chakra-ui/react";

export const AppLoader = () => {
    return (
        <Center
            position="fixed"
            top="0"
            left="0"
            width="100vw"
            height="100vh"
            bg="var(--chakra-colors-transparent)"
            zIndex="1500"
        >
            <Spinner
                size="xl"
                thickness="4px"
                speed="0.65s"
                color="var(--chakra-colors-primary)"
                emptyColor="var(--chakra-colors-greyT90)"
            />
        </Center>
    );
};
