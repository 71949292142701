import { EntityApi } from "../../services/ApiPlatform";
import { FinalResponse } from "../../services/ApiPlatform/models";
import { PrimitiveObject } from "../../services/ApiPlatform/types";

export abstract class TaskApi extends EntityApi {
    protected static GET_COLLECTION = "/api/tasks";

    protected static POST_COLLECTION = "/api/tasks";

    protected static GET_ITEM = "/api/tasks/{id}";

    protected static PATCH_ITEM = "/api/tasks/{id}";

    protected static DELETE_ITEM = "/api/tasks/{id}";

    protected static PATCH_ITEM_CHANGE_ORDER = "/api/tasks/{id}/change-order";

    public static async patchItemChangeOrder<R, P>(
        id: number | string,
        entity: P,
        extraParams: PrimitiveObject = {}
    ): Promise<FinalResponse<R | null>> {
        extraParams = { ...extraParams, PATH: this.PATCH_ITEM_CHANGE_ORDER };
        return this.patchItem(id, entity, extraParams);
    }
}
