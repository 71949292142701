import { Canceler } from "axios";
import { EntityApi } from "../../services/ApiPlatform";
import { FinalResponse, ListResponse } from "../../services/ApiPlatform/models";

export abstract class TimesheetApi extends EntityApi {
    protected static GET_COLLECTION = "/api/timesheets";

    protected static POST_COLLECTION = "/api/timesheets";

    protected static GET_ITEM = "/api/timesheets/{id}";

    protected static PATCH_ITEM = "/api/timesheets/{id}";

    protected static DELETE_ITEM = "/api/timesheets/{id}";

    protected static GET_COLLECTION_MY = "/api/timesheets/my";

    protected static GET_COLLECTION_RUNNING = "/api/timesheets/running";

    protected static POST_START = "/api/timesheets/start";

    protected static POST_START_MANUAL = "/api/timesheets/start-manual";

    protected static POST_END = "/api/timesheets/end";

    public static async getCollectionMy<R>(
        page = 1,
        extraParams = {},
        cancelToken?: (c: Canceler) => void
    ): Promise<FinalResponse<ListResponse<R> | null>> {
        extraParams = { ...extraParams, PATH: this.GET_COLLECTION_MY };
        return this.getCollection(page, extraParams, cancelToken);
    }

    public static async getCollectionRunning<R>(
        page = 1,
        extraParams = {},
        cancelToken?: (c: Canceler) => void
    ): Promise<FinalResponse<ListResponse<R> | null>> {
        extraParams = { ...extraParams, PATH: this.GET_COLLECTION_RUNNING };
        return this.getCollection(page, extraParams, cancelToken);
    }

    public static async postStart<R, P>(
        entity: P,
        extraParams = {}
    ): Promise<FinalResponse<R | null>> {
        extraParams = { ...extraParams, PATH: this.POST_START };
        return this.postCollection(entity, extraParams);
    }

    public static async postStartManual<R, P>(
        entity: P,
        extraParams = {}
    ): Promise<FinalResponse<R | null>> {
        extraParams = { ...extraParams, PATH: this.POST_START_MANUAL };
        return this.postCollection(entity, extraParams);
    }

    public static async postEnd<R, P>(
        entity: P,
        extraParams = {}
    ): Promise<FinalResponse<R | null>> {
        extraParams = { ...extraParams, PATH: this.POST_END };
        return this.postCollection(entity, extraParams);
    }
}
