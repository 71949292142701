import { useState } from "react";
import { useAuthAction, useFormContainerState, useTrans } from "../../../hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema";
import { schemaChangeEmail } from "./schemaChangeEmail";
import { useAuthData } from "../../../contexts";
import {
    AppAlert,
    AppFormControl,
    AppIcon,
    AppPageHeader,
} from "../../../components";
import {
    User as FormEntity,
    UserApi as FormEntityApi,
    UserApi,
} from "../../../apis";
import {
    Avatar,
    Box,
    Button,
    Divider,
    HStack,
    Input,
    Select,
    Text,
    VStack,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import { LSC } from "../../../config";
import {
    ChangeEmailPayload,
    ChangeEmailResponse,
    ForgotPasswordPayload,
    ForgotPasswordResponse,
} from "../../../apis/entities/dtos";
import { AppFormContainer } from "../../../containers";

export const ProfilePage = () => {
    const { t } = useTrans();
    const toast = useToast();
    const { user } = useAuthData();
    const { reloadUserAction, logoutAction } = useAuthAction();
    const formContainerState = useFormContainerState();
    const {
        isOpen: isOpenChangePassword,
        onOpen: onOpenChangePassword,
        onClose: onCloseChangePassword,
    } = useDisclosure();

    // state & const
    const [loading, setLoading] = useState(false);
    const [changeEmailData] = useState<ChangeEmailPayload>({ newEmail: "" });
    const [locale, setLocale] = useState(
        localStorage.getItem(LSC.APP_USER_LOCALE) || "en"
    );
    const localeOptions = [
        { value: "en", label: "English" },
        { value: "hi", label: "Hindi" },
        { value: "gu", label: "Gujarati" },
    ];

    // form
    const {
        handleSubmit,
        register,
        formState: { errors },
        reset,
    } = useForm<FormEntity>({
        resolver: yupResolver(schema(t)),
        mode: "onSubmit",
    });

    const onSubmitHandler = (formData: FormEntity) => {
        if (user) {
            setLoading(true);
            FormEntityApi.patchItemProfile<FormEntity, FormEntity>(
                user.id,
                formData
            )
                .then(({ response, errorMessage }) => {
                    if (errorMessage) {
                        toast({
                            title: errorMessage,
                            status: "error",
                        });
                    } else if (response !== null) {
                        reloadUserAction();
                        toast({
                            title: t("cmn:message.save.success"),
                            status: "success",
                        });
                        reset();
                        if (
                            locale !== localStorage.getItem(LSC.APP_USER_LOCALE)
                        ) {
                            localStorage.setItem(LSC.APP_USER_LOCALE, locale);
                        }
                        document.location.reload();
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    const {
        handleSubmit: changeEmailHandleSubmit,
        register: changeEmailRegister,
        formState: { errors: changeEmailErrors },
        reset: changeEmailReset,
    } = useForm<ChangeEmailPayload>({
        resolver: yupResolver(schemaChangeEmail(t)),
        mode: "onBlur",
    });

    const onSubmitChangeEmailHandler = (formData: ChangeEmailPayload) => {
        if (user) {
            setLoading(true);
            FormEntityApi.patchItemChangeEmail<
                ChangeEmailResponse,
                ChangeEmailPayload
            >(user.id, formData)
                .then(({ response, errorMessage }) => {
                    if (errorMessage) {
                        toast({
                            title: errorMessage,
                            status: "error",
                        });
                    } else if (response !== null) {
                        toast({
                            title: t(
                                "padm.ProfilePage:toast.success.changeEmail"
                            ),
                            status: "success",
                        });
                        changeEmailReset();
                        formContainerState.close();
                        logoutAction();
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    const changePasswordHandler = async () => {
        setLoading(true);
        UserApi.postUserChangePasswordRequest<
            ForgotPasswordResponse,
            ForgotPasswordPayload
        >({ email: `${user?.email}` })
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    toast({
                        title: t(
                            "padm.ProfilePage:toast.success.passwordReset"
                        ),
                        status: "success",
                    });
                    reset();
                    logoutAction();
                }
            })
            .finally(() => setLoading(false));
    };

    const renderUserForm = () => {
        return (
            <form
                id="app-form-profile"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(onSubmitHandler)();
                }}
            >
                <VStack gap={6} maxW={"600px"}>
                    <Box position="relative" w="100%">
                        <Text textStyle={"heading5"}>Personal Information</Text>
                        <Divider />
                    </Box>
                    <Avatar
                        size="2xl"
                        name={user?.fullName}
                        color={"var(--chakra-colors-primaryS10)"}
                        bgColor={"var(--chakra-colors-primaryT80)"}
                    />
                    <Button variant={"transparent"} w={"auto"}>
                        Change Avatar
                    </Button>
                    <AppFormControl label={t("ent.User:email.label")}>
                        <Input
                            type="email"
                            placeholder={t("ent.User:email.placeholder")}
                            isDisabled={true}
                            defaultValue={user?.email}
                        />
                    </AppFormControl>
                    <HStack w="100%">
                        <Button
                            w="50%"
                            variant={"destructive"}
                            onClick={() => formContainerState.open(0)}
                        >
                            <AppIcon name="icl-mail" w="1rem" />
                            <Text ml="2">Change Email</Text>
                        </Button>
                        <Button
                            w="50%"
                            variant={"destructive"}
                            onClick={onOpenChangePassword}
                        >
                            <AppIcon name="icl-key-alt" />
                            <Text ml="2">Change Password</Text>
                        </Button>
                    </HStack>
                    <AppFormControl
                        label={t("ent.User:firstName.label")}
                        isInvalid={!!errors?.firstName}
                        message={errors?.firstName?.message}
                        isLoading={loading}
                    >
                        <Input
                            type="text"
                            placeholder={t("ent.User:firstName.placeholder")}
                            {...register("firstName")}
                            defaultValue={user?.firstName}
                        />
                    </AppFormControl>
                    <AppFormControl
                        label={t("ent.User:lastName.label")}
                        isInvalid={!!errors?.lastName}
                        message={errors?.lastName?.message}
                        isLoading={loading}
                    >
                        <Input
                            type="text"
                            placeholder={t("ent.User:lastName.placeholder")}
                            {...register("lastName")}
                            defaultValue={user?.lastName}
                        />
                    </AppFormControl>
                    <Box position="relative" w="100%">
                        <Text textStyle={"heading5"}>Preferences</Text>
                        <Divider />
                    </Box>
                    <AppFormControl label={"Language"}>
                        <Select onChange={(e) => setLocale(e.target.value)}>
                            {localeOptions.map((option) => (
                                <option
                                    key={option.value}
                                    value={option.value}
                                    selected={option.value === locale}
                                >
                                    {option.label}
                                </option>
                            ))}
                        </Select>
                    </AppFormControl>
                    <AppFormControl label={"Timezone"}>
                        <Select>
                            <option value="GMT+5:30" selected>
                                GMT+5:30 Asia/Kolkata
                            </option>
                        </Select>
                    </AppFormControl>
                    <AppFormControl label={"Date Format"}>
                        <Select>
                            <option value="DD/MM/YYYY" selected>
                                DD/MM/YYYY
                            </option>
                        </Select>
                    </AppFormControl>
                    <AppFormControl label={"Time Format"}>
                        <Select>
                            <option value="12 Hours" selected>
                                12 Hours
                            </option>
                            <option value="24 Hours">24 Hours</option>
                        </Select>
                    </AppFormControl>
                    <Box alignSelf={"end"}>
                        <Button type="submit" form="app-form-profile">
                            {t("cmn:button.save")}
                        </Button>
                    </Box>
                </VStack>
            </form>
        );
    };

    const renderChangeEmailModal = () => {
        return (
            <AppFormContainer
                title={t("padm.ProfilePage:modal.changeEmail.title")}
                state={formContainerState}
                type={"modal"}
                size={"lg"}
            >
                <form
                    id="app-form"
                    onSubmit={(e) => {
                        e.preventDefault();
                        changeEmailHandleSubmit(onSubmitChangeEmailHandler)();
                    }}
                >
                    <VStack gap={5} py={6}>
                        <AppIcon
                            name="icl-mail"
                            w="2.5rem"
                            color={"var(--chakra-colors-destructive)"}
                        />
                        <AppFormControl label={t("ent.User:email.label")}>
                            <>{`${user?.email}`}</>
                        </AppFormControl>
                        <AppFormControl
                            label={t("ent.User:newEmail.label")}
                            isInvalid={!!changeEmailErrors?.newEmail}
                            message={changeEmailErrors?.newEmail?.message}
                            isLoading={loading}
                        >
                            <Input
                                type="text"
                                placeholder={t("ent.User:newEmail.placeholder")}
                                {...changeEmailRegister("newEmail")}
                                defaultValue={changeEmailData.newEmail}
                            />
                        </AppFormControl>
                        <Text textStyle={"regularSm"}>
                            {t("padm.ProfilePage:alert.changeEmail.detail")}
                        </Text>
                    </VStack>
                </form>
            </AppFormContainer>
        );
    };

    const renderChangePasswordAlert = () => {
        return (
            <AppAlert
                title={t("padm.ProfilePage:alert.changePassword.title")}
                isOpen={isOpenChangePassword}
                onSuccess={changePasswordHandler}
                onCancel={onCloseChangePassword}
                successLable={t(
                    "padm.ProfilePage:alert.changePassword.button.success"
                )}
                cancelLable={t(
                    "padm.ProfilePage:alert.changePassword.button.cancel"
                )}
                size={"lg"}
            >
                <VStack py={6}>
                    <AppIcon
                        name="icl-key-alt"
                        w="2.5rem"
                        color={"var(--chakra-colors-destructive)"}
                    />
                    <Text textStyle={"boldMd"}>
                        {t("padm.ProfilePage:alert.changePassword.message")}
                    </Text>
                    <Text textStyle={"regularSm"}>
                        {t("padm.ProfilePage:alert.changePassword.detail")}
                    </Text>
                </VStack>
            </AppAlert>
        );
    };

    return (
        <>
            <AppPageHeader
                title={t("padm.ProfilePage:text.profile")}
            ></AppPageHeader>
            <Box p={6}>
                {renderUserForm()}
                {renderChangeEmailModal()}
                {isOpenChangePassword && renderChangePasswordAlert()}
            </Box>
        </>
    );
};
