import { popoverAnatomy as parts } from "@chakra-ui/anatomy";
import {
    createMultiStyleConfigHelpers,
    defineStyle,
} from "@chakra-ui/styled-system";

const { defineMultiStyleConfig, definePartsStyle } =
    createMultiStyleConfigHelpers(parts.keys);

const baseStyleContent = defineStyle({
    bg: "var(--chakra-colors-popoverContentBg)",
    border: "1px solid var(--chakra-colors-popoverContentBorder)",
    borderRadius: "0.375rem",
});

const baseStyleCloseButton = defineStyle({
    color: "var(--chakra-colors-popoverCloseButtonText)",
    _hover: {
        bg: "var(--chakra-colors-popoverCloseButtonHoverBg)",
    },
});

const baseStyle = definePartsStyle({
    content: baseStyleContent,
    closeButton: baseStyleCloseButton,
});

export const Popover = defineMultiStyleConfig({
    baseStyle,
});
