import { Box, Button, Flex, HStack, Text } from "@chakra-ui/react";
import React, { useRef, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { atom, useRecoilState } from "recoil";
import { APPC } from "../config";

interface Status {
    key: string;
    name: string;
    tasks: Task[];
}

interface Task {
    id: number;
    name: string;
    status: string;
}

const statuses: Status[] = [
    {
        key: "TODO",
        name: "To Do",
        tasks: [] as Task[],
    },
    {
        key: "PROGRESS",
        name: "Progress",
        tasks: [] as Task[],
    },
    {
        key: "COMPLETED",
        name: "Completed",
        tasks: [] as Task[],
    },
    {
        key: "TESTING",
        name: "Testing",
        tasks: [] as Task[],
    },
];

const tasks: Task[] = [
    {
        id: 1,
        name: "Design Landing Page UI",
        status: "TODO",
    },
    {
        id: 2,
        name: "Implement User Authentication",
        status: "TODO",
    },
    {
        id: 3,
        name: "Create Product Listing Component",
        status: "TODO",
    },
    {
        id: 4,
        name: "Set Up Database Schema",
        status: "PROGRESS",
    },
    {
        id: 5,
        name: "Develop Notification System",
        status: "PROGRESS",
    },
    {
        id: 6,
        name: "Optimize Website Performance",
        status: "COMPLETED",
    },
    {
        id: 7,
        name: "Write Unit Tests for API",
        status: "COMPLETED",
    },
    {
        id: 8,
        name: "Integrate Payment Gateway",
        status: "TESTING",
    },
    {
        id: 9,
        name: "Fix Bugs in Login Module",
        status: "TESTING",
    },
    {
        id: 10,
        name: "Develop Admin Dashboard",
        status: "TESTING",
    },
];

const todoState = atom<Status>({
    key: "todoState",
    default: statuses[0],
});

const progressState = atom<Status>({
    key: "progressState",
    default: statuses[1],
});

const completedState = atom<Status>({
    key: "completedState",
    default: statuses[2],
});

const testingState = atom<Status>({
    key: "testingState",
    default: statuses[3],
});

export const AppKanban = () => {
    const itemRef = useRef<number>(51);
    const [todoColumn, setTodoColumn] = useRecoilState(todoState);
    const [progressColumn, setProgressColumn] = useRecoilState(progressState);
    const [completedColumn, setCompletedColumn] =
        useRecoilState(completedState);
    const [testingColumn, setTestingColumn] = useRecoilState(testingState);

    const addTask = () => {
        const newTask = {
            id: itemRef.current,
            name: `Task ${itemRef.current}`,
            status: APPC.COLUMN_KEYS.TODO,
        };
        setTodoColumn((prev) => ({
            ...prev,
            tasks: [...prev.tasks, newTask],
        }));
        itemRef.current++;
    };

    const moveTask = () => {
        if (todoColumn.tasks.length === 0) return;

        const [taskToMove, ...remainingTasks] = todoColumn.tasks;

        setTodoColumn((prev) => ({
            ...prev,
            tasks: remainingTasks,
        }));

        setProgressColumn((prev) => ({
            ...prev,
            tasks: [
                ...prev.tasks,
                { ...taskToMove, status: APPC.COLUMN_KEYS.PROGRESS },
            ],
        }));
    };

    const filterTasks = () => {
        const updatedTodoTasks = tasks.filter(
            (task) => task.status === APPC.COLUMN_KEYS.TODO
        );
        const updatedProgressTasks = tasks.filter(
            (task) => task.status === APPC.COLUMN_KEYS.PROGRESS
        );
        const updatedCompletedTasks = tasks.filter(
            (task) => task.status === APPC.COLUMN_KEYS.COMPLETED
        );
        const updatedTestingTasks = tasks.filter(
            (task) => task.status === APPC.COLUMN_KEYS.TESTING
        );

        setTodoColumn({ ...todoColumn, tasks: updatedTodoTasks });
        setProgressColumn({ ...progressColumn, tasks: updatedProgressTasks });
        setCompletedColumn({
            ...completedColumn,
            tasks: updatedCompletedTasks,
        });
        setTestingColumn({ ...testingColumn, tasks: updatedTestingTasks });
    };

    useEffect(() => {
        filterTasks();
    }, []);

    const getColumn = (dropId: string) => {
        switch (dropId) {
            case APPC.COLUMN_KEYS.TODO:
                return todoColumn;
            case APPC.COLUMN_KEYS.PROGRESS:
                return progressColumn;
            case APPC.COLUMN_KEYS.COMPLETED:
                return completedColumn;
            case APPC.COLUMN_KEYS.TESTING:
                return testingColumn;
            default:
                return null;
        }
    };

    const getItems = (columnId: string): Task[] => {
        switch (columnId) {
            case APPC.COLUMN_KEYS.TODO:
                return Array.from(todoColumn?.tasks || []);
            case APPC.COLUMN_KEYS.PROGRESS:
                return Array.from(progressColumn?.tasks || []);
            case APPC.COLUMN_KEYS.COMPLETED:
                return Array.from(completedColumn?.tasks || []);
            case APPC.COLUMN_KEYS.TESTING:
                return Array.from(testingColumn?.tasks || []);
            default:
                return [];
        }
    };

    const handleOnDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        ) {
            return;
        }
        const sourceColumn = getColumn(source.droppableId);
        const sourceItems = getItems(sourceColumn?.key || "");

        if (!sourceColumn) {
            return;
        }
        if (source.droppableId === destination.droppableId) {
            const [movedItem] = sourceItems.splice(source.index, 1);
            sourceItems.splice(destination.index, 0, movedItem);

            const updatedColumn = {
                ...sourceColumn,
                tasks: sourceItems,
            };

            switch (source.droppableId) {
                case APPC.COLUMN_KEYS.TODO:
                    setTodoColumn(updatedColumn);
                    break;
                case APPC.COLUMN_KEYS.PROGRESS:
                    setProgressColumn(updatedColumn);
                    break;
                case APPC.COLUMN_KEYS.COMPLETED:
                    setCompletedColumn(updatedColumn);
                    break;
                case APPC.COLUMN_KEYS.TESTING:
                    setTestingColumn(updatedColumn);
                    break;
                default:
                    break;
            }
            return;
        }
        const destColumn = getColumn(destination.droppableId);
        const destItems = getItems(destColumn?.key || "");

        if (!destColumn) {
            return;
        }

        const [movedItem] = sourceItems.splice(source.index, 1);
        destItems.splice(destination.index, 0, {
            ...movedItem,
            status: destination.droppableId,
        });

        const updatedSourceColumn = {
            ...sourceColumn,
            tasks: sourceItems,
        };

        const updatedDestColumn = {
            ...destColumn,
            tasks: destItems,
        };

        switch (source.droppableId) {
            case APPC.COLUMN_KEYS.TODO:
                setTodoColumn(updatedSourceColumn);
                break;
            case APPC.COLUMN_KEYS.PROGRESS:
                setProgressColumn(updatedSourceColumn);
                break;
            case APPC.COLUMN_KEYS.COMPLETED:
                setCompletedColumn(updatedSourceColumn);
                break;
            case APPC.COLUMN_KEYS.TESTING:
                setTestingColumn(updatedSourceColumn);
                break;
            default:
                break;
        }

        switch (destination.droppableId) {
            case APPC.COLUMN_KEYS.TODO:
                setTodoColumn(updatedDestColumn);
                break;
            case APPC.COLUMN_KEYS.PROGRESS:
                setProgressColumn(updatedDestColumn);
                break;
            case APPC.COLUMN_KEYS.COMPLETED:
                setCompletedColumn(updatedDestColumn);
                break;
            case APPC.COLUMN_KEYS.TESTING:
                setTestingColumn(updatedDestColumn);
                break;
            default:
                break;
        }
    };

    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Flex direction={"column"} gap={8}>
                <HStack>
                    <Button onClick={addTask}>Add Task</Button>
                    <Button onClick={moveTask}>Move Task</Button>
                </HStack>
                <Flex gap={6}>
                    <AppDroppableColumn column={todoColumn} />
                    <AppDroppableColumn column={progressColumn} />
                    <AppDroppableColumn column={completedColumn} />
                    <AppDroppableColumn column={testingColumn} />
                </Flex>
            </Flex>
        </DragDropContext>
    );
};

const AppDroppableColumn = React.memo(({ column }: { column: Status }) => {
    console.log(`${column.key} column render`);
    return (
        <Droppable droppableId={column.key}>
            {(provided) => (
                <Flex
                    w={"300px"}
                    direction={"column"}
                    p={"1.25rem"}
                    gap={"1.25rem"}
                    border={"1px solid #E4E4E4"}
                    borderRadius={"8px"}
                    bg={"white"}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    <Box>{column.name}</Box>
                    {column?.tasks.map((task, index) => (
                        <AppDragItem key={task.id} task={task} index={index} />
                    ))}
                    {provided.placeholder}
                </Flex>
            )}
        </Droppable>
    );
});
AppDroppableColumn.displayName = "AppDroppableColumn";

const AppDragItem = React.memo(
    ({ task, index }: { task: Task; index: number }) => {
        console.log(`${task.id} card render`);
        return (
            <Draggable draggableId={`${task.id}`} index={index}>
                {(provided) => (
                    <Flex
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        direction={"column"}
                        p={"1.125rem"}
                        gap={"1rem"}
                        border={"1px solid #E4E4E4"}
                        borderRadius={"4px"}
                        bg={"white"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        shadow={"md"}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            width="30px"
                            height="30px"
                            borderRadius="30px"
                            bg="#727272"
                            color="white"
                            textAlign="center"
                            padding={"20px"}
                        >
                            <Text textStyle="mediumMd">{task.id}</Text>
                        </Box>
                        <Text>{task.name}</Text>
                    </Flex>
                )}
            </Draggable>
        );
    }
);
AppDragItem.displayName = "AppDragItem";
