import { numberInputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(numberInputAnatomy.keys);

const baseStyle = definePartsStyle({
    field: {
        fontFamily: "Inter Regular, sans-serif",
        border: "1px solid",
        fontWeight: 400,
    },
});

const sizes = {
    xs: definePartsStyle({
        field: {
            h: "1.7025rem",
            px: "0.495rem",
            py: "0.4125rem",
            borderRadius: "0.15rem",
            fontSize: "0.5775rem",
            lineHeight: "0.8665rem",
        },
    }),
    sm: definePartsStyle({
        field: {
            h: "2.1325rem",
            px: "0.622rem",
            py: "0.518rem",
            borderRadius: "0.20rem",
            fontSize: "0.7262rem",
            lineHeight: "1.0895rem",
        },
    }),
    md: definePartsStyle({
        field: {
            h: "2.5625rem",
            px: 3,
            py: 2.5,
            borderRadius: "0.25rem",
            fontSize: "0.875rem",
            lineHeight: "1.3125rem",
        },
    }),
    lg: definePartsStyle({
        field: {
            h: 12,
            px: "0.87rem",
            py: "0.73rem",
            borderRadius: "0.30rem",
            fontSize: "1.02rem",
            lineHeight: "1.5356rem",
        },
    }),
};

const primary = definePartsStyle({
    field: {
        bg: "primaryNumberInputFieldBg",
        borderColor: "primaryNumberInputFieldBorder",
        color: "primaryNumberInputFieldText",

        _disabled: {
            opacity: 1,
            bg: "primaryNumberInputFieldDisabledBg",
            borderColor: "primaryNumberInputFieldDisabledBorder",
            color: "primaryNumberInputFieldDisabledText",
            _hover: {
                opacity: 1,
                bg: "primaryNumberInputFieldDisabledHoverBg",
                borderColor: "primaryNumberInputFieldDisabledHoverBorder",
                color: "primaryNumberInputFieldDisabledHoverText",
            },
        },
        _placeholder: {
            color: "primaryNumberInputFieldPlaceholder",
        },
        _hover: {
            borderColor: "primaryNumberInputFieldHoverBorder",
        },
        _invalid: {
            borderColor: "primaryNumberInputFieldInvalidBorder",
            boxShadow: "var(--chakra-colors-boxShadowFormEleInvalid)",
        },
        _focusVisible: {
            borderColor: "primaryNumberInputFieldFocusBorder",
            boxShadow: "var(--chakra-colors-boxShadowFormEle)",
        },
    },
});

const variants = {
    primary,
};

export const NumberInput = defineMultiStyleConfig({
    baseStyle,
    variants,
    sizes,
    defaultProps: {
        size: "md",
        variant: "primary",
    },
});
