import { menuAnatomy as parts } from "@chakra-ui/anatomy";
import {
    createMultiStyleConfigHelpers,
    defineStyle,
} from "@chakra-ui/styled-system";

const { defineMultiStyleConfig, definePartsStyle } =
    createMultiStyleConfigHelpers(parts.keys);

const baseStyleList = defineStyle({
    paddingX: 2,
    bg: "var(--chakra-colors-menuListBg)",
    border: "1px solid var(--chakra-colors-menuListBorder)",
    borderRadius: "0.375rem",
});

const baseStyleItem = defineStyle({
    padding: 2,
    lineHeight: "normal",
    bg: "var(--chakra-colors-menuItemBg)",
    color: "var(--chakra-colors-menuItemText)",
    fontSize: "0.875em",
    _hover: {
        bg: "var(--chakra-colors-menuItemHoverBg)",
    },
    _disabled: {
        opacity: 1,
        cursor: "not-allowed",
        color: "var(--chakra-colors-menuItemDisabledText)",
        _hover: {
            bg: "var(--chakra-colors-menuItemDisabledHoverBg)",
        },
    },
});

const baseStyleDivider = defineStyle({
    borderBottom: "1px solid var(--chakra-colors-menuDividerBorder) !important",
});

const baseStyle = definePartsStyle({
    list: baseStyleList,
    item: baseStyleItem,
    divider: baseStyleDivider,
});

export const Menu = defineMultiStyleConfig({
    baseStyle,
});
