import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import {
    createMultiStyleConfigHelpers,
    defineStyle,
} from "@chakra-ui/styled-system";

const { defineMultiStyleConfig, definePartsStyle } =
    createMultiStyleConfigHelpers(parts.keys);

const baseStyleDialog = defineStyle({
    bg: "modalBg",
    border: "1px solid var(--chakra-colors-modalBorder)",
    borderRadius: "0.5rem",
    boxShadow: "var(--chakra-colors-boxShadowPrimary)",
});

const baseStyleOverlay = {
    bg: "modalBgOverlay",
};

const baseStyleHeader = defineStyle({
    fontFamily: "Inter SemiBold, sans-serif",
    fontSize: "1.125rem",
    fontWeight: 600,
    lineHeight: "1.3612rem",
    borderBottom: "1px solid var(--chakra-colors-modalBorder)",
    padding: 6,
    color: "var(--chakra-colors-contentT20)",
});

const baseStyleBody = defineStyle({
    fontFamily: "Inter Regular, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "1.3125rem",
    color: "modalText",
    scrollbarWidth: "thin",
});

const baseStyleFooter = defineStyle({
    borderTop: "1px solid var(--chakra-colors-modalBorder)",
    padding: 6,
});

const baseStyleCloseButton = defineStyle({
    color: "var(--chakra-colors-modalCloseButtonText)",
    _hover: {
        bg: "var(--chakra-colors-modalCloseButtonHoverBg)",
    },
});

const baseStyle = definePartsStyle({
    header: baseStyleHeader,
    body: baseStyleBody,
    footer: baseStyleFooter,
    dialog: baseStyleDialog,
    overlay: baseStyleOverlay,
    closeButton: baseStyleCloseButton,
});

const sizes = {
    xxl: definePartsStyle({
        dialog: {
            maxW: "40rem",
        },
    }),
    xl: definePartsStyle({
        dialog: {
            minW: "52.25rem",
        },
    }),
};

export const Modal = defineMultiStyleConfig({
    baseStyle,
    sizes,
});
