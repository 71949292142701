import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
    field: {
        fontFamily: "Inter Regular, sans-serif",
        border: "1px solid",
        fontWeight: 400,
    },
    addon: {
        fontFamily: "Inter Regular, sans-serif",
        border: "1px solid",
        fontWeight: 400,
    },
    element: {
        fontFamily: "Inter Regular, sans-serif",
        fontWeight: 400,
    },
});

const sizes = {
    xs: definePartsStyle({
        field: {
            h: "1.7025rem",
            px: "0.495rem",
            py: "0.4125rem",
            borderRadius: "0.15rem",
            fontSize: "0.5775rem",
            lineHeight: "0.8665rem",
        },
        addon: {
            h: "1.7025rem",
            px: "0.495rem",
            py: "0.4125rem",
            borderRadius: "0.15rem",
            fontSize: "0.5775rem",
            lineHeight: "0.8665rem",
        },
        element: {
            h: "1.7025rem",
            fontSize: "0.495rem",
            lineHeight: "0.693rem",
        },
    }),
    sm: definePartsStyle({
        field: {
            h: "2.1325rem",
            px: "0.622rem",
            py: "0.518rem",
            borderRadius: "0.20rem",
            fontSize: "0.7262rem",
            lineHeight: "1.0895rem",
        },
        addon: {
            h: "2.1325rem",
            px: "0.622rem",
            py: "0.518rem",
            borderRadius: "0.25rem",
            fontSize: "0.7262rem",
            lineHeight: "1.0895rem",
        },
        element: {
            h: "2.1325rem",
            fontSize: "0.6225rem",
            lineHeight: "0.8715rem",
        },
    }),
    md: definePartsStyle({
        field: {
            h: "2.5625rem",
            px: 3,
            py: 2.5,
            borderRadius: "0.25rem",
            fontSize: "0.875rem",
            lineHeight: "1.3125rem",
        },
        addon: {
            h: "2.5625rem",
            px: 4,
            py: 2.5,
            borderRadius: "0.25rem",
            fontSize: "0.875rem",
            lineHeight: "1.3125rem",
        },
        element: {
            h: "2.5625rem",
            fontSize: "0.75rem",
            lineHeight: "1.05rem",
        },
    }),
    lg: definePartsStyle({
        field: {
            h: 12,
            px: "0.87rem",
            py: "0.73rem",
            borderRadius: "0.30rem",
            fontSize: "1.02rem",
            lineHeight: "1.5356rem",
        },
        addon: {
            h: 12,
            px: "0.87rem",
            py: "0.73rem",
            borderRadius: "0.30rem",
            fontSize: "1.02rem",
            lineHeight: "1.5356rem",
        },
        element: {
            h: 12,
            fontSize: "0.87rem",
            lineHeight: "1.23rem",
        },
    }),
};

const primary = definePartsStyle({
    field: {
        bg: "primaryInputFieldBg",
        borderColor: "primaryInputFieldBorder",
        color: "primaryInputFieldText",

        _disabled: {
            opacity: 1,
            bg: "primaryInputFieldDisabledBg",
            borderColor: "primaryInputFieldDisabledBorder",
            color: "primaryInputFieldDisabledText",
            _hover: {
                opacity: 1,
                bg: "primaryInputFieldDisabledHoverBg",
                borderColor: "primaryInputFieldDisabledHoverBorder",
                color: "primaryInputFieldDisabledHoverText",
            },
        },
        _placeholder: {
            color: "primaryInputFieldPlaceholder",
        },
        _hover: {
            borderColor: "primaryInputFieldHoverBorder",
        },
        _invalid: {
            borderColor: "primaryInputFieldInvalidBorder",
            boxShadow: "var(--chakra-colors-boxShadowFormEleInvalid)",
        },
        _focusVisible: {
            borderColor: "primaryInputFieldFocusBorder",
            boxShadow: "var(--chakra-colors-boxShadowFormEle)",
        },
    },

    addon: {
        bg: "primaryInputAddonBg",
        borderColor: "primaryInputAddonBorder",
        color: "primaryInputAddonText",

        _disabled: {
            opacity: 1,
            bg: "primaryInputAddonDisabledBg",
            borderColor: "primaryInputAddonDisabledBorder",
            color: "primaryInputAddonDisabledText",
            _hover: {
                opacity: 1,
                bg: "primaryInputAddonDisabledHoverBg",
                borderColor: "primaryInputAddonDisabledHoverBorder",
                color: "primaryInputAddonDisabledHoverText",
            },
        },
    },

    element: {
        color: "primaryInputElementText",
        insetEnd: 1,

        _disabled: {
            opacity: 1,
            bg: "primaryInputElementDisabledBg",
            borderColor: "primaryInputElementDisabledBorder",
            color: "primaryInputElementDisabledText",
            _hover: {
                opacity: 1,
                bg: "primaryInputElementDisabledHoverBg",
                borderColor: "primaryInputElementDisabledHoverBorder",
                color: "primaryInputElementDisabledHoverText",
            },
        },
    },
});

const variants = {
    primary,
};

export const Input = defineMultiStyleConfig({
    baseStyle,
    variants,
    sizes,
    defaultProps: {
        size: "md",
        variant: "primary",
    },
});
