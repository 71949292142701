import { FC } from "react";
import { FormContainerStateReturnType, useTrans } from "../../../hooks";
import {
    VStack,
    Input,
    InputGroup,
    InputRightAddon,
    Switch,
} from "@chakra-ui/react";
import { AppFormControl } from "../../../components";
import { AppFormContainer } from "../../../containers";
import { APPC } from "../../../config";

export const CompanyForm: FC<{
    formContainerState: FormContainerStateReturnType;
    onFormSubmit;
    register;
    formState;
    data;
    loading;
}> = ({
    formContainerState,
    onFormSubmit,
    register,
    formState,
    data,
    loading,
}) => {
    // hook
    const { t } = useTrans();

    // state & const
    const isEditMode: boolean = !!formContainerState.entityId;
    const { errors } = formState;

    return (
        <AppFormContainer
            title={t("padm.CompanyForm:text.pageTitle")}
            state={formContainerState}
            type={APPC.FORM_CONTAINER}
        >
            <form id="app-form" onSubmit={onFormSubmit}>
                <VStack gap={5} alignItems={"stretch"}>
                    <AppFormControl
                        label={t("ent.Company:name.label")}
                        isInvalid={!!errors.name}
                        message={errors.name?.message}
                        isLoading={loading}
                    >
                        <Input
                            type="text"
                            placeholder={t("ent.Company:name.placeholder")}
                            {...register("name")}
                            defaultValue={data.name}
                        />
                    </AppFormControl>
                    <AppFormControl
                        label={t("ent.Company:domain.label")}
                        isInvalid={!!errors.domain}
                        message={errors.domain?.message}
                        isDisabled={isEditMode}
                        isLoading={loading}
                    >
                        <InputGroup className="ra">
                            <Input
                                type="text"
                                placeholder={t(
                                    "ent.Company:domain.placeholder"
                                )}
                                {...register("domain")}
                                defaultValue={data.domain}
                            />
                            {!isEditMode && (
                                <InputRightAddon>
                                    .{APPC.APP_DOMAIN}
                                </InputRightAddon>
                            )}
                        </InputGroup>
                    </AppFormControl>
                    <AppFormControl
                        label={t("ent.Company:isActive.label")}
                        isInvalid={!!errors.isActive}
                        message={errors.isActive?.message}
                        isLoading={loading}
                    >
                        <Switch
                            {...register("isActive")}
                            defaultChecked={isEditMode ? !!data.isActive : true}
                        />
                    </AppFormControl>
                    {!isEditMode && (
                        <>
                            <AppFormControl
                                label={t("ent.User:firstName.label")}
                                isInvalid={!!errors?.users?.[0]?.["firstName"]}
                                message={
                                    errors?.users?.[0]?.["firstName"]?.message
                                }
                                isLoading={loading}
                            >
                                <Input
                                    type="text"
                                    placeholder={t(
                                        "ent.User:firstName.placeholder"
                                    )}
                                    {...register("users.0.firstName")}
                                />
                            </AppFormControl>
                            <AppFormControl
                                label={t("ent.User:lastName.label")}
                                isInvalid={!!errors?.users?.[0]?.["lastName"]}
                                message={
                                    errors?.users?.[0]?.["lastName"]?.message
                                }
                                isLoading={loading}
                            >
                                <Input
                                    type="text"
                                    placeholder={t(
                                        "ent.User:lastName.placeholder"
                                    )}
                                    {...register("users.0.lastName")}
                                />
                            </AppFormControl>
                            <AppFormControl
                                label={t("ent.User:email.label")}
                                isInvalid={!!errors?.users?.[0]?.["email"]}
                                message={errors?.users?.[0]?.["email"]?.message}
                                isLoading={loading}
                            >
                                <Input
                                    type="email"
                                    placeholder={t(
                                        "ent.User:email.placeholder"
                                    )}
                                    {...register("users.0.email")}
                                />
                            </AppFormControl>
                            <AppFormControl
                                label={t("ent.User:plainPassword.label")}
                                isInvalid={
                                    !!errors?.users?.[0]?.["plainPassword"]
                                }
                                message={
                                    errors?.users?.[0]?.["plainPassword"]
                                        ?.message
                                }
                                isLoading={loading}
                            >
                                <Input
                                    type="password"
                                    placeholder={t(
                                        "ent.User:plainPassword.placeholder"
                                    )}
                                    {...register("users.0.plainPassword")}
                                />
                            </AppFormControl>
                            <AppFormControl
                                label={t("ent.User:plainPasswordConfirm.label")}
                                isInvalid={
                                    !!errors?.users?.[0]?.[
                                        "plainPasswordConfirm"
                                    ]
                                }
                                message={
                                    errors?.users?.[0]?.["plainPasswordConfirm"]
                                        ?.message
                                }
                                isLoading={loading}
                            >
                                <Input
                                    type="password"
                                    placeholder={t(
                                        "ent.User:plainPasswordConfirm.placeholder"
                                    )}
                                    {...register(
                                        "users.0.plainPasswordConfirm"
                                    )}
                                />
                            </AppFormControl>
                        </>
                    )}
                </VStack>
            </form>
        </AppFormContainer>
    );
};
