import { FC } from "react";
import { FormContainerStateReturnType, useTrans } from "../../../hooks";
import { VStack, Input } from "@chakra-ui/react";
import { AppFormControl } from "../../../components";
import { AppFormContainer } from "../../../containers";
import { APPC } from "../../../config";

export const UserForm: FC<{
    formContainerState: FormContainerStateReturnType;
    onFormSubmit;
    register;
    formState;
    data;
    loading;
}> = ({
    formContainerState,
    onFormSubmit,
    register,
    formState,
    data,
    loading,
}) => {
    // hook
    const { t } = useTrans();

    // state & const
    const { errors } = formState;
    const isEditMode: boolean = !!formContainerState.entityId;

    return (
        <AppFormContainer
            title={t("padm.UserForm:text.pageTitle")}
            state={formContainerState}
            type={APPC.FORM_CONTAINER}
        >
            <form id="app-form" onSubmit={onFormSubmit}>
                <VStack gap={5} alignItems={"stretch"}>
                    <AppFormControl
                        label={t("ent.User:firstName.label")}
                        isInvalid={!!errors?.firstName}
                        message={errors?.firstName?.message}
                        isLoading={loading}
                    >
                        <Input
                            type="text"
                            placeholder={t("ent.User:firstName.placeholder")}
                            {...register("firstName")}
                            defaultValue={data.firstName}
                        />
                    </AppFormControl>
                    <AppFormControl
                        label={t("ent.User:lastName.label")}
                        isInvalid={!!errors?.lastName}
                        message={errors?.lastName?.message}
                        isLoading={loading}
                    >
                        <Input
                            type="text"
                            placeholder={t("ent.User:lastName.placeholder")}
                            {...register("lastName")}
                            defaultValue={data.lastName}
                        />
                    </AppFormControl>
                    <AppFormControl
                        label={t("ent.User:email.label")}
                        isInvalid={!!errors?.email}
                        message={errors?.email?.message}
                        isLoading={loading}
                    >
                        <Input
                            type="email"
                            placeholder={t("ent.User:email.placeholder")}
                            {...register("email")}
                            defaultValue={data.email}
                            disabled={isEditMode}
                        />
                    </AppFormControl>
                    {!isEditMode && (
                        <>
                            <AppFormControl
                                label={t("ent.User:plainPassword.label")}
                                isInvalid={!!errors?.plainPassword}
                                message={errors?.plainPassword?.message}
                            >
                                <Input
                                    type="password"
                                    placeholder={t(
                                        "ent.User:plainPassword.placeholder"
                                    )}
                                    {...register("plainPassword")}
                                />
                            </AppFormControl>
                            <AppFormControl
                                label={t("ent.User:plainPasswordConfirm.label")}
                                isInvalid={!!errors?.plainPasswordConfirm}
                                message={errors?.plainPasswordConfirm?.message}
                            >
                                <Input
                                    type="password"
                                    placeholder={t(
                                        "ent.User:plainPasswordConfirm.placeholder"
                                    )}
                                    {...register("plainPasswordConfirm")}
                                />
                            </AppFormControl>
                        </>
                    )}
                </VStack>
            </form>
        </AppFormContainer>
    );
};
