import { memo } from "react";
import {
    Button,
    Divider,
    HStack,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Spacer,
    VStack,
    Text,
} from "@chakra-ui/react";
import { NavItem } from "./AppNav";
import AppNavItem from "./AppNavItem";
import { AppIcon } from "../../components";
import { ButtonVariants } from "../../theme/components/button";

interface AppNavDropdownItem {
    navDropdownItem: NavItem;
    subActive: number;
    variant?: ButtonVariants;
    index: number;
    handleDropdownClick: (i: number, url?: string) => void;
    onlyIcon?: boolean;
}

const AppNavDropdownItem = ({
    navDropdownItem,
    variant,
    index,
    handleDropdownClick,
    onlyIcon,
    subActive,
}: AppNavDropdownItem) => {
    const handleButtonClickLocal = () => {
        handleDropdownClick(index, navDropdownItem?.url);
    };

    const renderButton = () => {
        return (
            <Button
                isActive={subActive > -1}
                variant={variant}
                className={onlyIcon ? "btn-icon-md" : ""}
                onClick={!onlyIcon ? handleButtonClickLocal : () => {}}
                size="sm"
            >
                <HStack gap={5}>
                    {navDropdownItem.iconName && (
                        <AppIcon name={navDropdownItem?.iconName || ""} />
                    )}

                    {!onlyIcon && (
                        <Text as={"span"} fontWeight="normal">
                            {navDropdownItem.label}
                        </Text>
                    )}
                </HStack>
                <Spacer />
                {!onlyIcon && (
                    <AppIcon name={"icl-chevron-bottom-alt"} w="1rem" />
                )}
            </Button>
        );
    };

    const renderSubItem = () => {
        return (
            <VStack
                gap={1}
                py={1}
                ps={!onlyIcon ? 9 : 1}
                alignItems={"stretch"}
                justify={"start"}
                className={
                    !onlyIcon
                        ? subActive > -1
                            ? "dropdown-container"
                            : "dropdown-container close-dropdown"
                        : ""
                }
            >
                {navDropdownItem?.children?.map((child, index) => (
                    <AppNavItem
                        key={index}
                        navItem={child}
                        variant={variant}
                        isActive={subActive === index}
                    />
                ))}
            </VStack>
        );
    };

    return (
        <>
            {onlyIcon ? (
                <Popover placement="right-start">
                    <PopoverTrigger>{renderButton()}</PopoverTrigger>
                    <PopoverContent
                        bg={"var(--chakra-colors-primaryT95)"}
                        ml={4}
                    >
                        <PopoverBody>{renderSubItem()}</PopoverBody>
                    </PopoverContent>
                </Popover>
            ) : (
                <>
                    {renderButton()}
                    {renderSubItem()}
                </>
            )}

            {navDropdownItem?.divider && (
                <Divider
                    orientation="horizontal"
                    my={4}
                    borderColor={"var(--chakra-colors-primaryT80)"}
                    alignSelf={"stretch"}
                />
            )}
        </>
    );
};

export default memo(AppNavDropdownItem, (prevProps, nextProps) => {
    return (
        prevProps.subActive === nextProps.subActive &&
        prevProps.onlyIcon === nextProps.onlyIcon
    );
});
