import { BaseEntity } from "./BaseEntity";
import { Company } from "./Company";
import { UserWorkspace } from "./UserWorkspace";

export class Workspace extends BaseEntity {
    public name: string = "";
    public company: string | Company = "";
    public userWorkspaces?: string | UserWorkspace = "";

    constructor(model: Partial<Workspace> = {}) {
        super(model?.id);
        Object.assign(this, model);
    }
}

export type PWorkspace = Partial<Workspace>;
