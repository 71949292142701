import {
    Box,
    Button,
    Divider,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    VStack,
    Text,
    Select,
    FormControl,
    FormLabel,
} from "@chakra-ui/react";
import { AppTaskCheckList } from "./AppTaskCheckList";
import { AppIcon } from "./AppIcon";

export const AppTaskModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <>
            <Button onClick={onOpen}>Open Modal</Button>
            <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Monthly Product Discussion</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody p={6}>
                        <Flex gap={6}>
                            <VStack alignItems={"flex-start"} gap={6}>
                                <VStack alignItems={"flex-start"} gap={2}>
                                    <Text
                                        textStyle={"mediumSm"}
                                        fontWeight={500}
                                    >
                                        Description
                                    </Text>
                                    <Box
                                        color={
                                            "var(--chakra-colors-contentT40)"
                                        }
                                    >
                                        Monthly Product Discussion by Design and
                                        Marketing Teams with CEO to Plan our
                                        future products sales and reports
                                    </Box>
                                </VStack>
                                <AppTaskCheckList />
                                <Button variant={"outline"} width={"full"}>
                                    <AppIcon name="icl-plus" w="1.25rem" />
                                    <Text ml={2}>Add New Checklist</Text>
                                </Button>
                            </VStack>
                            <Divider
                                orientation="vertical"
                                height={"auto"}
                                borderColor={"var(--chakra-colors-primaryT80)"}
                            />
                            <VStack
                                alignItems={"flex-start"}
                                minW={"172px"}
                                gap={5}
                            >
                                <FormControl>
                                    <FormLabel>Assignee</FormLabel>
                                    <Select placeholder="Select assignee">
                                        <option>Hiren</option>
                                        <option>Sagar</option>
                                        <option>Kishan</option>
                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Due Date</FormLabel>
                                    <Select placeholder="Select due date">
                                        <option>01 Oct 2024</option>
                                        <option>02 Oct 2024</option>
                                        <option>03 Oct 2024</option>
                                    </Select>
                                </FormControl>
                            </VStack>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
