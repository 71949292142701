import {
    Avatar,
    Box,
    Button,
    Divider,
    HStack,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { AppIcon } from "./AppIcon";
import AppNavItem from "../containers/AppNav/AppNavItem";
import { User } from "../apis";
import { NavItem } from "../containers";
import { AppWorkspaceSelector } from "./AppWorkspaceSelector";

interface AppUserNavProps {
    user: User;
    navItems: NavItem[];
}

export const AppUserNav = ({ user, navItems }: AppUserNavProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <HStack gap={1}>
            <Avatar
                size={"sm"}
                name={user?.fullName}
                // src={"https://bit.ly/dan-abramov"}
                color={"var(--chakra-colors-primaryS10)"}
                bgColor={"var(--chakra-colors-primaryT80)"}
            />
            <Popover
                placement="bottom-end"
                offset={[0, 15]}
                isOpen={isOpen}
                onClose={onClose}
                onOpen={onOpen}
            >
                <PopoverTrigger>
                    <Button
                        size={"sm"}
                        className={"btn-icon-header"}
                        variant={"transparent"}
                    >
                        <AppIcon
                            name={"icl-chevron-bottom-alt"}
                            w="1rem"
                            color={"var(--chakra-colors-primary)"}
                        />
                    </Button>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverBody>
                        {isOpen && (
                            <VStack
                                gap={1}
                                py={1}
                                ps={1}
                                alignItems={"stretch"}
                                justify={"start"}
                            >
                                <HStack gap={3} px={4} pb={2}>
                                    <Avatar
                                        size={"md"}
                                        name={user?.fullName}
                                        // src={"https://bit.ly/dan-abramov"}
                                        color={
                                            "var(--chakra-colors-primaryS10)"
                                        }
                                        bgColor={
                                            "var(--chakra-colors-primaryT80)"
                                        }
                                    />
                                    <Box>
                                        <Text textStyle={"heading5"}>
                                            {user?.fullName}
                                        </Text>
                                        <Text
                                            textStyle={"regularSm"}
                                            color={
                                                "var(--chakra-colors-contentT40)"
                                            }
                                        >
                                            {user.email}
                                        </Text>
                                    </Box>
                                </HStack>
                                <Divider
                                    mb={2}
                                    borderColor={
                                        "var(--chakra-colors-primaryT80)"
                                    }
                                />
                                <AppWorkspaceSelector />
                                <Divider
                                    mt={2}
                                    mb={2}
                                    borderColor={
                                        "var(--chakra-colors-primaryT80)"
                                    }
                                />
                                {navItems?.map((item, index) => (
                                    <AppNavItem
                                        key={index}
                                        navItem={item}
                                        variant={"transparent"}
                                    />
                                ))}
                            </VStack>
                        )}
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </HStack>
    );
};
