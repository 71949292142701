export const APP_PROTOCOL = process.env.REACT_APP_PROTOCOL;
export const APP_SUBDOMAIN = process.env.REACT_APP_SUBDOMAIN;
export const APP_DOMAIN = process.env.REACT_APP_DOMAIN;
export const APP_FULLDOMAIN = `${APP_SUBDOMAIN}.${APP_DOMAIN}`;
export const APP_BASEPATH = `${APP_PROTOCOL}://${APP_FULLDOMAIN}`;
export const PAGE_SIZE = 10;
export const PAGE_SIZES = [10, 30, 50, 100];
export const FORM_CONTAINER = "drawer";

export const BE = {
    User: {
        ROLE_SUPER_ADMIN: "ROLE_SUPER_ADMIN",
        ROLE_COMPANY_ADMIN: "ROLE_COMPANY_ADMIN",
        ROLE_ADMIN: "ROLE_ADMIN",
        ROLE_USER: "ROLE_USER",
    },
    KanbanStatus: {
        TYPE_BACKLOG: "BACKLOG",
        TYPE_TODO: "TODO",
        TYPE_PROCESS: "PROCESS",
        TYPE_COMPLETED: "COMPLETED",
    },
    Task: {
        PRIORITY_HEIGHEST: 1,
        PRIORITY_HIGH: 2,
        PRIORITY_MEDIUM: 3,
        PRIORITY_LOW: 4,
        PRIORITY_LOWEST: 5,
    },
};

export const COLUMN_KEYS = {
    TODO: "TODO",
    PROGRESS: "PROGRESS",
    COMPLETED: "COMPLETED",
    TESTING: "TESTING",
};
