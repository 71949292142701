import { useState } from "react";
import { useTrans } from "../hooks";
import { Box, Flex, HStack, Input, InputGroup, Text } from "@chakra-ui/react";
import { AppIcon } from "./AppIcon";
import { PKanbanStatus } from "../apis";
import { APPC } from "../config";

interface AppKanbanStatusCardProps {
    kanbanStatus: PKanbanStatus;
    onRemove: (id: number) => void;
    onUpdate: (index: number, name: string) => void;
}

export const AppKanbanStatusCard = ({
    kanbanStatus,
    onRemove,
    onUpdate,
}: AppKanbanStatusCardProps) => {
    const { t } = useTrans();

    const [status, setStatus] = useState<PKanbanStatus>(kanbanStatus);
    const [editStatusId, setEditStatusId] = useState<number | undefined>(0);
    const [isNameChange, setIsNameChange] = useState(false);

    const cardStyle = {
        [APPC.BE.KanbanStatus.TYPE_BACKLOG]: {
            border: "1px solid var(--chakra-colors-greyT75)",
            bg: "var(--chakra-colors-greyT95)",
        },
        [APPC.BE.KanbanStatus.TYPE_TODO]: {
            border: "1px solid var(--chakra-colors-greyT80)",
            bg: "var(--chakra-colors-white)",
        },
        [APPC.BE.KanbanStatus.TYPE_PROCESS]: {
            border: "1px solid var(--chakra-colors-blueT70)",
            bg: "var(--chakra-colors-blueT90)",
        },
        [APPC.BE.KanbanStatus.TYPE_COMPLETED]: {
            border: "1px solid var(--chakra-colors-successT70)",
            bg: "var(--chakra-colors-successT90)",
        },
    };

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStatus({
            ...status,
            name: e.target.value,
        });
        setIsNameChange(true);
    };

    const onBlurHandler = () => {
        if (isNameChange) {
            onUpdate(status.id || 0, status.name || "");
            setIsNameChange(false);
        }
        setEditStatusId(0);
    };

    return (
        <Flex
            position={"relative"}
            w={"290px"}
            direction={"column"}
            p={5}
            gap={5}
            borderRadius={8}
            justifyContent={"space-between"}
            {...(status?.type && { ...cardStyle[status.type] })}
        >
            <Text
                pos={"absolute"}
                top={"-8px"}
                right={"10px"}
                p={"0 5px"}
                bg={"var(--chakra-colors-white)"}
                borderRadius={"full"}
                textStyle="regularXs"
            >
                {t(`con.KanbanStatus:TYPE_${status.type}`)}
            </Text>
            <HStack justifyContent={"space-between"}>
                <HStack gap={4}>
                    {editStatusId === status.id ? (
                        <InputGroup>
                            <Input
                                size={"sm"}
                                maxLength={30}
                                value={status.name}
                                autoFocus
                                onChange={onChangeHandler}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        onBlurHandler();
                                    }
                                }}
                                onBlur={onBlurHandler}
                            />
                            <Text
                                pos={"absolute"}
                                top={"-8px"}
                                right={"10px"}
                                p={"0 5px"}
                                bg={"var(--chakra-colors-white)"}
                                textStyle="regularXs"
                            >
                                {status.name?.length || 0}/30
                            </Text>
                        </InputGroup>
                    ) : (
                        <Text
                            noOfLines={1}
                            textStyle="mediumSm"
                            verticalAlign={"bottom"}
                            textOverflow={"ellipsis"}
                            lineHeight={"34px"}
                            cursor={"pointer"}
                            minH={"34px"}
                            minW={"120px"}
                            onClick={() => setEditStatusId(status.id)}
                        >
                            {status.name}
                        </Text>
                    )}
                </HStack>
                <Box
                    as="button"
                    w="1.25rem"
                    onClick={() => onRemove(status.id || 0)}
                >
                    <AppIcon
                        name="icl-trash"
                        w="1.25rem"
                        color={"var(--chakra-colors-destructive)"}
                    />
                </Box>
            </HStack>
        </Flex>
    );
};
