import {
    Box,
    Button,
    Checkbox,
    Divider,
    Flex,
    HStack,
    Input,
    InputGroup,
    Text,
} from "@chakra-ui/react";
import { AppIcon } from "./AppIcon";
import { useEffect, useRef, useState } from "react";

type TaskCheckList = {
    id: number;
    label: string;
    isCompleted: boolean;
    isEditing: boolean;
};

const taskCheckList: TaskCheckList[] = [
    {
        id: 1,
        label: "Prepare Design Document",
        isCompleted: false,
        isEditing: false,
    },
    {
        id: 2,
        label: "Document Signature",
        isCompleted: true,
        isEditing: false,
    },
    {
        id: 3,
        label: "Pitch Deck Presentation Design",
        isCompleted: false,
        isEditing: false,
    },
];

export const AppTaskCheckList = () => {
    const checkItemRef = useRef<number>(4);
    const [isOpen, setIsOpen] = useState(true);
    const [checkList, setCheckList] = useState<TaskCheckList[]>(taskCheckList);
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [checklistTitle, setChecklistTitle] = useState("Task Checklist");
    const [totalCheckedItems, setTotalCheckedItems] = useState<number>(0);

    const getNumOfCheckedItems = () => {
        let count = 0;
        checkList?.forEach((item) => {
            if (item.isCompleted) {
                count++;
            }
        });
        return count;
    };

    useEffect(() => {
        setTotalCheckedItems(getNumOfCheckedItems());
    }, [checkList]);

    const addNewCheckItem = () => {
        const newItem = {
            id: checkItemRef.current,
            label: "",
            isEditing: true,
            isCompleted: false,
        };
        setCheckList((prev) => [...prev, newItem]);
        checkItemRef.current++;
    };

    const toggleCheckItem = (id: number) => {
        setCheckList((prev) =>
            prev.map((item) =>
                item.id === id ? { ...item, isEditing: !item.isEditing } : item
            )
        );
    };

    const handleCheckItemTextChange = (id: number, val: string) => {
        setCheckList((prev) =>
            prev.map((item) =>
                item.id === id ? { ...item, label: val } : item
            )
        );
    };

    const handleToggleComplete = (id: number) => {
        setCheckList((prev) =>
            prev.map((item) =>
                item.id === id
                    ? { ...item, isCompleted: !item.isCompleted }
                    : item
            )
        );
    };

    const handleDeleteCheckItem = (id: number) => {
        setCheckList((prev) => prev.filter((item) => item.id !== id));
    };

    return (
        <>
            <Flex
                direction="column"
                width={"full"}
                justifyContent={"space-between"}
            >
                {checkList.length > 0 && (
                    <>
                        <HStack
                            justifyContent={"space-between"}
                            paddingBottom={3}
                            _hover={{
                                ".delete-icon": { visibility: "visible" },
                            }}
                        >
                            <HStack w={"full"}>
                                <Box
                                    as="button"
                                    onClick={() => setIsOpen((prev) => !prev)}
                                >
                                    <AppIcon
                                        name={
                                            isOpen
                                                ? "icl-chevron-top-alt"
                                                : "icl-chevron-bottom-alt"
                                        }
                                        w="1rem"
                                        color={"var(--chakra-colors-primary)"}
                                    />
                                </Box>
                                <Box flexGrow={1}>
                                    {isEditingTitle ? (
                                        <InputGroup>
                                            <Input
                                                autoFocus
                                                size="sm"
                                                value={checklistTitle}
                                                maxLength={100}
                                                onChange={(e) =>
                                                    setChecklistTitle(
                                                        e.target.value
                                                    )
                                                }
                                                onBlur={(e) => {
                                                    if (e.target.value) {
                                                        setIsEditingTitle(
                                                            false
                                                        );
                                                    } else {
                                                        setIsEditingTitle(true);
                                                    }
                                                }}
                                            />
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    backgroundColor:
                                                        "var(--chakra-colors-white)",
                                                    padding: "0 5px",
                                                    right: "10px",
                                                    top: "-10px",
                                                }}
                                            >
                                                {checklistTitle.length}
                                                /100
                                            </span>
                                        </InputGroup>
                                    ) : (
                                        <Text
                                            textStyle={"mediumSm"}
                                            fontWeight={500}
                                            onDoubleClick={() =>
                                                setIsEditingTitle(true)
                                            }
                                        >
                                            {checklistTitle}
                                        </Text>
                                    )}
                                </Box>
                            </HStack>
                            <HStack
                                gap={"0.25rem"}
                                color={"var(--chakra-colors-contentT40)"}
                            >
                                <AppIcon name="icl-list" w="1rem" />
                                <Text textStyle={"regularSm"}>
                                    {`${totalCheckedItems}/${checkList.length}`}
                                </Text>
                            </HStack>
                            <Box
                                as="button"
                                visibility="hidden"
                                className="delete-icon"
                                color={"var(--chakra-colors-destructive)"}
                                onClick={() => setCheckList([])}
                            >
                                <AppIcon name={"icl-trash"} w="1rem" />
                            </Box>
                        </HStack>
                        <Divider
                            marginBottom={2}
                            borderColor={"var(--chakra-colors-primaryT80)"}
                        />
                    </>
                )}
                {isOpen && checkList.length > 0 && (
                    <Flex direction={"column"} gap={4} marginTop={4}>
                        {checkList?.map((item) => (
                            <HStack
                                key={item.id}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                _hover={{
                                    ".delete-icon": {
                                        visibility: "visible",
                                    },
                                }}
                            >
                                <Checkbox
                                    isChecked={item.isCompleted}
                                    onChange={() =>
                                        handleToggleComplete(item.id)
                                    }
                                />
                                <Box flexGrow={1}>
                                    {item.isEditing ? (
                                        <InputGroup>
                                            <Input
                                                size={"sm"}
                                                maxLength={100}
                                                value={item.label}
                                                autoFocus
                                                onChange={(e) => {
                                                    handleCheckItemTextChange(
                                                        item.id,
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={() => {
                                                    if (item.label) {
                                                        toggleCheckItem(
                                                            item.id
                                                        );
                                                    } else {
                                                        handleDeleteCheckItem(
                                                            item.id
                                                        );
                                                    }
                                                }}
                                            />
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    backgroundColor:
                                                        "var(--chakra-colors-white)",
                                                    padding: "0 5px",
                                                    right: "10px",
                                                    top: "-10px",
                                                }}
                                            >
                                                {item.label.length}/100
                                            </span>
                                        </InputGroup>
                                    ) : (
                                        <Text
                                            minH={5}
                                            onDoubleClick={() =>
                                                toggleCheckItem(item.id)
                                            }
                                        >
                                            {item.label}
                                        </Text>
                                    )}
                                </Box>
                                <Box
                                    as="button"
                                    visibility="hidden"
                                    className="delete-icon"
                                    color={"var(--chakra-colors-destructive)"}
                                    onClick={() =>
                                        handleDeleteCheckItem(item.id)
                                    }
                                >
                                    <AppIcon name={"icl-trash"} w="1rem" />
                                </Box>
                            </HStack>
                        ))}
                        {checkList.length > 0 && (
                            <Box>
                                <Button
                                    onClick={addNewCheckItem}
                                    variant={"secondary"}
                                    size={"sm"}
                                >
                                    <AppIcon name="icl-plus" w="0.875rem" />
                                    <Text
                                        ml={1}
                                        textStyle={"mediumXs"}
                                        fontWeight={500}
                                        lineHeight={1}
                                    >
                                        Add Item
                                    </Text>
                                </Button>
                            </Box>
                        )}
                    </Flex>
                )}
            </Flex>
        </>
    );
};

{
    /* <Input
    autoFocus
    size="sm"
    defaultValue={checklistTitle}
    maxLength={100}
    onBlur={(e) => {
        if (e.target.value) {
            setChecklistTitle(e.target.value);
            setIsEditingTitle(false);
        } else {
            setIsEditingTitle(true);
        }
    }}
/> */
}
