import { calculateTintShade } from "./utill";

const getColors = (primaryColor: string) => {
    const variableColors = {
        // primary: "#003A95",
        // primary: "#5f3789",
        primary: primaryColor || "#000000",
        grey: "#000000",
        success: "#198754",
        destructive: "#c81e1e",
        blue: "#0000ff",
        overlay: "#00000066",
        transparent: "transparent",
        content: "#000000",
        formLabel: "#000000",
    };

    const masterColors = {
        black: "#000000",
        white: "#FFFFFF",

        primary: variableColors.primary,
        primaryT10: calculateTintShade(variableColors.primary, 10, 0).tint,
        primaryT20: calculateTintShade(variableColors.primary, 20, 0).tint,
        primaryT30: calculateTintShade(variableColors.primary, 30, 0).tint,
        primaryT40: calculateTintShade(variableColors.primary, 40, 0).tint,
        primaryT50: calculateTintShade(variableColors.primary, 50, 0).tint,
        primaryT60: calculateTintShade(variableColors.primary, 60, 0).tint,
        primaryT70: calculateTintShade(variableColors.primary, 70, 0).tint,
        primaryT80: calculateTintShade(variableColors.primary, 80, 0).tint,
        primaryT85: calculateTintShade(variableColors.primary, 85, 0).tint,
        primaryT90: calculateTintShade(variableColors.primary, 90, 0).tint,
        primaryT95: calculateTintShade(variableColors.primary, 95, 0).tint,
        primaryS10: calculateTintShade(variableColors.primary, 0, 10).shade,
        primaryS20: calculateTintShade(variableColors.primary, 0, 20).shade,
        primaryS30: calculateTintShade(variableColors.primary, 0, 30).shade,
        primaryS40: calculateTintShade(variableColors.primary, 0, 40).shade,
        primaryS50: calculateTintShade(variableColors.primary, 0, 50).shade,
        primaryS60: calculateTintShade(variableColors.primary, 0, 60).shade,
        primaryS70: calculateTintShade(variableColors.primary, 0, 70).shade,
        primaryS80: calculateTintShade(variableColors.primary, 0, 80).shade,
        primaryS90: calculateTintShade(variableColors.primary, 0, 90).shade,

        grey: variableColors.grey,
        greyT05: calculateTintShade(variableColors.grey, 5, 0).tint,
        greyT10: calculateTintShade(variableColors.grey, 10, 0).tint,
        greyT15: calculateTintShade(variableColors.grey, 15, 0).tint,
        greyT20: calculateTintShade(variableColors.grey, 20, 0).tint,
        greyT25: calculateTintShade(variableColors.grey, 25, 0).tint,
        greyT30: calculateTintShade(variableColors.grey, 30, 0).tint,
        greyT35: calculateTintShade(variableColors.grey, 35, 0).tint,
        greyT40: calculateTintShade(variableColors.grey, 40, 0).tint,
        greyT45: calculateTintShade(variableColors.grey, 45, 0).tint,
        greyT50: calculateTintShade(variableColors.grey, 50, 0).tint,
        greyT55: calculateTintShade(variableColors.grey, 55, 0).tint,
        greyT60: calculateTintShade(variableColors.grey, 60, 0).tint,
        greyT65: calculateTintShade(variableColors.grey, 65, 0).tint,
        greyT70: calculateTintShade(variableColors.grey, 70, 0).tint,
        greyT75: calculateTintShade(variableColors.grey, 75, 0).tint,
        greyT80: calculateTintShade(variableColors.grey, 80, 0).tint,
        greyT85: calculateTintShade(variableColors.grey, 85, 0).tint,
        greyT90: calculateTintShade(variableColors.grey, 90, 0).tint,
        greyT95: calculateTintShade(variableColors.grey, 95, 0).tint,

        success: variableColors.success,
        successT10: calculateTintShade(variableColors.success, 10, 0).tint,
        successT20: calculateTintShade(variableColors.success, 20, 0).tint,
        successT30: calculateTintShade(variableColors.success, 30, 0).tint,
        successT40: calculateTintShade(variableColors.success, 40, 0).tint,
        successT50: calculateTintShade(variableColors.success, 50, 0).tint,
        successT60: calculateTintShade(variableColors.success, 60, 0).tint,
        successT70: calculateTintShade(variableColors.success, 70, 0).tint,
        successT80: calculateTintShade(variableColors.success, 80, 0).tint,
        successT90: calculateTintShade(variableColors.success, 90, 0).tint,
        successS10: calculateTintShade(variableColors.success, 0, 10).shade,
        successS20: calculateTintShade(variableColors.success, 0, 20).shade,
        successS30: calculateTintShade(variableColors.success, 0, 30).shade,
        successS40: calculateTintShade(variableColors.success, 0, 40).shade,
        successS50: calculateTintShade(variableColors.success, 0, 50).shade,
        successS60: calculateTintShade(variableColors.success, 0, 60).shade,
        successS70: calculateTintShade(variableColors.success, 0, 70).shade,
        successS80: calculateTintShade(variableColors.success, 0, 80).shade,
        successS90: calculateTintShade(variableColors.success, 0, 90).shade,

        destructive: variableColors.destructive,
        destructiveT10: calculateTintShade(variableColors.destructive, 10, 0)
            .tint,
        destructiveT20: calculateTintShade(variableColors.destructive, 20, 0)
            .tint,
        destructiveT30: calculateTintShade(variableColors.destructive, 30, 0)
            .tint,
        destructiveT40: calculateTintShade(variableColors.destructive, 40, 0)
            .tint,
        destructiveT50: calculateTintShade(variableColors.destructive, 50, 0)
            .tint,
        destructiveT60: calculateTintShade(variableColors.destructive, 60, 0)
            .tint,
        destructiveT70: calculateTintShade(variableColors.destructive, 70, 0)
            .tint,
        destructiveT80: calculateTintShade(variableColors.destructive, 80, 0)
            .tint,
        destructiveT90: calculateTintShade(variableColors.destructive, 90, 0)
            .tint,
        destructiveS10: calculateTintShade(variableColors.destructive, 0, 10)
            .shade,
        destructiveS20: calculateTintShade(variableColors.destructive, 0, 20)
            .shade,
        destructiveS30: calculateTintShade(variableColors.destructive, 0, 30)
            .shade,
        destructiveS40: calculateTintShade(variableColors.destructive, 0, 40)
            .shade,
        destructiveS50: calculateTintShade(variableColors.destructive, 0, 50)
            .shade,
        destructiveS60: calculateTintShade(variableColors.destructive, 0, 60)
            .shade,
        destructiveS70: calculateTintShade(variableColors.destructive, 0, 70)
            .shade,
        destructiveS80: calculateTintShade(variableColors.destructive, 0, 80)
            .shade,
        destructiveS90: calculateTintShade(variableColors.destructive, 0, 90)
            .shade,

        overlay: variableColors.overlay,
        transparent: variableColors.transparent,
        boxShadowPrimary: `0px 4px 36px 0px ${variableColors.primary}29`,
        boxShadowButton: `0 0 0 3px ${variableColors.primary}99`,
        boxShadowDestructiveButton: `0 0 0 3px ${variableColors.destructive}99`,
        boxShadowFormEle: `0 0 0 1px ${variableColors.primary}`,
        boxShadowFormEleInvalid: `0 0 0 1px ${variableColors.destructive}`,

        content: variableColors.content,
        contentT10: calculateTintShade(variableColors.content, 10, 0).tint,
        contentT20: calculateTintShade(variableColors.content, 20, 0).tint,
        contentT30: calculateTintShade(variableColors.content, 30, 0).tint,
        contentT40: calculateTintShade(variableColors.content, 40, 0).tint,
        contentT50: calculateTintShade(variableColors.content, 50, 0).tint,
        contentT60: calculateTintShade(variableColors.content, 60, 0).tint,
        contentT70: calculateTintShade(variableColors.content, 70, 0).tint,
        contentT80: calculateTintShade(variableColors.content, 80, 0).tint,
        contentT90: calculateTintShade(variableColors.content, 90, 0).tint,

        blue: variableColors.blue,
        blueT10: calculateTintShade(variableColors.blue, 10, 0).tint,
        blueT20: calculateTintShade(variableColors.blue, 20, 0).tint,
        blueT30: calculateTintShade(variableColors.blue, 30, 0).tint,
        blueT40: calculateTintShade(variableColors.blue, 40, 0).tint,
        blueT50: calculateTintShade(variableColors.blue, 50, 0).tint,
        blueT60: calculateTintShade(variableColors.blue, 60, 0).tint,
        blueT70: calculateTintShade(variableColors.blue, 70, 0).tint,
        blueT80: calculateTintShade(variableColors.blue, 80, 0).tint,
        blueT90: calculateTintShade(variableColors.blue, 90, 0).tint,
        blueS10: calculateTintShade(variableColors.blue, 0, 10).shade,
        blueS20: calculateTintShade(variableColors.blue, 0, 20).shade,
        blueS30: calculateTintShade(variableColors.blue, 0, 30).shade,
        blueS40: calculateTintShade(variableColors.blue, 0, 40).shade,
        blueS50: calculateTintShade(variableColors.blue, 0, 50).shade,
        blueS60: calculateTintShade(variableColors.blue, 0, 60).shade,
        blueS70: calculateTintShade(variableColors.blue, 0, 70).shade,
        blueS80: calculateTintShade(variableColors.blue, 0, 80).shade,
        blueS90: calculateTintShade(variableColors.blue, 0, 90).shade,
    };

    const colors = {
        ...masterColors,

        // BUTTON

        primaryButtonBg: masterColors.primary,
        primaryButtonText: masterColors.white,
        primaryButtonHoverBg: masterColors.primaryT10,
        primaryButtonHoverText: masterColors.white,
        primaryButtonActiveBg: masterColors.primaryS10,
        primaryButtonActiveText: masterColors.white,
        primaryButtonDisabledBg: masterColors.greyT95,
        primaryButtonDisabledText: masterColors.greyT85,
        primaryButtonDisabledHoverBg: masterColors.greyT95,
        primaryButtonDisabledHoverText: masterColors.greyT85,

        secondaryButtonBg: masterColors.primaryT90,
        secondaryButtonText: masterColors.primary,
        secondaryButtonHoverBg: masterColors.primaryT80,
        secondaryButtonHoverText: masterColors.primary,
        secondaryButtonActiveBg: masterColors.primaryT70,
        secondaryButtonActiveText: masterColors.primary,
        secondaryButtonDisabledBg: masterColors.greyT95,
        secondaryButtonDisabledText: masterColors.greyT70,
        secondaryButtonDisabledHoverBg: masterColors.greyT95,
        secondaryButtonDisabledHoverText: masterColors.greyT70,

        outlineButtonBg: masterColors.white,
        outlineButtonText: masterColors.primary,
        outlineButtonBorder: masterColors.primary,
        outlineButtonHoverBg: masterColors.primaryT80,
        outlineButtonHoverText: masterColors.primary,
        outlineButtonHoverBorder: masterColors.primary,
        outlineButtonActiveBg: masterColors.primaryT70,
        outlineButtonActiveText: masterColors.primary,
        outlineButtonDisabledBg: masterColors.white,
        outlineButtonDisabledText: masterColors.greyT70,
        outlineButtonDisabledBorder: masterColors.greyT95,
        outlineButtonDisabledHoverBg: masterColors.white,
        outlineButtonDisabledHoverText: masterColors.greyT70,
        outlineButtonDisabledHoverBorder: masterColors.greyT95,

        destructiveButtonBg: masterColors.white,
        destructiveButtonText: masterColors.destructive,
        destructiveButtonBorder: masterColors.destructive,
        destructiveButtonHoverBg: masterColors.destructiveT80,
        destructiveButtonHoverText: masterColors.destructive,
        destructiveButtonHoverBorder: masterColors.destructive,
        destructiveButtonActiveBg: masterColors.destructiveT70,
        destructiveButtonActiveText: masterColors.destructive,
        destructiveButtonDisabledBg: masterColors.white,
        destructiveButtonDisabledText: masterColors.destructiveT80,
        destructiveButtonDisabledBorder: masterColors.destructiveT90,
        destructiveButtonDisabledHoverBg: masterColors.white,
        destructiveButtonDisabledHoverText: masterColors.destructiveT80,
        destructiveButtonDisabledHoverBorder: masterColors.destructiveT90,

        transparentButtonBg: masterColors.transparent,
        transparentButtonText: masterColors.primary,
        transparentButtonHoverBg: masterColors.primaryT85,
        transparentButtonHoverText: masterColors.primary,
        transparentButtonActiveBg: masterColors.primaryT90,
        transparentButtonActiveText: masterColors.primaryS10,
        transparentButtonDisabledBg: masterColors.greyT95,
        transparentButtonDisabledText: masterColors.greyT70,
        transparentButtonDisabledHoverBg: masterColors.greyT95,
        transparentButtonDisabledHoverText: masterColors.greyT70,

        tabButtonBg: masterColors.white,
        tabButtonText: masterColors.primary,
        tabButtonActiveText: masterColors.primaryS10,
        tabButtonActiveBorder: masterColors.primaryS10,
        tabButtonDisabledText: masterColors.greyT70,
        tabButtonDisabledHoverText: masterColors.greyT70,

        // DRAWER

        drawerBg: masterColors.white,
        drawerBgOverlay: masterColors.overlay,
        drawerText: masterColors.contentT20,
        drawerBorder: masterColors.primaryT80,
        drawerCloseButtonText: masterColors.primary,
        drawerCloseButtonHoverBg: masterColors.primaryT85,

        // MODAL

        modalBg: masterColors.white,
        modalBgOverlay: masterColors.overlay,
        modalText: masterColors.contentT20,
        modalBorder: masterColors.primaryT80,
        modalCloseButtonText: masterColors.primary,
        modalCloseButtonHoverBg: masterColors.primaryT85,

        // MENU

        menuListBg: masterColors.primaryT95,
        menuListBorder: masterColors.primaryT85,
        menuItemBg: masterColors.transparent,
        menuItemText: masterColors.primary,
        menuItemHoverBg: masterColors.primaryT85,
        menuItemDisabledText: masterColors.greyT70,
        menuItemDisabledHoverBg: masterColors.transparent,
        menuDividerBorder: masterColors.primaryT80,

        // POPOVER

        popoverContentBg: masterColors.primaryT95,
        popoverContentBorder: masterColors.primaryT80,
        popoverCloseButtonText: masterColors.primary,
        popoverCloseButtonHoverBg: masterColors.primaryT85,

        // TAB

        tabText: masterColors.primary,
        tabSelectedText: masterColors.primaryS10,
        tabSelectedBorder: masterColors.primaryS10,

        // SELECT

        primarySelectFieldBg: masterColors.white,
        primarySelectFieldBorder: masterColors.greyT65,
        primarySelectFieldText: masterColors.contentT20,
        primarySelectFieldPlaceholder: masterColors.greyT70,
        primarySelectFieldHoverBorder: masterColors.greyT40,
        primarySelectFieldFocusBorder: masterColors.primary,
        primarySelectFieldFocusShadow: masterColors.primary,
        primarySelectFieldDisabledBg: masterColors.greyT95,
        primarySelectFieldDisabledBorder: masterColors.greyT90,
        primarySelectFieldDisabledText: masterColors.greyT80,
        primarySelectFieldDisabledHoverBg: masterColors.greyT95,
        primarySelectFieldDisabledHoverBorder: masterColors.greyT90,
        primarySelectFieldDisabledHoverText: masterColors.greyT80,
        primarySelectIcon: masterColors.primary,

        // TEXTAREA

        primaryTextAreaBg: masterColors.white,
        primaryTextAreaBorder: masterColors.greyT65,
        primaryTextAreaText: masterColors.contentT20,
        primaryTextAreaPlaceholder: masterColors.greyT70,
        primaryTextAreaHoverBorder: masterColors.greyT40,
        primaryTextAreaFocusBorder: masterColors.primary,
        primaryTextAreaFocusShadow: masterColors.primary,
        primaryTextAreaInvalidBorder: masterColors.destructive,
        primaryTextAreaInvalidShadow: masterColors.destructive,
        primaryTextAreaDisabledBg: masterColors.greyT95,
        primaryTextAreaDisabledBorder: masterColors.greyT90,
        primaryTextAreaDisabledText: masterColors.greyT80,
        primaryTextAreaDisabledHoverBg: masterColors.greyT95,
        primaryTextAreaDisabledHoverBorder: masterColors.greyT90,
        primaryTextAreaDisabledHoverText: masterColors.greyT80,

        // INPUT

        primaryInputFieldBg: masterColors.white,
        primaryInputFieldBorder: masterColors.greyT65,
        primaryInputFieldText: masterColors.contentT20,
        primaryInputFieldDisabledBg: masterColors.greyT95,
        primaryInputFieldDisabledBorder: masterColors.greyT90,
        primaryInputFieldDisabledText: masterColors.greyT80,
        primaryInputFieldDisabledHoverBg: masterColors.greyT95,
        primaryInputFieldDisabledHoverBorder: masterColors.greyT90,
        primaryInputFieldDisabledHoverText: masterColors.greyT80,
        primaryInputFieldPlaceholder: masterColors.greyT70,
        primaryInputFieldHoverBorder: masterColors.greyT40,
        primaryInputFieldInvalidBorder: masterColors.destructive,
        primaryInputFieldInvalidShadow: masterColors.destructive,
        primaryInputFieldFocusBorder: masterColors.primary,
        primaryInputFieldFocusShadow: masterColors.primary,
        primaryInputAddonBg: masterColors.white,
        primaryInputAddonBorder: masterColors.greyT65,
        primaryInputAddonText: masterColors.greyT70,
        primaryInputAddonDisabledBg: masterColors.greyT95,
        primaryInputAddonDisabledBorder: masterColors.greyT90,
        primaryInputAddonDisabledText: masterColors.greyT80,
        primaryInputAddonDisabledHoverBg: masterColors.greyT95,
        primaryInputAddonDisabledHoverBorder: masterColors.greyT90,
        primaryInputAddonDisabledHoverText: masterColors.greyT80,
        primaryInputElementText: masterColors.greyT70,
        primaryInputElementDisabledBg: masterColors.greyT95,
        primaryInputElementDisabledBorder: masterColors.greyT90,
        primaryInputElementDisabledText: masterColors.greyT80,
        primaryInputElementDisabledHoverBg: masterColors.greyT95,
        primaryInputElementDisabledHoverBorder: masterColors.greyT90,
        primaryInputElementDisabledHoverText: masterColors.greyT80,

        // NUMBER INPUT

        primaryNumberInputFieldBg: masterColors.white,
        primaryNumberInputFieldBorder: masterColors.greyT65,
        primaryNumberInputFieldText: masterColors.contentT20,
        primaryNumberInputFieldDisabledBg: masterColors.greyT95,
        primaryNumberInputFieldDisabledBorder: masterColors.greyT90,
        primaryNumberInputFieldDisabledText: masterColors.greyT80,
        primaryNumberInputFieldDisabledHoverBg: masterColors.greyT95,
        primaryNumberInputFieldDisabledHoverBorder: masterColors.greyT90,
        primaryNumberInputFieldDisabledHoverText: masterColors.greyT80,
        primaryNumberInputFieldPlaceholder: masterColors.greyT70,
        primaryNumberInputFieldHoverBorder: masterColors.greyT40,
        primaryNumberInputFieldInvalidBorder: masterColors.destructive,
        primaryNumberInputFieldInvalidShadow: masterColors.destructive,
        primaryNumberInputFieldFocusBorder: masterColors.primary,
        primaryNumberInputFieldFocusShadow: masterColors.primary,

        // SWITCH

        primarySwitchTrackBorder: masterColors.primaryT70,
        primarySwitchTrackFocusBorder: masterColors.primary,
        primarySwitchTrackFocusShadow: masterColors.primary,
        primarySwitchTrackBg: masterColors.white,
        primarySwitchTrackCheckedBg: masterColors.primary,
        primarySwitchThumbBg: masterColors.primary,
        primarySwitchThumbCheckedBg: masterColors.white,
        primarySwitchTrackDisabledBg: masterColors.greyT95,
        primarySwitchThumbDisabledBg: masterColors.greyT85,
        primarySwitchTrackDisabledBorder: masterColors.greyT90,

        // CHECKBOX

        primaryCheckboxControlBorder: masterColors.primaryT70,
        primaryCheckboxControlBg: masterColors.white,
        primaryCheckboxControlHoverBorder: masterColors.primaryT40,
        primaryCheckboxControlFocusBorder: masterColors.primary,
        primaryCheckboxControlFocusShadow: masterColors.primary,
        primaryCheckboxControlDisabledBorder: masterColors.greyT90,
        primaryCheckboxControlDisabledBg: masterColors.greyT95,
        primaryCheckboxControlDisabledHoverBorder: masterColors.greyT90,
        primaryCheckboxControlDisabledHoverBg: masterColors.greyT95,
        primaryCheckboxControlCheckedBg: masterColors.primary,
        primaryCheckboxControlCheckedHoverBg: masterColors.primaryT30,
        primaryCheckboxControlCheckedDisabledBg: masterColors.greyT95,
        primaryCheckboxControlCheckedDisabledStroke: masterColors.greyT50,
        primaryCheckboxControlCheckedDisabledHoverBg: masterColors.greyT95,
        primaryCheckboxControlCheckedDisabledHoverStroke: masterColors.greyT50,
        primaryCheckboxControlIndeterminateBg: masterColors.primary,
        primaryCheckboxControlIndeterminateHoverBg: masterColors.primaryT30,
        primaryCheckboxControlIndeterminateDisabledBg: masterColors.greyT95,
        primaryCheckboxControlIndeterminateDisabledHoverBg:
            masterColors.greyT95,
        primaryCheckboxLabelText: variableColors.formLabel,
        primaryCheckboxLabelDisabledText: masterColors.greyT80,
        primaryCheckboxLabelDisabledHoverText: masterColors.greyT80,
        primaryCheckboxIcon: masterColors.white,

        // RADIO

        primaryRadioControlBorder: masterColors.primaryT70,
        primaryRadioControlBg: masterColors.white,
        primaryRadioControlHoverBorder: masterColors.primaryT40,
        primaryRadioControlFocusBorder: masterColors.primary,
        primaryRadioControlFocusShadow: masterColors.primary,
        primaryRadioControlCheckedBg: masterColors.white,
        primaryRadioControlCheckedBorder: masterColors.primary,
        primaryRadioControlCheckedText: masterColors.primary,
        primaryRadioControlCheckedHoverBg: masterColors.primaryT90,
        primaryRadioControlCheckedHoverBorder: masterColors.primary,
        primaryRadioControlCheckedHoverText: masterColors.primary,
        primaryRadioControlCheckedDisabledBg: masterColors.greyT95,
        primaryRadioControlCheckedDisabledBorder: masterColors.greyT90,
        primaryRadioControlCheckedDisabledText: masterColors.greyT80,
        primaryRadioControlCheckedDisabledHoverBg: masterColors.greyT95,
        primaryRadioControlCheckedDisabledHoverBorder: masterColors.greyT90,
        primaryRadioControlCheckedDisabledHoverText: masterColors.greyT80,
        primaryRadioControlDisabledBg: masterColors.greyT95,
        primaryRadioControlDisabledBorder: masterColors.greyT90,
        primaryRadioControlDisabledHoverBg: masterColors.greyT95,
        primaryRadioControlDisabledHoverBorder: masterColors.greyT90,
        primaryRadioLabelText: variableColors.formLabel,
        primaryRadioLabelDisabledText: masterColors.greyT80,
        primaryRadioLabelDisabledHoverText: masterColors.greyT80,

        // FORM LABEL

        formLabelText: variableColors.formLabel,
    };
    return colors;
};

export default getColors;
