import * as yup from "yup";

export const schema = (t: (val: string) => string) => {
    const validation = yup.object().shape({
        name: yup.string().required(t("cmn:val.required")),
        description: yup.string().optional().max(1000),
        priority: yup.number().nullable(),
        startDate: yup.string().nullable(),
        dueDate: yup.string().nullable(),
        estimatedTime: yup.number().nullable(),
        assignee: yup.string().nullable(),
    });

    return validation;
};
