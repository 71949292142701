import { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { FormContainerStateReturnType, useTrans } from "../../../hooks";
import {
    VStack,
    Input,
    useToast,
    Textarea,
    HStack,
    Select,
} from "@chakra-ui/react";
import {
    Task as FormEntity,
    TaskApi as FormEntityApi,
    ProjectApi,
} from "../../../apis";
import { AppFormControl } from "../../../components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema";
import { AppFormContainer } from "../../../containers";
import { SelectedProjectAtom } from "../../../atoms";
import { APPC } from "../../../config";

export const TaskForm: FC<{
    formContainerState: FormContainerStateReturnType;
}> = ({ formContainerState }) => {
    // hook
    const { t } = useTrans();
    const toast = useToast();

    // state & const
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<FormEntity>(new FormEntity());

    const selectedProject = useRecoilValue(SelectedProjectAtom);

    // form
    const {
        handleSubmit,
        register,
        formState: { errors },
        reset,
        setFocus,
    } = useForm<FormEntity>({
        resolver: yupResolver(schema(t)),
        mode: "onSubmit",
    });

    const onSubmitHandler = (formData: FormEntity) => {
        setLoading(true);

        // set empty field values to null
        Object.keys(formData).forEach((f) => {
            if (!formData[f]) {
                formData[f] = null;
            }
        });

        if (selectedProject > 0) {
            formData["project"] = ProjectApi.toResourceIRI(selectedProject);
        }

        FormEntityApi.createOrUpdate<FormEntity, FormEntity>(
            formContainerState.entityId,
            formData
        )
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    toast({
                        title: t("cmn:message.save.success"),
                        status: "success",
                    });
                    reset();
                    formContainerState.close();
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        setTimeout(() => setFocus("name"), 500);
        if (!formContainerState.entityId) {
            return;
        }
        setLoading(true);
        FormEntityApi.getItem<FormEntity>(formContainerState.entityId)
            .then(({ errorMessage, isNotFound, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (isNotFound) {
                    toast({
                        title: t("cmn:message.entityNotFound"),
                        status: "error",
                    });
                } else if (response !== null) {
                    reset();
                    setData(response);
                }
            })
            .finally(() => setLoading(false));
    }, [formContainerState.entityId]);

    return (
        <AppFormContainer
            title={t("padm.TaskForm:text.pageTitle")}
            state={formContainerState}
            type={"modal"}
            size={"xxl"}
        >
            <form
                id="app-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(onSubmitHandler)();
                }}
            >
                <VStack gap={5} alignItems={"stretch"}>
                    <AppFormControl
                        label={t("ent.Task:name.label")}
                        isInvalid={!!errors?.name}
                        message={errors?.name?.message}
                        isLoading={loading}
                    >
                        <Input
                            type="text"
                            placeholder={t("ent.Task:name.placeholder")}
                            {...register("name")}
                            defaultValue={data.name}
                        />
                    </AppFormControl>
                    <AppFormControl
                        label={t("ent.Task:description.label")}
                        isInvalid={!!errors?.description}
                        message={errors?.description?.message}
                        isLoading={loading}
                    >
                        <Textarea
                            placeholder={t("ent.Task:description.placeholder")}
                            {...register("description")}
                            defaultValue={data?.description}
                        />
                    </AppFormControl>
                    <HStack gap={5} alignItems={"stretch"}>
                        <AppFormControl
                            label={t("ent.Task:assignee.label")}
                            isInvalid={!!errors?.assignee}
                            message={errors?.assignee?.message}
                            isLoading={loading}
                        >
                            <Select
                                placeholder={t("ent.Task:assignee.placeholder")}
                                {...register("assignee")}
                                defaultValue={data?.assignee as string}
                            ></Select>
                        </AppFormControl>
                        <AppFormControl
                            label={t("ent.Task:priority.label")}
                            isInvalid={!!errors?.priority}
                            message={errors?.priority?.message}
                            isLoading={loading}
                        >
                            <Select
                                {...register("priority")}
                                defaultValue={
                                    data?.priority ||
                                    APPC.BE.Task.PRIORITY_MEDIUM
                                }
                            >
                                {Object.keys(APPC.BE.Task).map((s) => (
                                    <option key={s} value={APPC.BE.Task[s]}>
                                        {t(`ent.Task:priority.value.${s}`)}
                                    </option>
                                ))}
                            </Select>
                        </AppFormControl>
                        <AppFormControl
                            label={t("ent.Task:estimatedTime.label")}
                            isInvalid={!!errors?.estimatedTime}
                            message={errors?.estimatedTime?.message}
                            isLoading={loading}
                        >
                            <Input
                                type="text"
                                placeholder={t(
                                    "ent.Task:estimatedTime.placeholder"
                                )}
                                {...register("estimatedTime")}
                                defaultValue={data.estimatedTime}
                            />
                        </AppFormControl>
                    </HStack>
                    <HStack gap={5} alignItems={"stretch"}>
                        <AppFormControl
                            label={t("ent.Task:startDate.label")}
                            isInvalid={!!errors?.startDate}
                            message={errors?.startDate?.message}
                            isLoading={loading}
                        >
                            <Input
                                type="date"
                                placeholder={t(
                                    "ent.Task:startDate.placeholder"
                                )}
                                {...register("startDate")}
                                defaultValue={data?.startDate?.substring(0, 10)}
                            />
                        </AppFormControl>
                        <AppFormControl
                            label={t("ent.Task:dueDate.label")}
                            isInvalid={!!errors?.dueDate}
                            message={errors?.dueDate?.message}
                            isLoading={loading}
                        >
                            <Input
                                type="date"
                                placeholder={t("ent.Task:dueDate.placeholder")}
                                {...register("dueDate")}
                                defaultValue={data?.dueDate?.substring(0, 10)}
                            />
                        </AppFormControl>
                    </HStack>
                </VStack>
            </form>
        </AppFormContainer>
    );
};
