import { FC } from "react";
import { Avatar, Flex, HStack, Spacer, Text } from "@chakra-ui/react";
import { AppIcon } from "./AppIcon";
import { AppTaskProgress } from "./AppTaskProgress";
import { AppTooltip } from "./AppTooltip";
import { Task, User } from "../apis";
import dayjs from "dayjs";

type AppTaskCardProps = {
    task: Task;
};

export const AppTaskCard: FC<AppTaskCardProps> = ({ task }) => {
    return (
        <>
            <Flex
                w={"267px"}
                direction={"column"}
                p={"1.125rem"}
                gap={"0.75rem"}
                border={"1px solid var(--chakra-colors-primaryT80)"}
                borderTopWidth={"5px"}
                borderRadius={"4px"}
                bg={"var(--chakra-colors-white)"}
                justifyContent={"space-between"}
            >
                <AppTooltip label={task.name}>
                    <Text noOfLines={2} textStyle="mediumXs">
                        {task.name}
                    </Text>
                </AppTooltip>
                <HStack
                    justifyContent={"space-between"}
                    color={"var(--chakra-colors-contentT40)"}
                >
                    {task.dueDate && (
                        <HStack gap={"0.5rem"}>
                            <AppIcon name="icl-calendar" w="0.875rem" />
                            <Text textStyle={"regularXs"}>
                                {dayjs(task.dueDate).format("DD/MM/YYYY")}
                            </Text>
                        </HStack>
                    )}
                    <Spacer />
                    <HStack gap={"0.25rem"}>
                        <AppIcon name="icl-list" w="0.875rem" />
                        <Text textStyle={"regularXs"}>3/8</Text>
                    </HStack>
                </HStack>
                <HStack gap={"0.75rem"}>
                    {task?.assignee && (
                        <AppTooltip label={(task?.assignee as User)?.fullName}>
                            <Avatar
                                name={(task?.assignee as User)?.fullName}
                                src="https://bit.ly/dan-abramov"
                                size="sm"
                            />
                        </AppTooltip>
                    )}
                    <Spacer />
                    <HStack
                        gap={"0.25rem"}
                        color={"var(--chakra-colors-contentT40)"}
                    >
                        <AppIcon name="icl-paperclip" w="1rem" />
                        <Text textStyle={"regularXs"}>99</Text>
                    </HStack>
                    <HStack
                        gap={"0.25rem"}
                        color={"var(--chakra-colors-contentT40)"}
                    >
                        <AppIcon name="icl-comment-bubble" w="1rem" />
                        <Text textStyle={"regularXs"}>999</Text>
                    </HStack>
                </HStack>
                {task.estimatedTime && task.trackTime && (
                    <AppTaskProgress
                        estimatedTime={task.estimatedTime}
                        trackTime={task.trackTime}
                    />
                )}
            </Flex>
        </>
    );
};
