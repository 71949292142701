import { useContext } from "react";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts";
import {
    AuthApi,
    Project,
    ProjectApi,
    User,
    UserWorkspace,
    UserWorkspaceApi,
    Workspace,
} from "../apis";
import { ENUM, LSC } from "../config";
import { useToast } from "@chakra-ui/react";

import {
    ActiveUserWorkspaceAtom,
    ProjectListAtom,
    SelectedProjectAtom,
    UserWorkspaceListAtom,
} from "../atoms";

type AuthActionType = {
    isAuthenticated: boolean;
    setAuthTokenAction: (token: string) => void;
    setUserAction: (user: User) => void;
    loginAction: (payload: User) => void;
    logoutAction: () => void;
    reloadUserAction: () => Promise<void>;
    loadWorkspacesAndProjectsAction: () => Promise<void>;
};

export function useAuthAction(): AuthActionType {
    // hooks
    const navigate = useNavigate();
    const toast = useToast();
    // const { changeLocale } = useTrans();

    // contexts
    const { state, dispatch } = useContext(AuthContext);

    // state
    const { isAuthenticated } = state;

    const setProjectList = useSetRecoilState(ProjectListAtom);
    const setSelectedProject = useSetRecoilState(SelectedProjectAtom);
    const setUserWorkspaceList = useSetRecoilState(UserWorkspaceListAtom);
    const setActiveUserWorkspace = useSetRecoilState(ActiveUserWorkspaceAtom);

    const setAuthTokenAction = (token: string) => {
        localStorage.setItem(LSC.APP_TOKEN_KEY, token);
    };

    const setUserAction = (user: User) => {
        localStorage.setItem(LSC.APP_USER_KEY, JSON.stringify(user));
    };

    const loginAction = (payload: User) => {
        setUserAction(payload);

        dispatch({
            type: ENUM.AuthContextAction.LOGIN_SUCCESS,
            payload,
        });
    };

    const logoutAction = () => {
        dispatch({ type: ENUM.AuthContextAction.LOGOUT });
        navigate("/");
    };

    const reloadUserAction = async () =>
        AuthApi.me<User>().then(({ response, errorMessage }) => {
            if (errorMessage) {
                toast({ title: errorMessage, status: "error" });
            } else if (response !== null) {
                setUserAction(response);

                dispatch({
                    type: ENUM.AuthContextAction.RELOAD_USER,
                    payload: response,
                });
            }
        });

    const loadWorkspacesAndProjectsAction = async () => {
        const { response: workspaceResponse } =
            await UserWorkspaceApi.getCollectionMyWorkspaces<UserWorkspace>(1, {
                pagination: false,
            });

        if (workspaceResponse && workspaceResponse?.totalItems > 0) {
            setUserWorkspaceList(workspaceResponse.items);
            let activeUserWorkspaceId: number = 0;
            workspaceResponse.items.forEach((uw) => {
                if (uw.isActive) {
                    activeUserWorkspaceId = (uw?.workspace as Workspace).id;
                }
            });
            setActiveUserWorkspace(activeUserWorkspaceId);

            const { response: projectResponse } =
                await ProjectApi.getCollectionMyProjects<Project>(1, {
                    "workspace.id": activeUserWorkspaceId,
                    [`order[name]`]: "asc",
                    pagination: false,
                });

            if (projectResponse && projectResponse?.totalItems > 0) {
                setProjectList(projectResponse.items);
                let selP = projectResponse.items[0]?.id;
                projectResponse.items.forEach((p) => {
                    if (p.id === Number(localStorage.getItem(LSC.APP_SEL_P))) {
                        selP = p.id;
                    }
                });
                setSelectedProject(selP);
            }
        }
    };

    return {
        isAuthenticated,
        setAuthTokenAction,
        setUserAction,
        loginAction,
        logoutAction,
        reloadUserAction,
        loadWorkspacesAndProjectsAction,
    };
}
